import { geti18nText, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NyFileUpload from '../../components/file-upload';
import ParamComponent from '../../components/param-form';
import { CONSTANTS_REQ, ParamStatus, SettingsKey, WsMethods } from '../../utils/Constants';
import { StatusNotification } from '../../utils/StatusNotification';

interface iNySendRequestIndex {
    wsMethod: any;
    visible?: boolean;
    requestType?: string;
    closeModal?: () => void;
    url?:string;
    values?:any,
}

const SendRequestIndex = ({
    wsMethod = WsMethods.CREATE_ORDER,
    visible,
    requestType,
    closeModal,
    url,
    values
}: iNySendRequestIndex) => {
    const [params, setParams] = useState<any>(null);
    const [type, setType] = useState<any>(requestType);
    const [form] = Form.useForm();
    const [attachment, setAttachment] = useState(false);
    

    useEffect(() => {
        const found: any = (NySession.getAppValue(SettingsKey.REQ_TYPES) as []).filter((param: any) => {
            return param.typeId == requestType;
        });
        if (found && found[0] !== undefined) {
            setAttachment(found[0].attachment);
        }
        setParams(
            (NySession.getAppValue(SettingsKey.REQ_PARAM_TYPE) as []).filter((param: any) => {
                if (wsMethod === WsMethods.GET_DATA) {
                    return (
                        param.typeId === requestType && param.wsMethod === wsMethod && param.status === ParamStatus.IN
                    );
                } else {
                    return param.typeId === requestType && param.wsMethod === wsMethod;
                }
            })
        );
        
    }, [requestType]);

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 14, offset: 8 },
    };

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onFinish = () => {
            childRef.current.onLoading(true)
            form.validateFields()
            .then(async (values: any) => {
                let files=[]
                if(childRefFiles.current){
                     files=childRefFiles.current.getFiles();
                }
                if (files.length > 0) {
                    const formData = new FormData();
                    formData.append('requestType', requestType ? requestType : '');
                    formData.append('paramsJson', JSON.stringify(values));
                    formData.append('wsMethod', wsMethod);
                    files.forEach((file: any) => {
                        console.log('file');
                        formData.append('files', file, file.name);
                    });
                    NyRequestResolver.requestPostFile(url ? url:CONSTANTS_REQ.RECIEVING_REQUEST.SEND_REQUEST, undefined, formData).then(
                        (response: any) => {
                            showResponse(response);
                        }
                    );
                } else {
                    NyRequestResolver.requestPost(url ? url:CONSTANTS_REQ.RECIEVING_REQUEST.SEND_REQUEST, undefined, {
                        requestType: requestType ? requestType : '',
                        params: values,
                        wsMethod: wsMethod,
                    }).then((response: any) => {
                        showResponse(response);
                    });
                }
                
            })
            .catch((errorInfo: any) => {
                childRef.current.onLoading(false)
            })
            
        
    };

    const showResponse = (response: any) => {
        if (response.status === RESPONSE.OK) {
            childRef.current.onLoading(false)
            StatusNotification.notify(response.data.code, response.data.message);
            if (response.data.code == 0) {
                form.resetFields();
                close();
            } else {
                if (response.data.message) {
                    const arr = response.data.message.split(' ');
                    if (arr[0]) {
                        form.setFields([{ name: [arr[0]], errors: [geti18nText('app.default.error')] }]);
                    }
                }
            }
        } else {
            childRef.current.onLoading(false)
            StatusNotification.notify(-1, '');
        }
    };

    const close = () => {
        if(childRefFiles.current) {
            childRefFiles.current.resetFiles()
        }
        if (closeModal) {
            closeModal();
        }
    };
    const childRef = useRef<any>();
    const childRefFiles = useRef<any>();

    return (
        <Modal
            visible={visible}
            title={geti18nText('recieving.request.title') + ' ' + requestType}
            width={1500}
            style={{ top: 20 }}
            onCancel={close}
            destroyOnClose={true}
            footer={null}
            maskClosable={false}
        >
            <>
                <Form
                    {...formItemLayout}
                    style={{ marginTop: '20px' }}
                    form={form}
                    name="control-ref"
                    
                >
                    <ParamComponent values={values} form={form} params={params}></ParamComponent>

                    {attachment && (
                        <Col span={24} style={{ marginTop: '10px' }}>
                            <Form.Item label={geti18nText('attachment.name')}>
                                 <SendRequestFiles ref={childRefFiles} multiple={wsMethod === WsMethods.GET_DATA ? false : true}/>
                            </Form.Item>
                        </Col>
                    )}
                    <Row>
                        <Col span={24}>
                            {(<Form.Item label="&nbsp;" {...buttonItemLayout}>
                                    <SendButton ref={childRef} onFinish={onFinish} />
                            </Form.Item>
                                   
                            )}
                        </Col>
                    </Row>
                </Form>
            </>
        </Modal>
    );
};

const SendRequestFiles=forwardRef((props:any, ref:any)=>{
    const [files, setFiles] = useState<any>([]);
    useImperativeHandle(ref, () => ({
        getFiles() {
          return files;
        },
        resetFiles(){
            setFiles([])
        }
    
      }));
        return <NyFileUpload
            files={files}
            setFiles={setFiles}
            accept="pdf"
            multiple={props.multiple}
        />
});


const SendButton= forwardRef((props:any, ref:any)=>{
    const [loading,setLoading]=useState(false)
    useImperativeHandle(ref, () => ({
        onLoading(active:any) {
          setLoading(active)
        }
    
      }));
    return <Button disabled={loading} type="primary" onClick={props.onFinish} >
        {geti18nText('recieving.request.button.send')}
    </Button>
});

export default SendRequestIndex;
