
import { geti18nText } from '@nybble/nyreact';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

const ParamComponent = (props: any) => {
    const { Option } = Select;
    const dateFormat = 'DD.MM.YYYY';


    useEffect(()=>{
        if(props.values){
            props.form.setFieldsValue(props.values)
        }
    },[props.values])

    function params() {
        return props.params != undefined && props.params.map((option: { [index: string]: any }, index: number) => {
            return (
                <Form.Item
                    key={index}
                    label={geti18nText('params.' + option.typeId + '.' + option.name)}
                    name={option.name}
                    rules={setRules(option.mandatory, option.dataType, option.allowedValues)}
                >
                    {renderElement(option.name, option.dataTypeAlternate?option.dataTypeAlternate:option.dataType, transformAllowedValues(option.allowedValuesAlternate?option.allowedValuesAlternate: option.allowedValues), option.defaultValues)}
                </Form.Item>
            );
        });
    }

    function setRules(mandatory: any, dataType: any, max: any): any {
        let rules = [];
        if (dataType == 'string') {
            rules.push({ required: mandatory, message: geti18nText('recieving.request.mandatory.field') });
            rules.push({ max: parseInt(max), message: geti18nText('recieving.request.max.field') + ' ' + max + ' !' });
        } else {
            rules.push({ required: mandatory, message: geti18nText('recieving.request.mandatory.field') });
        }
        return rules;
    }

    function transformAllowedValues(values: any): any {
        let tmpValues = null;
        let allowedValues: any = [];
        if (values) {
            tmpValues = values.split('|');
        }
        if (tmpValues) {
            tmpValues.forEach((element: any) => {
                let idValue = element.includes(':') ? element.split(':') : element;
                if (idValue instanceof Array) {
                    allowedValues.push({ key: idValue[0], value: idValue[1] });
                } else {
                    allowedValues.push({ key: idValue, value: idValue });
                }
            });
        }
        return allowedValues;
    }

    function handleChange(key: any, options: any, formItem: any) {
        if (key != undefined) {
            props.form.setFieldsValue({ [options.key.split('$')[0]]: options.value });
        } else {
            props.form.setFieldsValue({ [formItem]: undefined });
        }
    }

    function onChangeDate(date: any, dateString: any, key: any) {
        props.form.setFieldsValue({ [key]: dateString });
    }

    const setDefaultValue=(key:any, defaultValue?:any)=>{
        let value=props.values && props.values[key];
        props.form.setFieldsValue({ [key]: value?value:defaultValue?defaultValue:undefined });
        return value?value:defaultValue?defaultValue:undefined;

    }
     const setDefaultValueDate=(key:any)=>{
        let value=props.values && props.values[key];
        return value?moment(value): undefined;

    }

    function renderElement(key: any, type: any, allowedValues: any, defaultValue?:any) {
        const typeEl = () => {
            switch (type) {
                case 'string':
                    return <Input defaultValue={setDefaultValue(key, defaultValue)} />;
                case 'integer':
                    return <InputNumber defaultValue={setDefaultValue(key)} style={{ width: '100%' }} />;
                case 'date':
                    return (
                        <DatePicker
                            defaultValue={setDefaultValueDate(key)}
                            key={key}
                            format={dateFormat}
                            onChange={(date, dateString) => onChangeDate(date, dateString, key)}
                        />
                    );
                case 'select':
                    return (
                        <Select defaultValue={setDefaultValue(key,defaultValue)} allowClear onChange={(value, option) => handleChange(value, option, key)}>
                            {allowedValues.map((allValue: any, index: any) => {
                                return (
                                    <Option key={key + '$' + index} value={allValue.key}>
                                        {allValue.value}
                                    </Option>
                                );
                            })}
                        </Select>
                    );
                default:
                    return <Input />;
            }
        };

        return <div>{typeEl()}</div>;
    }

    return params();
};

export default ParamComponent;
