import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ConsoleSqlOutlined,
    DownloadOutlined,
    ForwardOutlined,
    HourglassOutlined,
    InfoCircleOutlined,
    MinusCircleOutlined,
    SearchOutlined,
    StopOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import {
    Button,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Popover,
    Row,
    Select,
    Tag,
    Timeline,
    Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ, DefaultSettings, SettingsKey, WsMethods } from '../../utils/Constants';
import { StatusNotification } from '../../utils/StatusNotification';
import WsRequestDetails from '../ws-request/edit';
import { GetParameterValue } from '../../utils/Utils';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { RangePickerProps } from 'antd/lib/date-picker';
import SendRequestIndex from '../send-request';
const { Text } = Typography;

export const HtStatus: { [index: string]: any } = {
    PRIMLJEN: { color: 'green', icon: <CheckCircleOutlined /> },
    PRIHVAĆEN: { color: 'green', icon: <CheckCircleOutlined /> },
    REALIZIRAN: { color: 'green', icon: <CheckCircleOutlined /> },
    RIJEŠEN: { color: 'green', icon: <CheckCircleOutlined /> },
    ODBIJEN: { color: 'orange', icon: <StopOutlined /> },
    STORNO: { color: 'gold', icon: <MinusCircleOutlined /> },
    NEDOZVOLJEN: { color: 'red', icon: <CloseCircleOutlined /> },
    INFO: { color: 'blue', icon: <InfoCircleOutlined /> },
    ODGOĐEN: { color: 'geekblue', icon: <ClockCircleOutlined /> },
    WAIT_RESPONSE: { color: 'geekblue', icon: <HourglassOutlined /> },
};

const RequestDetails = React.forwardRef((props: any, ref: any) => {
    const { Option } = Select;
    const [guuid, setGuuid] = useState(props.match.params.guuid);
    const [requestType, setRequestType] = useState(null);
    const [statusType, setStatusType] = useState(null);
    const [data, setData] = useState<any>(null);
    const [createOrderData, setCreateOrderData] = useState<any>(null);
    const [location, setLocation] = useState<any>(null);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { Title, Link } = Typography;
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [detailsVisibleSend, setDetailsVisibleSend] = useState(false);
    const [wsRequestId, setWsRequestId] = useState(0);
    const [loadingFile, setLoadingFile] = useState(false);
    const [printPdf, setPrintPdf] = useState(true);
    const [modalViewActivity, setModalViewActivity] = useState(false);
    const [selectedProcedureUpdateOrder, setSelectedProcedureUpdateOrder] = useState<any>(undefined);
    const [selectedUpdateOrderStatus, setSelectedUpdateOrderStatus] = useState<any>(undefined);
    const [updateOrderTimeExecutionFrom, setUpdateOrderTimeExecutionFrom] = useState<any>(undefined);
    const [updateOrderTimeExecutionTo, setUpdateOrderTimeExecutionTo] = useState<any>(undefined);
    const [updateOrderDateOfCompletion, setUpdateOrderDateOfCompletion] = useState<any>(undefined);
    const [updateOrderDeliveryTime, setUpdateOrderDeliveryTime] = useState<any>(undefined);
    const [updateOrderDescriptionValue, setUpdateOrderDescriptionValue] = useState<any>(undefined);
    const dateFormat = 'DD.MM.YYYY';
    const dateTimeFormat = 'DD.MM.YYYY HH:mm';
    let history = useHistory();

    useEffect(() => {
        fetchData();
    }, []);

    const stornoNotAllowedStatus = () => {
        return ['ODBIJEN', 'STORNO', 'NEDOZVOLJEN', 'REALIZIRAN'];
    };

    function fetchData() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.DETAILS + '/' + guuid).then((result) => {
            if (result.status === RESPONSE.OK) {
                const responseData: { [index: string]: any } | Object | undefined | any = result.data;
                setData(responseData);
                setRequestType(responseData[0].requestType);
                setStatusType(responseData[0].statusType);
                setCreateOrderData(responseData[0]);
                setLoading(false);
            }
        });
    }

    function renderCreateOrderParam() {
        function getParamValues() {
            let params: any[] = [];

            if (createOrderData.createOrderParam) {
                const types = NySession.getAppValue(SettingsKey.REQ_PARAM_TYPE) as [];
                (NySession.getAppValue(SettingsKey.REQ_PARAM_TYPE) as []).forEach((param: any) => {
                    if (
                        param.typeId === requestType &&
                        param.wsMethod === WsMethods.CREATE_ORDER &&
                        createOrderData.createOrderParam.hasOwnProperty(param.name)
                    ) {
                        let paramValue: any = {};
                        paramValue.text = geti18nText('params.' + requestType + '.' + param.name);
                        paramValue.value = GetParameterValue(
                            createOrderData.createOrderParam[param.name],
                            param.dataType,
                            param.allowedValuesAlternate?param.allowedValuesAlternate: param.allowedValues
                        );

                        params.push(paramValue);
                    }
                });
            }

            return params;
        }

        return (
            <>
                <Descriptions.Item key={'guuid'} label={geti18nText('ws.request.edit.guuid')}>
                    <b>{guuid}</b>
                </Descriptions.Item>

                {getParamValues().map((obj: any, index: number) => {
                    return (
                        <Descriptions.Item key={index} label={obj.text}>
                            <b>{obj.value}</b>
                        </Descriptions.Item>
                    );
                })}

                {createOrderData.wsRequestAttachment && (
                    <Descriptions.Item label={geti18nText('ws.request.details.attachments')}>
                        {createOrderData.wsRequestAttachment.map((obj: any) => {
                            return (
                                <>
                                    <div>
                                        {loadingFile && <NySpinner />}
                                        <Link onClick={() => fileDownload(obj.id, obj.fileName)}>{obj.fileName}</Link>
                                    </div>
                                    {createOrderData.wsRequestAttachment.size > 1 ? <br /> : null}
                                </>
                            );
                        })}
                    </Descriptions.Item>
                )}
                {renderCreateStatus()}
            </>
        );
    }

    function renderCreateStatus() {
        console.log('createOrderData.statusType',createOrderData.statusType)
        if (createOrderData.statusType) {
            return (
                <>
                    <Descriptions.Item key={'status'} label={geti18nText('request.details.status')}>
                        <Tag
                            color={
                                HtStatus[createOrderData.statusType]
                                    ? HtStatus[createOrderData.statusType].color
                                    : undefined
                            }
                            icon={
                                HtStatus[createOrderData.statusType]
                                    ? HtStatus[createOrderData.statusType].icon
                                    : undefined
                            }
                        >
                            {' '}
                            {createOrderData.statusType}{' '}
                        </Tag>
                    </Descriptions.Item>
                    {createOrderData.statusDate && (
                        <Descriptions.Item key={'stDate'}>
                            {moment(createOrderData.statusDate).format('DD.MM.YYYY HH:mm:ss')}
                        </Descriptions.Item>
                    )}
                    {createOrderData.statusDescription && (
                        <Descriptions.Item key={'stDesc'}>{createOrderData.statusDescription}</Descriptions.Item>
                    )}
                </>
            );
        } else if (data[data.length - 1].responseCode !== 0) {
            return (
                <Descriptions.Item key={'statusError'} label={geti18nText('request.details.status')}>
                    <Tag color="red" icon={<WarningOutlined />}>
                        {' '}
                        {geti18nText('ws.request.edit.error')}{' '}
                    </Tag>
                </Descriptions.Item>
            );
        } else if (createOrderData.responseCode === 0 && !createOrderData.statusType) {
            return (
                <>
                    <Descriptions.Item key={'statusError'} label={geti18nText('request.details.status')}>
                        <Tag color="blue" icon={<ClockCircleOutlined />}>
                            {' '}
                            {geti18nText('ws.request.edit.status.wait.for.ht')}{' '}
                        </Tag>
                    </Descriptions.Item>
                </>
            );
        }
    }

    function renderParams(value: any) {
        if (value.requestMethod === WsMethods.CREATE_ORDER) {
            if (value.createOrderParam) {
                return Object.keys(value.createOrderParam).map((obj: any) => {
                    return (
                        <Descriptions.Item key={obj} label={geti18nText('params.' + value.requestType + '.' + obj)}>
                            <div>
                                <b>{value.createOrderParam[obj]}</b>
                                <br />
                            </div>
                        </Descriptions.Item>
                    );
                });
            }
        } else if (value.requestMethod === WsMethods.SEND_ORDER_STATUS) {
            if (value.sendOrderStatusParam) {
                return Object.keys(value.sendOrderStatusParam)
                    .filter((obj: any) => {
                        return obj !== 'id';
                    })
                    .map((obj: any) => {
                        return (
                            <Descriptions.Item
                                key={obj}
                                label={geti18nText('params.' + value.requestMethod + '.' + obj)}
                            >
                                <div>
                                    <b>{value.sendOrderStatusParam[obj]}</b>
                                    <br />
                                </div>
                            </Descriptions.Item>
                        );
                    });
            }
        }
        else if (value.requestMethod === WsMethods.UPDATE_ORDER) {
            if (value.paramUpdateOrder) {
                return Object.keys(value.paramUpdateOrder)
                    .filter((obj: any) => {
                        return obj !== 'id';
                    })
                    .map((obj: any) => {
                        return (
                            <Descriptions.Item
                                key={obj}
                                label={geti18nText('params.' + value.requestMethod + '.' + obj)}
                            >
                                <div>
                                    <b>{value.paramUpdateOrder[obj]}</b>
                                    <br />
                                </div>
                            </Descriptions.Item>
                        );
                    });
            }
        }
        else {
            return <div></div>;
        }
    }

    function errorContent(el: any) {
        return (
            <Descriptions bordered={printPdf === true}>
                <Descriptions.Item
                    style={{ background: '#cfcfcf' }}
                    span={3}
                    label={geti18nText('request.details.error.id')}
                >
                    {el.responseId ? el.responseId : geti18nText('request.details.errors.no.data')}
                </Descriptions.Item>
                <Descriptions.Item
                    style={{ background: '#cfcfcf' }}
                    span={3}
                    label={geti18nText('request.details.error.message')}
                >
                    {el.responseMessage ? el.responseMessage : geti18nText('request.details.errors.no.data')}
                </Descriptions.Item>
                <Descriptions.Item
                    style={{ background: '#cfcfcf' }}
                    span={3}
                    label={geti18nText('request.details.error.trace')}
                >
                    {el.responseTrace ? el.responseTrace : geti18nText('request.details.errors.no.data')}
                </Descriptions.Item>
            </Descriptions>
        );
    }

    function renderItems() {
        return data
            .filter((d: any) => {
                return d.id !== createOrderData.id && d.requestMethod !== WsMethods.SEND_NOTIFICATION;
            })
            .map((el: any) => {
                return (
                    <Timeline.Item
                        key={el.id}
                        color={el.requestFromHt ? 'gray' : 'blue'}
                        dot={
                            el.requestFromHt ? (
                                <ArrowLeftOutlined style={{ fontSize: '22px' }} />
                            ) : (
                                <ArrowRightOutlined style={{ fontSize: '22px' }} />
                            )
                        }
                        label={moment(el.timeGenerated).format('DD.MM.YYYY HH:mm:ss')}
                    >
                        <Row justify={'end'}>
                            {el.responseCode !== 0 && (
                                <Descriptions.Item span={2} label={geti18nText('request.details.status')}>
                                    {
                                        <Popover
                                            placement="bottom"
                                            content={errorContent(el)}
                                            title={
                                                el.responseCode +
                                                ' : ' +
                                                geti18nText(
                                                    NySession.getAppValue(SettingsKey.STATUS_CODE)[el.responseCode]
                                                )
                                            }
                                        >
                                            <Tag icon={<CloseCircleOutlined />} color="error">
                                                {geti18nText('ws.request.edit.error')}
                                            </Tag>
                                        </Popover>
                                    }
                                </Descriptions.Item>
                            )}
                            {
                                <div onClick={() => orderDetails(el.id)}>
                                    <Text
                                        strong
                                        style={{
                                            cursor: 'pointer',
                                            color:
                                                el.requestFromHt && el.responseCode === 0
                                                    ? 'grey'
                                                    : el.requestFromHt && el.responseCode !== 0
                                                    ? '#cd201f'
                                                    : !el.requestFromHt && el.responseCode !== 0
                                                    ? '#cd201f'
                                                    : '#1494e3',
                                        }}
                                        underline
                                    >
                                        {geti18nText('request.method.' + el.requestMethod)}
                                    </Text>
                                </div>
                            }
                        </Row>
                        <Row>
                            <Descriptions
                                column={1}
                                style={{ textAlign: 'left' }}
                                bordered={printPdf === true}
                                size={'small'}
                            >
                                {el.statusTypeRow && (
                                    <Descriptions.Item key={'status'} label={geti18nText('request.details.status')}>
                                        <Tag
                                            color={
                                                HtStatus[el.statusTypeRow]
                                                    ? HtStatus[el.statusTypeRow].color
                                                    : undefined
                                            }
                                            icon={
                                                HtStatus[el.statusTypeRow] ? HtStatus[el.statusTypeRow].icon : undefined
                                            }
                                        >
                                            {' '}
                                            {el.statusTypeRow}{' '}
                                        </Tag>
                                        {el.statusDate && moment(el.statusDate).format('DD.MM.YYYY HH:mm:ss')}
                                    </Descriptions.Item>
                                )}
                                {el.statusDescription && (
                                    <Descriptions.Item
                                        key={'stDesc'}
                                        label={geti18nText('request.details.status.description')}
                                    >
                                        {el.statusDescription}
                                    </Descriptions.Item>
                                )}
                                {el.username && (
                                    <Descriptions.Item key={'username'} label={geti18nText('ws.request.edit.user')}>
                                        <b>{el.username}</b>
                                    </Descriptions.Item>
                                )}
                                {renderAdditionalData(el)}
                                {el.sendOrderStatusParam && renderParams(el)}
                                {el.paramUpdateOrder && renderParams(el)}
                                {el.wsRequestAttachment && (
                                    <Descriptions.Item label={geti18nText('ws.request.details.attachments')}>
                                        {el.wsRequestAttachment.map((obj: any) => {
                                            return (
                                                <>
                                                    <div>
                                                        {loadingFile && <NySpinner />}
                                                        <Link onClick={() => fileDownload(obj.id, obj.fileName)}>
                                                            {obj.fileName}
                                                        </Link>
                                                    </div>
                                                    {el.wsRequestAttachment.size > 1 ? <br /> : null}
                                                </>
                                            );
                                        })}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </Row>
                        <Divider></Divider>
                    </Timeline.Item>
                );
            });
    }

    function orderDetails(id: any) {
        setDetailsVisible(true);
        setWsRequestId(id);
    }

    function renderAdditionalData(el: any) {
        if (el.requestMethod === WsMethods.CANCEL_ORDER) {
            return (
                <Descriptions.Item key={el.id} label={geti18nText('request.details.modal.cancel.order.note')}>
                    {el.note && (
                        <div>
                            <b>{el.note}</b>
                            <br />
                        </div>
                    )}
                </Descriptions.Item>
            );
        }
    }

    const selectAsRealized=()=>{
        setLoading(true);
        
        let params={realized:!createOrderData.realized,id:createOrderData.id}
        NyRequestResolver.requestPut(CONSTANTS_REQ.WS_REQUEST.REALIZED,params,params).then((result) => {
            if (result.status === RESPONSE.OK) {
                
                fetchData()
            }
            else setLoading(false)
        });
    }

    function renderButton() {
        return (
            <>
                {actionsButtons().map((button: any) => {
                    return (
                        <Button
                            key={button.key}
                            hidden={button.hidden}
                            style={button.style}
                            onClick={() => button.action()}
                            type={button.type}
                            icon={button.icon}
                        >
                            {button.title}
                        </Button>
                    );
                })}
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button
                                type={'primary'}
                                key={'print'}
                                style={{ marginLeft: '10px' }}
                                icon={<DownloadOutlined />}
                            >
                                {geti18nText('request.details.btn.save.pdf')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(false);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(true);
                    }}
                    content={() => componentRef.current}
                />
                <Button
                    type={'primary'}
                    key={'view'}
                    style={{ marginLeft: '125px' }}
                    onClick={() => {
                        setModalViewActivity(true);
                    }}
                    icon={<SearchOutlined />}
                >
                    {geti18nText('request.log.btn.title')}
                </Button>
                {createOrderData.statusType==='ODBIJEN'&&<Button
                    type={'primary'}
                    key={'sendAgain'}
                    style={{ marginLeft: '5px' }}
                    onClick={() => {
                        
                        setDetailsVisibleSend(true)
                    }}
                    icon={<ForwardOutlined />}
                >
                    {geti18nText('request.btn.send.again')}
                </Button>}
                {createOrderData.statusType==='REALIZIRAN'&&<Button
                    type={'primary'}
                    key={'selectRealized'}
                    style={{ marginLeft: '5px' }}
                    onClick={() => {
                        
                        selectAsRealized()
                    }}
                    danger={createOrderData.realized && createOrderData.realized===true}
                    icon={<CheckOutlined /> }
                >
                    {createOrderData.realized && createOrderData.realized===true? geti18nText('request.btn.unrealized'): geti18nText('request.btn.realized')}
                </Button>}
            </>
        );
    }

    function goBack() {
        history.goBack();
    }

    const allowedTypeForUpdateOrder=['PIAA','PIAN','PIAP','NBSA','NSTA','NVOA','NBSS','ULL','FAA']
    const showSendUpdateBtn=(type:any)=>{
        return !allowedTypeForUpdateOrder.includes(type);
    }

    const actionsButtons = () => {
        return [
            {
                type: 'primary',
                title: geti18nText('request.details.btn.back'),
                action: () => goBack(),
                key: 'back',
            },
            {
                type: 'danger',
                hidden:
                    data.length !== 1 &&
                    createOrderData.statusType &&
                    stornoNotAllowedStatus().includes(createOrderData.statusType),
                style: { marginLeft: '10px' },
                title: geti18nText('request.details.btn.cancel.order'),
                action: () => setCancelModalVisible(true),
                key: 'cancelOrder',
            },
            {
               // type: 'primary',
                hidden:showSendUpdateBtn(createOrderData.requestType),
                title: geti18nText('request.details.btn.update.order'),
                style: { marginLeft: '10px', backgroundColor:'#55C51E', color:'white'},
                action: () => setUpdateModalVisible(true),
                key: 'updateOrder',
            },
        ];
    };

    function cancelOrderConfirm() {
        setCancelModalVisible(false);
        let cancelOrderRequestType = {
            guid: guuid,
            operator: { id: data[0].operatorId, name: data[0].operatorName },
            type: requestType,
            note: form.getFieldValue('note'),
        };
        setLoading(true);
        NyRequestResolver.requestPost(
            CONSTANTS_REQ.RECIEVING_REQUEST.CANCEL_ORDER,
            undefined,
            cancelOrderRequestType
        ).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                StatusNotification.notify(response.data.code, response.data.message);
                fetchData();
                form.resetFields();
            } else {
                StatusNotification.notify(-1, '');
                fetchData();
                form.resetFields();
            }
            setLoading(false);
        });
    }
    const formUpdateOrderParams=()=>{
        const parameter:any = [];
        if(form.getFieldValue('description') != undefined) {
            parameter.push({name:'description',value: form.getFieldValue('description')})
        }
        if(form.getFieldValue('status') != undefined) {
            parameter.push({name:'status',value: form.getFieldValue('status')})
        }
        if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===0 ){
            if(form.getFieldValue('op1') != undefined)
            parameter.push({name:'op1',value: form.getFieldValue('op1')})
        }
        
        if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===2){
            if(updateOrderTimeExecutionFrom != null) {
                parameter.push({name:'vrod',value: moment(updateOrderTimeExecutionFrom).format(DefaultSettings.DEFAULT_DATE_TIME_REQUEST)})
            }
            if(updateOrderTimeExecutionFrom != null) {
                parameter.push({name:'vrdo',value:moment(updateOrderTimeExecutionTo).format(DefaultSettings.DEFAULT_DATE_TIME_REQUEST)})
            }
        }if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===3){
            if(form.getFieldValue('op1') != undefined)
            parameter.push({name:'op1',value: form.getFieldValue('op1')})
        }
         if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===4){
            if(updateOrderDateOfCompletion != null) {
                parameter.push({name:'dtmre',value:moment(updateOrderDateOfCompletion).format(DefaultSettings.DEFAULT_DATE_TIME_REQUEST)})
            }
        }
        if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===5){
            if(updateOrderDeliveryTime != null) {
                parameter.push({name:'ddog',value:moment(updateOrderDeliveryTime).format(DefaultSettings.DEFAULT_DATE_TIME_REQUEST)})
            }
            if(form.getFieldValue('adrs') != undefined)
            parameter.push({name:'adrs',value: form.getFieldValue('adrs')})
        }
        if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===6){
            if(updateOrderDateOfCompletion != null) {
                parameter.push({name:'dtmre',value:moment(updateOrderDateOfCompletion).format(DefaultSettings.DEFAULT_DATE_TIME_REQUEST)})
            }
        }
        
        
        if(selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder===9){
            if(form.getFieldValue('ont_serial_number') != undefined)
                parameter.push({name:'ont_serial_number',value: form.getFieldValue('ont_serial_number')})
        }
        return parameter;
        
    }
    function updateOrderConfirm() {
        setUpdateModalVisible(false);
        let updateOrderRequestType = {
            guid: guuid,
            operator: { id: data[0].operatorId, name: data[0].operatorName },
            type: requestType,
            status: { type: form.getFieldValue('status'), description: form.getFieldValue('description')},
            data: {parameter:formUpdateOrderParams()}
        };
        setLoading(true);
        NyRequestResolver.requestPost(
            CONSTANTS_REQ.RECIEVING_REQUEST.UPDATE_ORDER,
            undefined,
            updateOrderRequestType
        ).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                StatusNotification.notify(response.data.code, response.data.message);
                fetchData();
                form.resetFields();
                
            } else {
                StatusNotification.notify(-1, '');
                fetchData();
                form.resetFields();
            }
            onCancelUpdateOrder()
            setLoading(false);
        });
    }

    function cancelOrderCancel() {
        setCancelModalVisible(false);
    }
    function closeDetailModal() {
        setDetailsVisible(false);
    }
    function closeDetailModalSend() {
        setDetailsVisibleSend(false);
    }

    const fileDownload = (attachmentId: any, fileName: String) => {
        setLoadingFile(true);

        NyRequestResolver.requestGet(
            CONSTANTS_REQ.WS_REQUEST.DOWNLOAD_FILE,
            { attachmentId: attachmentId },
            false,
            true
        ).then((result: { [index: string]: any }) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                NyUtils.downloadFile(result);
            } else {
                message.error(geti18nText('app.default.error'));
            }
            setLoadingFile(false);
        });
    };

    const componentRef = useRef<any>();

    function onCancelDeviceModal() {
        setModalViewActivity(false);
    }

    const columns: any = [
        {
            title: geti18nText('log.request.table.column.user'),
            dataIndex: ['userCreated', 'username'],
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('log.request.table.column.created'),
            dataIndex: 'created',
            width: '5%',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.created) {
                    return <div>{new Date(record.created).toLocaleString(NyUtils.getSelectedLocale())}</div>;
                }
            },
            ...getColumnDateOption(true),
        },
    ];

    function setDefaultFilterValue() {
        return [{ field: 'ws_request.id', condition: 'equals', value: data[0].id }];
    }

    const updateOrderProceduresList=()=>{
        const procedures=[
            {value:0, title:geti18nText('update.order.procedure.0'), types:['PIAA','PIAN','PIAP']},
            {value:1, title:geti18nText('update.order.procedure.1'), types:['PIAA','PIAN','PIAP']},
            {value:2, title:geti18nText('update.order.procedure.2'), types:['PIAN']},
            {value:3, title:geti18nText('update.order.procedure.3'), types:['PIAN']},
            {value:4, title:geti18nText('update.order.procedure.4'), types:['PIAN']},
            {value:5, title:geti18nText('update.order.procedure.5'), types:['PIAN']},
            {value:6, title:geti18nText('update.order.procedure.6'), types:['NBSA','NSTA']},
            {value:7, title:geti18nText('update.order.procedure.7'), types:['NBSA','NSTA','NVOA','NBSS','ULL']},
            {value:8, title:geti18nText('update.order.procedure.8'), types:['NBSA','NSTA','NBSS','FAA']},
            {value:9, title:geti18nText('update.order.procedure.9'), types:['NBSA','NSTA','NBSS']}
        ]
        return procedures;
    }

    const handleUpdateOrderProcedureChange=(value:any,option:any)=>{
        setSelectedUpdateOrderStatus(undefined);
        setSelectedProcedureUpdateOrder(value)
        form.setFieldsValue({description: undefined})
        setUpdateOrderDescriptionValue(undefined)
        form.setFieldsValue({status:undefined})
    }

    const handleChangeStatus=(value:any)=>{
        setSelectedUpdateOrderStatus(value);
        form.setFieldsValue({description: formUpdateOrderDescription(value)})
        setUpdateOrderDescriptionValue(formUpdateOrderDescription(value))
    }

    const formUpdateOrderDescription=(status:any)=>{
        let value=undefined;
        switch(selectedProcedureUpdateOrder){
            case 0:
                value= status===undefined?undefined:status==='DA'?geti18nText('update.order.procedure.0.status.DA'):geti18nText('update.order.procedure.0.status.NE');
                return value;
                break;
            case 1:
                value= status===undefined?undefined:status==='DA'?geti18nText('update.order.procedure.1.status.DA'):geti18nText('update.order.procedure.1.status.NE');
                return value;
            case 2:
                value= geti18nText('update.order.procedure.2.status.NASTAVAK');
                return value;
            case 3:
                value= geti18nText('update.order.procedure.3.status.ZAUSTAVLJANJE');
                return value;
            case 4:
                value= geti18nText('update.order.procedure.4.status.NASTAVAK');
                return value;
            case 5:
                value= geti18nText('update.order.procedure.5.status.NASTAVAK');
                return value;
            case 6:
                value= status===undefined?undefined: status==='NASTAVAK'? geti18nText('update.order.procedure.6.status.NASTAVAK') : undefined;
                return value;
            case 7:
                if(status==='DA,HT')value=geti18nText('update.order.procedure.7.status.DA,HT.desc') 
                else if (status==='NE') value=geti18nText('update.order.procedure.7.status.NE.desc') 
                else if (status==='DA,OPERATOR') value=geti18nText('update.order.procedure.7.status.DA,OPERATOR.desc') 
                else value=undefined;
                return value;
            case 8:
                    value= status===undefined?undefined: status==='DA'?geti18nText('update.order.procedure.8.status.DA.desc'):geti18nText('update.order.procedure.8.status.NE.desc');
                    return value;
            case 9:
                    return geti18nText('update.order.procedure.9.desc');
            
        }
    }

    const yesNoStatuses=[{value:'DA',title: geti18nText('app.default.button.yes')},{value:'NE',title: geti18nText('app.default.button.no')}]
    const continueCancelStatuses=[{value:'NASTAVAK',title: geti18nText('update.order.procedure.4.status.title.NASTAVAK')},{value:'ODUSTAJANJE',title: geti18nText('update.order.procedure.6.status.title.ODUSTAJANJE')}]
    const formStatusListUpdateOrder=()=>{
        let statusProcedure3=[{value:'ZAUSTAVLJANJE',title: geti18nText('update.order.procedure.3.status.title.ZAUSTAVLJANJE')}]
        let statusProcedure7=[{value:'DA,HT',title: geti18nText('update.order.procedure.7.status.DA,HT')},{value:'DA,OPERATOR',title: geti18nText('update.order.procedure.7.status.DA,OPERATOR')},{value:'NE',title: geti18nText('update.order.procedure.7.status.NE')}]
        let statusProcedureOther=[{value:'NASTAVAK',title: geti18nText('update.order.procedure.4.status.title.NASTAVAK')}]
        if(selectedProcedureUpdateOrder && selectedProcedureUpdateOrder > 1) {
                if(selectedProcedureUpdateOrder===2) {
                   // setSelectedUpdateOrderStatus(statusProcedure3[0].value)
                    form.setFieldsValue({status: statusProcedure3[0].value})
                    return statusProcedure3
                }
                else if(selectedProcedureUpdateOrder===6) {
                    //form.setFieldsValue({status: continueCancelStatuses[0].value})
                    return continueCancelStatuses;
                }
                else if(selectedProcedureUpdateOrder===7) {
                    //form.setFieldsValue({status: continueCancelStatuses[0].value})
                    return statusProcedure7;
                }
                else if(selectedProcedureUpdateOrder===8) {
                    //form.setFieldsValue({status: continueCancelStatuses[0].value})
                    return yesNoStatuses;
                }
                else {
                    //setSelectedUpdateOrderStatus(statusProcedureOther[0].value)
                    form.setFieldsValue({status: statusProcedureOther[0].value})
                    return statusProcedureOther
                };
        }
        return yesNoStatuses;
    }

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days before today and today
        return current && current < moment(updateOrderTimeExecutionFrom).endOf('day');
      };

    const onChangeDateExecutionFrom=(date:any)=>{
        setUpdateOrderTimeExecutionFrom(date);
        if(updateOrderTimeExecutionTo && date>updateOrderTimeExecutionTo) {
            form.setFieldsValue({vrdo: undefined})
            setUpdateOrderTimeExecutionTo(undefined)
        }

    }  
    const renderUpdateOrderProcedureParameter=(value:any)=>{
        let content=<><Col span={8}><Form.Item
        key={value}
        label={geti18nText('request.details.status')}
        name={"status"}
        //rules={setRules(option.mandatory, option.dataType, option.allowedValues)}
    >
        {selectedProcedureUpdateOrder != undefined &&<Select allowClear onChange={(value) => handleChangeStatus(value)}>
                    {formStatusListUpdateOrder().map((allValue: any, index: any) => {
                        return (
                            <Option key={index} value={allValue.value}>
                                {allValue.title}
                            </Option>
                        );
                    })}
                </Select>}
    </Form.Item>
    </Col>
    {(selectedUpdateOrderStatus != undefined || (selectedProcedureUpdateOrder != undefined && selectedProcedureUpdateOrder>1)) && <Col span={24}><Form.Item
        key={'desc'}
        label={geti18nText('update.order.procedure.description')}
        name={"description"}
        initialValue={formUpdateOrderDescription(selectedUpdateOrderStatus)}
    >
        <Input disabled multiple value={updateOrderDescriptionValue}  ></Input>
    </Form.Item>
    </Col>}
    </>
        switch(value){
            
            case 0:
                return <> {content} 
                {selectedUpdateOrderStatus &&<Form.Item
                    key={'op1'}
                    label={geti18nText('update.order.procedure.0.op1')}
                    name={"op1"}
                >
                    <Input ></Input>
                </Form.Item>}
                </>;
                break;
            case 1:
                return content ;
                break;
            case 2:
                return<>
                {content} 
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===2 &&<Form.Item
                    key={'vrod'}
                    label={geti18nText('update.order.procedure.2.vrod')}
                    name={"vrod"}
                >
                    <DatePicker

                            key={'vrodDate'}
                            format={dateFormat}
                            onChange={(date, dateString) => onChangeDateExecutionFrom(date)}
                        />
                </Form.Item>}
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===2 &&<Form.Item
                    key={'vrdo'}
                    label={geti18nText('update.order.procedure.2.vrdo')}
                    name={"vrdo"}
                >
                    <DatePicker
                            disabledDate={disabledDate}
                            key={'vrodDate'}
                            
                            format={dateFormat}
                            onChange={(date, dateString) => setUpdateOrderTimeExecutionTo(date)}
                        />
                </Form.Item>}
                </>;
                break;
            case 3:
                return <>
                {content} 
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===3 &&<Form.Item
                    key={'op1'}
                    label={geti18nText('update.order.procedure.0.op1')}
                    name={"op1"}
                >
                    <Input ></Input>
                </Form.Item>}
                </>;
                break;
            case 4:
                return <>
                {content} 
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===4 &&<Form.Item
                    key={'dtmre'}
                    label={geti18nText('update.order.procedure.4.dtmre')}
                    name={"dtmre"}
                >
                    <DatePicker
                            key={'dtmre'}
                            format={dateFormat}
                            onChange={(date, dateString) => setUpdateOrderDateOfCompletion(date)}
                        />
                </Form.Item>}
                </>;
                break;
            case 5:
                return <>
                {content} 
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===5 &&<Form.Item
                    key={'adrs'}
                    label={geti18nText('update.order.procedure.5.adrs')}
                    name={"adrs"}
                >
                    <Input ></Input>
                </Form.Item>}
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===5 &&<Form.Item
                    key={'ddog'}
                    label={geti18nText('update.order.procedure.5.ddog')}
                    name={"ddog"}
                >
                    <DatePicker
                            key={'ddogDate'}
                            showTime
                            format={dateTimeFormat}
                            onChange={(date, dateString) => setUpdateOrderDeliveryTime(date)}
                        />
                </Form.Item>}
                </>
                break;
            case 6:
                return <>
                {content} 
                {(selectedUpdateOrderStatus || selectedProcedureUpdateOrder===6) &&<Form.Item
                    key={'dtmre'}
                    label={geti18nText('update.order.procedure.4.dtmre')}
                    name={"dtmre"}
                >
                    <DatePicker
                            key={'dtmre'}
                            format={dateFormat}
                            onChange={(date, dateString) => setUpdateOrderDateOfCompletion(date)}
                        />
                </Form.Item>}
                </>
                break;
            case 7:
                return <>
                {content} 
                
                </>;
                break;
            case 8:
                return <>
                {content} 
                
                </>;
                break;
            case 9:
                return <>
                {content} 
                {selectedUpdateOrderStatus || selectedProcedureUpdateOrder===9 &&<Form.Item
                    key={'ont_serial_number'}
                    label={geti18nText('update.order.procedure.9.desc')}
                    name={"ont_serial_number"}
                >
                    <Input ></Input>
                </Form.Item>}
                </>;
                break;
        }
    }

    const onCancelUpdateOrder=()=>{
        setUpdateModalVisible(false)
        setSelectedProcedureUpdateOrder(undefined);
        setSelectedUpdateOrderStatus(undefined);
        setUpdateOrderDescriptionValue(undefined);
        setUpdateOrderDateOfCompletion(undefined);
        setUpdateOrderTimeExecutionFrom(undefined);
        setUpdateOrderTimeExecutionTo(undefined);
        setUpdateOrderDeliveryTime(undefined);
    }

    return (
        <>
            {' '}
            {loading ? (
                <NySpinner />
            ) : (
                data && (
                    <>
                        <div ref={componentRef}>
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <Title level={4}>
                                        {geti18nText('request.type.' + requestType) + ' - ' + requestType}
                                    </Title>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '35px' }}>
                                <Descriptions
                                    column={1}
                                    style={{ textAlign: 'left' }}
                                    bordered={printPdf === true}
                                    size={'small'}
                                >
                                    {createOrderData && renderCreateOrderParam()}
                                </Descriptions>
                            </Row>

                            <Row style={{ marginTop: '10px' }}>{printPdf === true && renderButton()}</Row>
                            <Divider></Divider>

                            <Timeline mode="right">
                                {createOrderData && (
                                    <Timeline.Item
                                        key={createOrderData.id}
                                        color={'blue'}
                                        dot={<ArrowRightOutlined style={{ fontSize: '22px' }} />}
                                        label={moment(createOrderData.timeGenerated).format('DD.MM.YYYY HH:mm:ss')}
                                    >
                                        <div onClick={() => orderDetails(createOrderData.id)}>
                                            <Text strong style={{ color: '#1494e3', cursor: 'pointer' }} underline>
                                                {geti18nText('request.method.' + createOrderData.requestMethod)}
                                            </Text>
                                        </div>
                                        {createOrderData.username && (
                                            <Row>
                                                <Descriptions
                                                    column={1}
                                                    style={{ textAlign: 'left' }}
                                                    bordered={printPdf === true}
                                                    size={'small'}
                                                >
                                                    <Descriptions.Item
                                                        key={'username'}
                                                        label={geti18nText('ws.request.edit.user')}
                                                    >
                                                        <b>{createOrderData.username}</b>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Row>
                                        )}
                                        <Divider></Divider>
                                    </Timeline.Item>
                                )}
                                {data && renderItems()}
                            </Timeline>

                            <Modal
                                closable={true}
                                title={geti18nText('request.details.btn.cancel.order') + ': ' + data[0].guuid}
                                footer={
                                    <>
                                        <Button type="primary" onClick={() => setCancelModalVisible(false)}>
                                            {geti18nText('app.default.button.cancel')}
                                        </Button>

                                        <Button type="primary" danger onClick={() => cancelOrderConfirm()}>
                                            {geti18nText('request.details.btn.cancel.order')}
                                        </Button>
                                    </>
                                }
                                visible={cancelModalVisible}
                                onCancel={cancelOrderCancel}
                            >
                                <Form form={form}>
                                    <Form.Item
                                        label={geti18nText('request.details.modal.cancel.order.note')}
                                        name="note"
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Modal
                                width={1200}
                                closable={true}
                                destroyOnClose
                                title={geti18nText('request.details.btn.update.order') + ': ' + data[0].guuid}
                                footer={
                                    <>
                                        <Button type="primary" onClick={() => onCancelUpdateOrder()}>
                                            {geti18nText('app.default.button.cancel')}
                                        </Button>

                                        <Button type="primary" danger onClick={() => updateOrderConfirm()}>
                                            {geti18nText('request.details.btn.update.order')}
                                        </Button>
                                    </>
                                }
                                visible={updateModalVisible}
                                onCancel={()=>{onCancelUpdateOrder()}}
                            >
                                <Form form={form}>
                                    <div>{geti18nText('update.order.procedure.title')}</div>
                                    <Select style={{width:'85%',marginBottom:'30px'}} allowClear onChange={(value, option) => handleUpdateOrderProcedureChange(value,option)}>
                                        {updateOrderProceduresList().filter((f:any)=>f.types.includes(createOrderData.requestType)).map((procedure: any, index: any) => {
                                            return (
                                                <Option key={index} value={procedure.value}>
                                                    {procedure.title}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {renderUpdateOrderProcedureParameter(selectedProcedureUpdateOrder)}
                                </Form>
                            </Modal>
                            <WsRequestDetails visible={detailsVisible} id={wsRequestId} closeModal={closeDetailModal} />
                            <Modal
                                visible={modalViewActivity}
                                title={geti18nText('request.log.title')}
                                width={900}
                                footer={null}
                                onCancel={onCancelDeviceModal}
                                destroyOnClose={true}
                            >
                                <NyDataTable
                                    url={CONSTANTS_REQ.LOG_REQUEST.LIST}
                                    showRecordModal={false}
                                    hideButtons={true}
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    columns={columns}
                                    setDefaultPageSize={20}
                                />
                            </Modal>
                            {createOrderData &&<SendRequestIndex
                                values={createOrderData && createOrderData.createOrderParam}
                                visible={detailsVisibleSend}
                                requestType={createOrderData && createOrderData.requestType ? createOrderData.requestType : undefined}
                                closeModal={closeDetailModalSend}
                                wsMethod={createOrderData && createOrderData.requestMethod ? createOrderData.requestMethod : undefined}
                            />}
                        </div>
                    </>
                )
            )}
        </>
    );
});

export default RequestDetails;
