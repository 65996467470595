import logo from './../../assets/images/magic-logo.png';
import {
    EditOutlined,
    LineChartOutlined,
    LogoutOutlined,
    UserOutlined,
    ZoomInOutlined,
    SafetyCertificateOutlined,
    SettingOutlined,
    DatabaseOutlined,
    MailOutlined,
    NodeIndexOutlined,
    DashboardOutlined,
    TeamOutlined,
    AlignCenterOutlined,
    FundOutlined,
} from '@ant-design/icons';
import { geti18nText, NyLanguageSelector, NyLayoutHeader, NyLayoutMenu, NySession, NyUtils } from '@nybble/nyreact';
import { Button, Layout, Menu, Result, Input } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import './index.scss';
import { AppContext } from '../../App';
import { mainRoutes } from './routes';
import LayoutMenu from '../layout-menu';
const { Content } = Layout;

const LayoutMain = () => {
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = useState<any>();

    const { Search } = Input;

    useEffect(() => {
        if (location != null) {
            if (NySession.isUserAuthenticated()) {
                history.push(location.pathname);
            }
        }
    }, []);

    function generateRoutes(routes: any) {
        let retValue = [];
        let route;
        for (route of routes) {
            if (NySession.hasAnyRole(route.role)) {
                retValue.push(
                    <Route
                        key={route.path}
                        // state={null}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                );
            }
        }
        return retValue;
    }

    const menu = [
        {
            key: 'menu.dashboard',
            icon: <DashboardOutlined />,
            i18n: 'menu.dashboard',
            path: '/dashboard',
            role: ['ROLE_B2B_ADMIN','ROLE_B2B_USER'],
        },
        {
            key: 'menu.main.request',
            icon: <ZoomInOutlined />,
            i18n: 'menu.main.request',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
            submenu: [
                {
                    key: 'menu.FA',
                    i18n: 'menu.FA',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                    submenu: [
                        {
                            key: 'menu.FAA',
                            path: '/review/faa',
                            i18n: 'menu.FAA',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.FAS',
                            path: '/review/fas',
                            i18n: 'menu.FAS',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },

                        {
                            key: 'menu.FAD',
                            path: '/review/fad',
                            i18n: 'menu.FAD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                    ],
                },
                {
                    key: 'menu.PIA',
                    i18n: 'menu.PIA',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                    submenu: [
                        {
                            key: 'menu.PIAS',
                            path: '/review/pias',
                            i18n: 'menu.PIAS',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.PIAA',
                            path: '/review/piaa',
                            i18n: 'menu.PIAA',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.PIAB',
                            path: '/review/piab',
                            i18n: 'menu.PIAB',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.PIABD',
                            path: '/review/piabd',
                            i18n: 'menu.PIABD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        
                        {
                            key: 'menu.PIAN',
                            path: '/review/pian',
                            i18n: 'menu.PIAN',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.PIAP',
                            path: '/review/piap',
                            i18n: 'menu.PIAP',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.PIAD',
                            path: '/review/piad',
                            i18n: 'menu.PIAD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.PIAL',
                            path: '/review/pial',
                            i18n: 'menu.PIAL',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },

                    ],
                },
                {
                    key: 'menu.DSL',
                    i18n: 'menu.DSL',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                    submenu: [
                        {
                            key: 'menu.DSLA',
                            path: '/review/dsla',
                            i18n: 'menu.DSLA',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.DSLD',
                            path: '/review/dsld',
                            i18n: 'menu.DSLD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.DSLP',
                            path: '/review/dslp',
                            i18n: 'menu.DSLP',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                    ]
                },
                {
                    key: 'menu.HTSL',
                    path: '/review/htsl',
                    i18n: 'menu.HTSL',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.NBS',
                    i18n: 'menu.NBS',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                    submenu: [
                        {
                            key: 'menu.NBSA',
                            path: '/review/nbsa',
                            i18n: 'menu.NBSA',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NBSD',
                            path: '/review/nbsd',
                            i18n: 'menu.NBSD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NBSP',
                            path: '/review/nbsp',
                            i18n: 'menu.NBSP',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NBSM',
                            path: '/review/nbsm',
                            i18n: 'menu.NBSM',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NBSS',
                            path: '/review/nbss',
                            i18n: 'menu.NBSS',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NBSZ',
                            path: '/review/nbsz',
                            i18n: 'menu.NBSZ',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                    ]
                },
                {
                    key: 'menu.NST',
                    i18n: 'menu.NST',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                    submenu: [
                        {
                            key: 'menu.NSTA',
                            path: '/review/nsta',
                            i18n: 'menu.NSTA',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NSTP',
                            path: '/review/nstp',
                            i18n: 'menu.NSTP',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NSTD',
                            path: '/review/nstd',
                            i18n: 'menu.NSTD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NSTM',
                            path: '/review/nstm',
                            i18n: 'menu.NSTM',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                    ]
                },
                {
                    key: 'menu.NVO',
                    i18n: 'menu.NVO',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                    submenu: [
                        {
                            key: 'menu.NVOA',
                            path: '/review/nvoa',
                            i18n: 'menu.NVOA',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NVOP',
                            path: '/review/nvop',
                            i18n: 'menu.NVOP',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        {
                            key: 'menu.NVOD',
                            path: '/review/nvod',
                            i18n: 'menu.NVOD',
                            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                        },
                        
                    ]
                },
            ],
        },
        {
            key: 'menu.main.report.fault',
            icon: <ZoomInOutlined />,
            i18n: 'menu.main.report.fault',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
            submenu: [
                {
                    key: 'menu.SFA',
                    path: '/reviewReportFault/sfa',
                    i18n: 'menu.SFA',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.SPIA',
                    path: '/reviewReportFault/spia',
                    i18n: 'menu.SPIA',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.SVA',
                    path: '/reviewReportFault/sva',
                    i18n: 'menu.SVA',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.SNBS',
                    path: '/reviewReportFault/snbs',
                    i18n: 'menu.SNBS',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                 {
                    key: 'menu.ESCL',
                    path: '/reviewReportFault/escl',
                    i18n: 'menu.ESCL',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
            ],
        },

        {
            key: 'menu.main.get.data',
            icon: <ZoomInOutlined />,
            i18n: 'menu.main.get.data',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
            submenu: [
                {
                    key: 'menu.main.distribution.node.data',
                    icon: <NodeIndexOutlined />,
                    path: '/distribution-nodes-data',
                    i18n: 'menu.main.distribution.node.data',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },

                {
                    key: 'menu.main.check.availability',
                    icon: <NodeIndexOutlined />,
                    path: '/check-availability',
                    i18n: 'menu.main.check.availability',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.main.dslam.free.capacity.data',
                    icon: <NodeIndexOutlined />,
                    path: '/dslam-free-capacity-data',
                    i18n: 'menu.main.dslam.free.capacity.data',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.reset.dslam.settings.data',
                    icon: <NodeIndexOutlined />,
                    path: '/reset-dslam-settings-data',
                    i18n: 'menu.reset.dslam.settings.data',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.dslam.configuration.data',
                    icon: <NodeIndexOutlined />,
                    path: '/dslam-configuration-data',
                    i18n: 'menu.dslam.configuration.data',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.main.olt.free.capacity.data',
                    icon: <NodeIndexOutlined />,
                    path: '/olt-free-capacity-data',
                    i18n: 'menu.main.olt.free.capacity.data',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.collocation.divider',
                    icon: <NodeIndexOutlined />,
                    path: '/location-divider',
                    i18n: 'menu.collocation.divider',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.connection.location',
                    icon: <NodeIndexOutlined />,
                    path: '/connection-location',
                    i18n: 'menu.connection.location',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.check.operator.information',
                    icon: <NodeIndexOutlined />,
                    path: '/check-operator-information',
                    i18n: 'menu.check.operator.information',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.street.coverage.by.location',
                    icon: <NodeIndexOutlined />,
                    path: '/street-coverage-by-location',
                    i18n: 'menu.street.coverage.by.location',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.search.location.by.phone',
                    icon: <NodeIndexOutlined />,
                    path: '/search-location-by-phone',
                    i18n: 'menu.search.location.by.phone',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.info.about.network.topology',
                    icon: <NodeIndexOutlined />,
                    path: '/info-about-network-topology',
                    i18n: 'menu.info.about.network.topology',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.ftth.availability',
                    icon: <NodeIndexOutlined />,
                    path: '/ftth-availability',
                    i18n: 'menu.ftth.availability',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.adsl.vdsl.availability',
                    icon: <NodeIndexOutlined />,
                    path: '/adsl-vdsl-availability',
                    i18n: 'menu.adsl.vdsl.availability',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.new.installation.fttb.fttdp',
                    icon: <NodeIndexOutlined />,
                    path: '/new-installation-fttb-fttdp',
                    i18n: 'menu.new.installation.fttb.fttdp',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
            ],
        },

        
        /* {
            key: 'menu.EKI',
            icon: <ZoomInOutlined />,
            i18n: 'menu.EKI',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
            submenu: [
                {
                    key: 'menu.PIAB',
                    path: '/review/piab',
                    i18n: 'menu.PIAB',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.PIABD',
                    path: '/review/piabd',
                    i18n: 'menu.PIABD',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.PIAS',
                    path: '/review/pias',
                    i18n: 'menu.PIAS',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
            ],
        }, */

        {
            key: 'menu.information.ht',
            path: '/information-ht',
            icon: <LineChartOutlined />,
            i18n: 'menu.information.ht',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
        },
        {
            key: 'menu.reports',
            icon: <AlignCenterOutlined />,
            i18n: 'menu.reports',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
            submenu: [
                {
                    key: 'menu.reports.NBSA',
                    path: '/nbsa-report',
                    icon: <FundOutlined />,
                    i18n: 'menu.reports.NBSA',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                }
            ]
        },
        
        {
            key: 'menu.settings',
            icon: <SettingOutlined />,
            i18n: 'menu.settings',
            role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER','ROLE_TENANT_ADMIN'],
            submenu: [

                {
                    key: 'menu.cert',
                    path: '/cert',
                    icon: <SafetyCertificateOutlined />,
                    i18n: 'menu.cert',
                    role: ['ROLE_TENANT_ADMIN'],
                },
                {
                    key: 'menu.ws.request',
                    path: '/ws-request',
                    icon: <LineChartOutlined />,
                    i18n: 'menu.ws.request',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.mail',
                    path: '/mail',
                    icon: <MailOutlined />,
                    i18n: 'menu.mail',
                    role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
                },
                {
                    key: 'menu.admin.user',
                    icon: <UserOutlined />,
                    i18n: 'menu.user',
                    path: '/user',
                    role: ['ROLE_B2B_ADMIN'],
                },
                
            ],
        },
        {
            key: 'menu.admin.clients',
            icon: <TeamOutlined />,
            i18n: 'menu.clients',
            path: '/clients',
            role: ['ROLE_TENANT_ADMIN'],
        },
        
        
    ];

    function headerSelect(value: any) {
        if (value.key === 'logout') {
            NySession.logoutUser();
            sessionStorage.removeItem('IoTSettings');
            history.push('/login');
        }
    }

    const headerContent = () => {
        return (
            <div style={{ display:'inline-flex', width: '100%' }}>
                <div style={{ marginLeft: '450px', float: 'left', width:'70%' }}>
                    <h3>{NySession.getSetting('ux').title}</h3>
                </div>
                <div style={{ float: 'right', width:'30%' }}>
                    <Menu mode="horizontal" className="header-menu" onSelect={headerSelect}>
                        <Menu.Item key="version">
                            {geti18nText('login.build')} <p className="version">{NySession.getAppValue('VERSION')}</p>
                        </Menu.Item>
                        <SubMenu
                            className="ant-menu-item"
                            title={
                                <span className="submenu-title-wrapper">
                                    <UserOutlined />
                                    {NySession.getUser().fullName}
                                </span>
                            }
                        >
                            <Menu.Item key="logout">
                                <LogoutOutlined />
                                {geti18nText('navbar.user.logout')}
                            </Menu.Item>
                        </SubMenu>
                        {NySession.getAppValue('I18N') != null && (
                            <Menu.Item key="lang">
                                <NyLanguageSelector
                                    languages={NySession.getAppValue('I18N').language.available}
                                    selectedLanguage={NyUtils.getSelectedLanguage()}
                                    onSelect={(lang: object) => {
                                        NyUtils.saveDefaults(lang);
                                    }}
                                    reloadPage={true}
                                />
                            </Menu.Item>
                        )}
                    </Menu>
                </div>
            </div>
        );
    };

    const MenuLogo = () => {
        const [searchKey, setSearchKey] = useState<any>(Date.now());

        const LoginLogo = () => (
            <img src={logo} alt="BtcAuth" style={{ width: '230px', marginLeft: '0px', padding: '0px' }} />
        );

        const onSearch = (value: string) => {
            setSearchKey(Date.now());
            if (value.length > 0) {
                history.push({ pathname: '/search/' + encodeURIComponent(value) });
            }
        };

        return (
            <React.Fragment>
                <div className="menu-search" style={{ marginTop: '7px', marginLeft: '5px' }}>
                    <LoginLogo />
                    <Search
                        key={searchKey}
                        placeholder={geti18nText('search.placeholder')}
                        onSearch={onSearch}
                        style={{ width: 300, marginLeft: '18px', marginTop: '5px' }}
                    />
                </div>
            </React.Fragment>
        );
    };

    if (!NySession.isUserAuthenticated()) {
        return <Redirect to="/login" />;
    } else {
        return (
            <Layout>
                <NyLayoutHeader headerClassName="ny-header" headerContent={headerContent()} />
                <Layout>
                    <LayoutMenu
                        menuItems={menu}
                        menuHistory={history}
                        menuLocation={location}
                        menuTheme="dark"
                        menuLogo={MenuLogo()}
                        menuSiderBackgroundColor="#001529"
                        siderClassName="ny-sider"
                        menuClassName="ny-menu"
                        menuWidth={350}
                    />
                    <Layout className="main-layout">
                        <Content className="main-content thin-scrollbar">
                            <AppContext.Provider value={{ state, setState }}>
                                <Switch>
                                    {generateRoutes(mainRoutes)}
                                    <Route
                                        path="*"
                                        render={(props) => (
                                            <Result
                                                status="404"
                                                title="404"
                                                subTitle={geti18nText('app.default.404')}
                                                extra={
                                                    <Button type="primary">
                                                        {geti18nText('app.default.404.back')}
                                                    </Button>
                                                }
                                            />
                                        )}
                                    />
                                </Switch>
                            </AppContext.Provider>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
};

export default LayoutMain;
