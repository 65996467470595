import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { Descriptions, Form } from 'antd';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';

const DslamConfigurationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('dslam.configuration.data.table.header'));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(undefined);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
 
    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('dslam.configuration.data.table.header'));
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('dslam.configuration.data.table.header')+ ' '+dataForm.getDate);
         setData(dataForm)
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DSLAM_CONFIGURATION.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            hideButtons
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            //normalize={normalizeValues}
            {...props}
        >
            {data &&<>
            <Descriptions contentStyle={{ fontWeight: 'bold' }}>
                    {data.id_usluge && <Descriptions.Item label={geti18nText('dslam.configuration.table.service.id')}>
                            {data.id_usluge}
                    </Descriptions.Item>}
                    {data.konfig_dslam && <Descriptions.Item label={geti18nText('dslam.configuration.table.konfig.dslam')}>
                            {data.konfig_dslam}
                    </Descriptions.Item>}
                    {data.st_port && <Descriptions.Item label={geti18nText('dslam.configuration.table.st.port')}>
                            {data.st_port}
                    </Descriptions.Item>}
                    {data.trenbrz && <Descriptions.Item label={geti18nText('dslam.configuration.table.trenbrz')}>
                            {data.trenbrz}
                    </Descriptions.Item>}
                    {data.profil && <Descriptions.Item label={geti18nText('dslam.configuration.table.profil')}>
                            {data.profil}
                    </Descriptions.Item>}
                    {data.tehsuc && <Descriptions.Item label={geti18nText('dslam.configuration.table.tehsuc')}>
                            {data.tehsuc}
                    </Descriptions.Item>}
                    {data.brz && <Descriptions.Item label={geti18nText('dslam.configuration.table.brz')}>
                            {data.brz}
                    </Descriptions.Item>}
                    
                    {data.voip && <Descriptions.Item label={geti18nText('dslam.configuration.table.voip')}>
                            {data.voip}
                    </Descriptions.Item>}
                    {data.iptiv && <Descriptions.Item label={geti18nText('dslam.configuration.table.iptiv')}>
                            {data.iptiv}
                    </Descriptions.Item>}
                    {data.vkdod1 && <Descriptions.Item label={geti18nText('dslam.configuration.table.vkdod1')}>
                            {data.vkdod1}
                    </Descriptions.Item>}
                    {data.vkdod2 && <Descriptions.Item label={geti18nText('dslam.configuration.table.vkdod2')}>
                            {data.vkdod2}
                    </Descriptions.Item>}
                    {data.linprof && <Descriptions.Item label={geti18nText('dslam.configuration.table.linprof')}>
                            {data.linprof}
                    </Descriptions.Item>}


            </Descriptions>
            </>
            }
        </NyDataEdit>
    );
};

export default DslamConfigurationEdit;
