import { geti18nText, NySession } from '@nybble/nyreact';
import { Layout, Menu } from 'antd';
import { useContext, useEffect, useState } from 'react';
import './index.scss';
import { INyLayoutMenu } from './types';
import { useLocation } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu } = Menu;

const LayoutMenu = ({
    menuHistory,
    menuItems,
    menuLocation,
    menuLogo,
    menuTheme = 'light',
    menuWidth = 200,
    siderClassName,
    menuClassName,
    noRoles = false,
}: INyLayoutMenu) => {
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [breakpoint, setBreakpoint] = useState<boolean>(false);
    let location:any = useLocation();

    useEffect(() => {
        checkOverflow()
        setSelectedKeys(findKey(menuItems, undefined, menuLocation.pathname));
    }, []);

    useEffect(()=>{
        if(location && location.state && location.state.selected){
            setSelectedKeys(findKey(menuItems, undefined, location.pathname));

        }
    },[location])

    const checkOverflow = () => {
        let elements = Array.from(document.getElementsByClassName('menu-element'));
        elements.forEach((element: any) => {
            if (0 > element.clientWidth - element.scrollWidth) {
                element.classList.add('marquee');
            }
        });
    };


    

    function generateMenu(menus: any) {
        let retValue = [];
        let menu;
        for (menu of menus) {
            if (NySession.hasAnyRole(menu.role) || noRoles) {
                if (menu.submenu) {
                    retValue.push(
                        <SubMenu
                            key={menu.key}
                            onTitleClick={menuSelected}
                            icon={menu.icon}
                            title={
                                <span >
                                  
                                    {geti18nText(menu.i18n)}
                                </span>
                            }
                        >
                            {generateMenu(menu.submenu)}
                        </SubMenu>
                    );
                } else {
                    retValue.push(
                        <Menu.Item icon={menu.icon} key={menu.key}>
                            <div
                                    className="menu-element"
                                    
                                >
                                    <span>
                                        <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                            {geti18nText(menu.i18n)}
                                        </span>
                                    </span>
                                </div>
                        </Menu.Item>
                    );
                }
            }
        }
        return retValue;
    }

    function menuSelected(selected: { key: any }) {
        setSelectedKeys(findKey(menuItems, selected.key));
        setTimeout(checkOverflow, 500);
       
        
    }

    function findKey(menus: any, key: any, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch: any = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                menuHistory.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                return [menu.key];
            }
        }
        return undefined;
    }

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            width={menuWidth}
            className={siderClassName}
            onCollapse={(collapsed) => {
                setCollapsed(collapsed);
            }}
            onBreakpoint={(broken) => {
                setBreakpoint(broken);
            }}
            style={{
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            <Menu
                theme={menuTheme}
                mode="inline"
                className={menuClassName}
                onClick={menuSelected}
                selectedKeys={selectedKeys}
                openKeys={selectedKeys}
            >
                {!collapsed && menuLogo}
                {generateMenu(menuItems)}
            </Menu>
        </Sider>
    );
};

export default LayoutMenu;
