import { CheckOutlined, CloseOutlined, ZoomInOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    geti18nText,
    getColumnSearch,
    getColumnDateOption,
    getColumnSearchOption,
    ENUMS,
    NyUtils,
    NySession,
    NyRequestResolver,
    RESPONSE,
    NySearchField,
} from '@nybble/nyreact';
import { Button, Col, Input, message, notification, Popover, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ, SettingsKey } from '../../utils/Constants';

const SearchLocationByPhoneIndex = () => {
    const { Option } = Select;
    const [selectedTelBr, setSelectedTelBr] = useState<any>(null);
    const [selectedAdr, setSelectedAdr] = useState<any>(null);
    const [serviceID, setServiceID] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const timeout: any = useRef(null);


    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }


    function onRefreshClick(e: any) {
        setLoading(true);
        const params:any = {  };
        if(selectedTelBr) {
            params.telbr= selectedTelBr
        }
        if(selectedAdr) {
            params.adr=selectedAdr
        }
        if(serviceID) {
            params.idusl=serviceID
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.SEARCH_LOCATION_BY_PHONE.DATA, undefined, {
            params: params,
            requestType: 51,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
            setSelectedAdr(null)
            setSelectedTelBr(null)
            setServiceID(null)
        });
    }

    const onchangeTelBr=(value:any)=>{

        setSelectedTelBr(value.target.value &&value.target.value!== ""?value.target.value:null)          
    }
    
    const onchangeAddress=(value:any)=>{
        setSelectedAdr(value.target.value &&value.target.value!== ""?value.target.value:null)          
    }
    
    const onchangeServiceID=(value:any)=>{
        setServiceID(value.target.value &&value.target.value!== ""?value.target.value:null)          
    }

    return (
        <>
            <Row gutter={24}  style={{alignItems: 'end'}}>
                <Col span={6}>
                    <div>{geti18nText('search.location.by.phone.number')}</div>
                    <Input value={selectedTelBr}  onChange={(e)=>onchangeTelBr(e)}></Input>
                </Col>
                <Col>
                    <Button loading={loading} type="primary" disabled={selectedTelBr == null && selectedAdr==null && serviceID==null} onClick={onRefreshClick}>
                        {geti18nText('get.data.fetch.data')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={24} style={{marginTop:10}}>
                <Col span={6}>
                    <div>{geti18nText('search.location.by.phone.idusl')}</div>
                    <Input value={serviceID}  onChange={(e)=>onchangeServiceID(e)}></Input>
                </Col>
            </Row>
            <Row gutter={24} style={{marginTop:10}}>
                <Col span={6}>
                    <div>{geti18nText('search.location.by.phone.adr')}</div>
                    <Input value={selectedAdr}  onChange={(e)=>onchangeAddress(e)}></Input>
                </Col>
            </Row>
            

            <NyDataTable
                key={tableKey}
                headerTitle={geti18nText('menu.search.location.by.phone')}
                url={CONSTANTS_REQ.SEARCH_LOCATION_BY_PHONE.LIST}
                readonly
                hideNewButton={true}
                setDefaultPageSize={20}
                setDefaultFilterValue={setDefaultFilterValue}
                exportCSV={true}
                colCSVCustomization={[
                    {
                        county: (value: any) => {
                            if (value) {
                                return value.name;
                            }
                            return '';
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('app.default.active');
                            } else {
                                return geti18nText('app.default.inactive');
                            }
                        },
                    },
                    {
                        getDate: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale()) : '';
                        },
                    },
                ]}
                columns={[
                    {
                        title: geti18nText('dslam.free.capacity.data.table.details'),
                        render: (text: any, record: any) => {
                            return <Popover placement='right' content={<div style={{width:'100%'}}>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>
                                    <div style={{flexDirection:'row', display:'flex', flex: '1 0 30%'}}>
                                    <p>{geti18nText('dslam.free.capacity.data.table.id')+': '}</p>
                                    <p>{record.id}</p>
                                    </div>
                                    <div style={{flexDirection:'row', display:'flex', flex: '1 0 30%'}}>
                                    <p >{geti18nText('check.operator.information.telBr')+': '}</p>
                                    <p>{record.telBr}</p>
                                    </div>
                                    <div style={{flexDirection:'row', display:'flex', flex: '1 0 30%'}}>
                                    <p >{geti18nText('search.location.by.phone.idusl')+': '}</p>
                                    <p>{record.idusl}</p>
                                    </div>
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.adr && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('search.location.by.phone.adr')+': '}</p>
                                    <p>{record.adr}</p></div>}
                                    {record.lokRazd && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('street.coverage.by.location.loc.razd')+': '}</p>
                                    <p>{record.lokRazd}</p></div>}
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.vekt && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('search.location.by.phone.vekt')+': '}</p>
                                    <p>{record.vekt}</p></div>}
                                    {record.svekt && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('search.location.by.phone.svekt')+': '}</p>
                                    <p>{record.svekt}</p></div>}
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.vrcvo && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('search.location.by.phone.vrcvo')+': '}</p>
                                    <p>{record.vrcvo}</p></div>}
                                    {record.lokRazdSt && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('search.location.by.phone.lokaRazdSt')+': '}</p>
                                    <p>{record.lokRazdSt}</p></div>}
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.lokAdrSt && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('search.location.by.phone.adrRazdSt')+': '}</p>
                                    <p>{record.lokAdrSt}</p></div>}
                                    
                                    
                                </div>
                              </div>}>
                                <ZoomInOutlined style={{fontSize:20 , color:'#1890ff', marginLeft: '5px' }}/>
                            </Popover>
                        },
                    },
                    {
                        title: geti18nText('distribution.node.table.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('check.operator.information.telBr'),
                        dataIndex: 'telBr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('search.location.by.phone.adr'),
                        dataIndex: 'adr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.loc.razd'),
                        dataIndex: 'lokRazd',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.adr.loc'),
                        dataIndex: 'lokAdr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.vekt'),
                        dataIndex: 'vekt',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('distribution.node.table.get_date'),
                        dataIndex: 'getDate',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.getDate) {
                                return (
                                    <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                );
                            }
                        },
                    },

                    {
                        title: geti18nText('users.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                    },
                ]}
            />
        </>
    );
};

export default SearchLocationByPhoneIndex;
