import { NyUtils } from '@nybble/nyreact';

export function GetParameterValue(value: any, dataType: string, allowedValues: string) {
    switch (dataType) {
        case 'string':
            return value + '';
        case 'integer':
            return value + '';
        case 'date':
            return new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
        case 'select':
            if (allowedValues) {
                const map1 = new Map();
                let arr = allowedValues.split('|');
                arr.forEach((value: string) => {
                    let idValue = value.toString().includes(':') ? value.toString().split(':') : value;
                    if (idValue instanceof Array) {
                        map1.set(idValue[0], idValue[1]);
                    } else {
                        map1.set(idValue, idValue);
                    }
                });
                return map1.get(typeof value === 'number'? value.toString().replace(".",","): value.toString());
            }
            return value + '';
        default:
            return '';
    }
}
