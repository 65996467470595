import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataEdit, NyDataTable, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Modal, Form, Row, Col, Input, Divider, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ClientEdit from './edit';

const ClientsIndex = (props: any) => {
    
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('client.table.header')}
            url={CONSTANTS_REQ.TENANT.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('clients.table.add')}
            modalComponent={ClientEdit}
            setDefaultPageSize={20}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('users.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('client.edit.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('client.edit.code'),
                    dataIndex: 'code',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('client.edit.domain'),
                    dataIndex: 'domain',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('client.edit.port'),
                    dataIndex: 'port',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('client.edit.operator.id'),
                    dataIndex: 'operatorId',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('client.edit.operator.name'),
                    dataIndex: 'operatorName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('client.edit.mail.address'),
                    dataIndex: 'mailAddresses',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                
                {
                    title: geti18nText('users.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]}
        />
    );
};


export default ClientsIndex;
