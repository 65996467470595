import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    geti18nText,
    getColumnSearch,
    getColumnDateOption,
    getColumnSearchOption,
    ENUMS,
    NyUtils,
    NySession,
    NyRequestResolver,
    RESPONSE,
    NySearchField,
} from '@nybble/nyreact';
import { Button, Col, Input, message, notification, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ, SettingsKey } from '../../utils/Constants';

const StreetCoverageByLocationIndex = () => {
    const { Option } = Select;
    const [selectedCity, setSelectedCity] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const timeout: any = useRef(null);
    const [selectedCounty, setSelectedCounty] = useState<any>(null);
    useEffect(() => {
        setTableKey(Math.random().toString(36));
    }, [selectedCounty]);


    function setDefaultFilterValue() {
        if (selectedCounty && selectedCounty.id !== -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'zup_id', condition: 'equals', value: selectedCounty.id },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }


    function onRefreshClick(e: any) {
        setLoading(true);
        const params:any = {  };
       
        if(selectedCity) {
            params.grad=selectedCity
        }
        if(selectedCounty) {
            params.zup=selectedCounty.name
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.STREET_COVERAGE_BY_LOCATION.DATA, undefined, {
            params: params,
            requestType: 50,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
            setSelectedCity(null)
        });
    }

   
    
    const onchangeCity=(value:any)=>{
        setSelectedCity(value.target.value &&value.target.value!== ""?value.target.value:null)          
    }

    return (
        <>
            <Row gutter={24}  style={{alignItems: 'end'}}>
                <Col span={6}>
                    <div>{geti18nText('connection.location.zup')}</div>
                        <NySearchField
                            style={{width:'100%'}}
                            onChange={setSelectedCounty}
                            url={CONSTANTS_REQ.COUNTY.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                        
                            
                        />
                </Col>
                <Col>
                    <Button loading={loading} type="primary" disabled={selectedCity == null || selectedCounty==null} onClick={onRefreshClick}>
                        {geti18nText('get.data.fetch.data')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={24} style={{marginTop:10}}>
                <Col span={6}>
                    <div>{geti18nText('street.coverage.by.location.city')}</div>
                    <Input value={selectedCity}  onChange={(e)=>onchangeCity(e)}></Input>
                </Col>
            </Row>

            <NyDataTable
                key={tableKey}
                headerTitle={geti18nText('menu.street.coverage.by.location')}
                url={CONSTANTS_REQ.STREET_COVERAGE_BY_LOCATION.LIST}
                readonly
                hideNewButton={true}
                setDefaultPageSize={20}
                setDefaultFilterValue={setDefaultFilterValue}
                exportCSV={true}
                colCSVCustomization={[
                    {
                        county: (value: any) => {
                            if (value) {
                                return value.name;
                            }
                            return '';
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('app.default.active');
                            } else {
                                return geti18nText('app.default.inactive');
                            }
                        },
                    },
                    {
                        getDate: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale()) : '';
                        },
                    },
                ]}
                columns={[
                    {
                        title: geti18nText('distribution.node.table.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('distribution.node.table.zup'),
                        dataIndex: ['county','name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.county) {
                                return <div>{record.county.name}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('street.coverage.by.location.city'),
                        dataIndex: 'grad',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.loc.razd'),
                        dataIndex: 'lokRazd',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.adr.loc'),
                        dataIndex: 'lokAdr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.street'),
                        dataIndex: 'ulica',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('street.coverage.by.location.street.n.from'),
                        dataIndex: 'kucBroOd',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    }, 
                    {
                        title: geti18nText('street.coverage.by.location.street.n.to'),
                        dataIndex: 'kucBroDo',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('street.coverage.by.location.vekt'),
                        dataIndex: 'vekt',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('distribution.node.table.get_date'),
                        dataIndex: 'getDate',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.getDate) {
                                return (
                                    <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                );
                            }
                        },
                    },

                    {
                        title: geti18nText('users.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                    },
                ]}
            />
        </>
    );
};

export default StreetCoverageByLocationIndex;
