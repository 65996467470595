import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyRequestResolver,
    RESPONSE,
    NySpinner,
    NyDataTable,
    getColumnSearch,
    ENUMS,
    getColumnSearchOption,
    NyUtils,
} from '@nybble/nyreact';
import { Button, Col, Descriptions, message, Modal, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

interface IDistributionNodeArea {
    lokDslam: string;
    visible: boolean;
    closeModal: () => void;
}

const DSLAMFreeCapacityAreaIndex = ({ lokDslam, visible, closeModal }: IDistributionNodeArea) => {
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    function onRefreshClick(e: any) {
        setLoading(true);
        const params = { lokDslam: lokDslam };
        NyRequestResolver.requestPost(CONSTANTS_REQ.DSLAM_FREE_CAPACITY_AREA.DATA, undefined, {
            params: params,
            requestType: 45,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    }

    return (
        <Modal
            visible={visible}
            title={geti18nText('dslam.free.capacity.area.details.title')}
            width={1200}
            onCancel={closeModal}
            getContainer={false}
            destroyOnClose={true}
            footer={null}
        >
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    <NyDataTable
                        key={tableKey}
                        headerTitle={geti18nText('dslam.free.capacity.area.header')}
                        url={CONSTANTS_REQ.DSLAM_FREE_CAPACITY_AREA.LIST}
                        showRecordModal={false}
                        hideNewButton={true}
                        readonly
                        addedData={{
                            lokDslam: lokDslam,
                        }}
                        exportCSV={false}
                        colCSVCustomization={[
                            {
                                active: (value: boolean) => {
                                    if (value === true) {
                                        return geti18nText('app.default.active');
                                    } else {
                                        return geti18nText('app.default.inactive');
                                    }
                                },
                            },
                            {
                                getDate: (value: string) => {
                                    return value != null
                                        ? new Date(value).toLocaleString(NyUtils.getSelectedLocale())
                                        : '';
                                },
                            },
                            {
                                najIzgr: (value: string) => {
                                    return value != null
                                        ? new Date(value).toLocaleDateString(NyUtils.getSelectedLocale())
                                        : '';
                                },
                            },
                        ]}
                        setDefaultPageSize={20}
                        setDefaultFilterValue={setDefaultFilterValue}
                        columns={[
                            {
                                title: geti18nText('dslam.free.capacity.area.table.loks'),
                                dataIndex: 'lokDslam',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('dslam.free.capacity.area.table.city'),
                                dataIndex: 'lokGrad',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('dslam.free.capacity.area.table.settlement'),
                                dataIndex: 'nazNas',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('dslam.free.capacity.area.table.street'),
                                dataIndex: 'nazUl',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('distribution.node.area.details.get_date'),
                                dataIndex: 'getDate',
                                sorter: (a: any, b: any) => {},
                                render: (text: string, record: { [index: string]: any }) => {
                                    if (record.getDate) {
                                        return (
                                            <div>
                                                {new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}
                                            </div>
                                        );
                                    }
                                },
                            },
                            {
                                title: geti18nText('distribution.node.table.get_date'),
                                dataIndex: 'getDate',
                                sorter: (a: any, b: any) => {},
                                render: (text: string, record: { [index: string]: any }) => {
                                    if (record.getDate) {
                                        return (
                                            <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                        );
                                    }
                                },
                            }
                        ]}
                    />
                    <Row justify="end">
                        <Col style={{ marginTop: '20px' }}>
                            <Button loading={loading} type="primary" onClick={onRefreshClick}>
                                {geti18nText('distribution.node.area.details.refresh.btn')}
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Modal>
    );
};

export default DSLAMFreeCapacityAreaIndex;
