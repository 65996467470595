import ReviewRequestResponse from '../../views/review-request-response';
import RequestDetails from '../../views/review-request-response/edit';
import WsRequestIndex from '../../views/ws-request';
import CertManagment from '../../views/cert-managment';
import GetDataIndex from '../../views/get-data';
import UserIndex from '../../views/users/edit';
import MailSettingsEdit from '../../views/email_settings';
import ReviewReportFault from '../../views/review-report-fault';
import ReportFaultDetails from '../../views/review-report-fault/edit';
import DistributionNodesDataIndex from '../../views/distribution-nodes-data';
import DSLAMFreeCapacityDataIndex from '../../views/dslam-free-capacity';
import ResetDslamSettings from '../../views/resetDslamSettings';
import DslamConfiguration from '../../views/dslam-configuration';
import CheckAvailability from '../../views/check-availability';
import Dashboard from '../../views/dashboard';
import ClientsIndex from '../../views/clients';
import InformationHt from '../../views/information-ht';
import OltFreeCapacityDataIndex from '../../views/olt-capacity-data';
import ColocationDividerIndex from '../../views/collocation-divider';
import ConnectionLocationIndex from '../../views/connection-location';
import CheckOperatorInformationIndex from '../../views/check-operator-information';
import StreetCoverageByLocationIndex from '../../views/street-coverage-by-location';
import SearchLocationByPhoneIndex from '../../views/search-location-by-phone';
import InfoAboutNetworkTopologyIndex from '../../views/info-about-network-topology';
import FthAvailabilityIndex from '../../views/ftth-availability';
import AdslVdslAvailabilityIndex from '../../views/adsl-vdsl-availability';
import NewInstallationFttbFttdpIndex from '../../views/new-installation-fttb-fttdp';
import NbsaReport from '../../views/nbsa-report';

export const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: Dashboard,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/review/:type',
        exact: true,
        component: ReviewRequestResponse,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/search/:searchPattern',
        exact: true,
        component: ReviewRequestResponse,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/review/:guuid/details',
        exact: true,
        component: RequestDetails,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/reviewReportFault/:guuid/details',
        exact: true,
        component: ReportFaultDetails,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/ws-request',
        exact: true,
        component: WsRequestIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/cert',
        exact: true,
        component: CertManagment,
        role: ['ROLE_TENANT_ADMIN'],
    },
    {
        path: '/get-data',
        exact: true,
        component: GetDataIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/user',
        exact: true,
        component: UserIndex,
        role: ['ROLE_B2B_ADMIN'],
    },
    {
        path: '/mail',
        exact: true,
        component: MailSettingsEdit,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/distribution-nodes-data',
        exact: true,
        component: DistributionNodesDataIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/dslam-free-capacity-data',
        exact: true,
        component: DSLAMFreeCapacityDataIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/reset-dslam-settings-data',
        exact: true,
        component: ResetDslamSettings,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/dslam-configuration-data',
        exact: true,
        component: DslamConfiguration,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    
    {
        path: '/check-availability',
        exact: true,
        component: CheckAvailability,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/reviewReportFault/:type',
        exact: true,
        component: ReviewReportFault,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/dashboard',
        exact: true,
        component: Dashboard,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/information-ht',
        exact: true,
        component: InformationHt,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/olt-free-capacity-data',
        exact: true,
        component: OltFreeCapacityDataIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/location-divider',
        exact: true,
        component: ColocationDividerIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/connection-location',
        exact: true,
        component: ConnectionLocationIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    
    {
        path: '/check-operator-information',
        exact: true,
        component: CheckOperatorInformationIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/street-coverage-by-location',
        exact: true,
        component: StreetCoverageByLocationIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/search-location-by-phone',
        exact: true,
        component: SearchLocationByPhoneIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/info-about-network-topology',
        exact: true,
        component: InfoAboutNetworkTopologyIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/ftth-availability',
        exact: true,
        component: FthAvailabilityIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/adsl-vdsl-availability',
        exact: true,
        component: AdslVdslAvailabilityIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/new-installation-fttb-fttdp',
        exact: true,
        component: NewInstallationFttbFttdpIndex,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
    {
        path: '/clients',
        exact: true,
        component: ClientsIndex,
        role: ['ROLE_TENANT_ADMIN'],
    },
    {
        path: '/nbsa-report',
        exact: true,
        component: NbsaReport,
        role: ['ROLE_B2B_ADMIN', 'ROLE_B2B_USER'],
    },
];
