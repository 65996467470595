import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Modal, Form, Row, Col, Input, Divider, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';

const UserEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('user.table.header.new'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [userItem, setUserItem] = useState<any>(null);
    const [passwordValidateModal, setPasswordValidateModal] = useState(false);
    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('user.table.header.new'));
    };

    function setValues(dataForm: any) {
        delete dataForm.password;
        if (Array.isArray(dataForm.roles)) {
            dataForm.roles = dataForm.roles.map((role: { [index: string]: any }) => {
                return { key: role.id, label: role.authority };
            });
        }
        if(dataForm.tenant) {
            dataForm.tenant= {key: dataForm.tenant.id, label: dataForm.tenant.name}
        }


        delete dataForm.usersGroup;
        setEditHeader(dataForm.firstName + ' ' + dataForm.lastName);
        form.setFieldsValue(dataForm);
        setUserItem(dataForm);
    }

    function normalizeValues(values: any) {
        let normalized = { ...values };
        normalized.active = 'active' in values ? values['active'] : true;
        normalized.roles = Array.isArray(form.getFieldValue('roles'))
            ? form.getFieldValue('roles').map((selectedRole: any) => {
                    console.log(selectedRole);
                  let ret: { [index: string]: any } = {};
                  if (selectedRole.hasOwnProperty('value')) {
                      ret.id = parseInt(selectedRole.value, 10);
                  } else if (selectedRole.hasOwnProperty('id')) {
                      ret.id = parseInt(selectedRole.id, 10);
                  } else if (selectedRole.hasOwnProperty('key')) {
                      ret.id = parseInt(selectedRole.key, 10);
                  }
                  return ret;
              })
            : null;

        if (!form.getFieldValue('password') || form.getFieldValue('password').length == 0) {
            delete normalized.password;
        }

        if (normalized.roles == null) {
            delete normalized.roles;
        }

        return normalized;
    }

    function compareToFirstPassword(rule: any, value: any, callback: any) {
        if (value && value !== form.getFieldValue('password')) {
            callback(geti18nText('user.validation.password.equal'));
        } else {
            callback();
        }
    }

    function customButtons() {
        return isCreate === true
            ? []
            : [
                  {
                      text: geti18nText('user.password.btn.reset'),
                      onClick: async () => {
                          form.setFieldsValue({ password: '', confirm: '' });
                          setPasswordValidateModal(true);
                      },
                  },
              ];
    }

    function resetPassword(password: any, confirm: any) {
        console.log(form.getFieldsValue());
        const userId = form.getFieldValue('id');
        NyRequestResolver.requestPut(CONSTANTS_REQ.USER.RESET + '/' + userId, undefined, {
            password: password,
            confirm: confirm,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                setPasswordValidateModal(false);
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
                setPasswordValidateModal(false);
            }
        });
    }

    const validateUsername = (rule: any, value: any, callback: any) => {
        if (userItem != null && userItem.username == value) {
            callback();
            return;
        }
        if (value.length > 2) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.USER.USERNAME_EXIST + '/' + value).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data != null) {
                        const responseData: boolean = result.data;
                        if (responseData == false) {
                            callback();
                            return;
                        }
                    }
                }
                callback(geti18nText('user.validation.username.exist'));
            });
        } else {
            callback();
        }
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.USER.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalizeValues}
            customButtons={customButtons()}
            {...props}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('user.edit.firstName')} name="firstName">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('user.edit.lastName')} name="lastName">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('user.edit.username')}
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                validator: validateUsername,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('user.edit.email')} name="email">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            {isCreate && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('user.edit.password')}
                            name="password"
                            rules={[
                                {
                                    required: isCreate ? true : false,
                                    message: geti18nText('app.default.required'),
                                },
                                {
                                    min: 6,
                                    message: geti18nText('user.password.btn.reset.length.validate'),
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('user.edit.password')}
                            name="confirm"
                            rules={[
                                {
                                    required: isCreate ? true : false,
                                    message: geti18nText('app.default.required'),
                                },
                                {
                                    validator: compareToFirstPassword,
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Divider orientation="left">{geti18nText('user.edit.divider.safety')}</Divider>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('user.edit.roles')} name="roles">
                        <NySearchField
                            url={CONSTANTS_REQ.ROLE.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            mode="multiple"
                            
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {NySession.hasAnyRole(['ROLE_ADMIN']) && 
                    <Form.Item label={geti18nText('user.edit.tenant')} name="tenant">
                    <NySearchField
                        url={CONSTANTS_REQ.TENANT.SEARCH}
                        map={{ id: 'id', label: 'text' }}
                        searchBy="text"
                       
                        
                    />
                </Form.Item>}
                </Col>
            </Row>

            <PasswordValidator
                form={form}
                visible={passwordValidateModal}
                closeModal={() => setPasswordValidateModal(false)}
                save={resetPassword}
            ></PasswordValidator>
        </NyDataEdit>
    );
};

interface iPasswordValidator {
    form: any;
    visible?: boolean;
    closeModal?: () => void;
    save?: any;
}

const PasswordValidator = ({ form, visible, closeModal, save }: iPasswordValidator) => {
    function passwordValidator(rule: any, value: any, callback: any) {
        if (value && value !== form.getFieldValue('password')) {
            callback(geti18nText('user.validation.password.equal'));
        } else {
            callback();
        }
    }

    return (
        <React.Fragment>
            <Modal
                visible={visible}
                title={geti18nText('user.password.btn.reset')}
                width={600}
                onCancel={closeModal}
                getContainer={false}
                destroyOnClose={true}
                onOk={() => {
                    form.validateFields().then((values: any) => {
                        save(form.getFieldValue('password'), form.getFieldValue('confirm'));
                    });
                }}
            >
                <Form form={form} layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('user.edit.password')}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        min: 6,
                                        message: geti18nText('user.password.btn.reset.length.validate'),
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('user.edit.password')}
                                name="confirm"
                                rules={[
                                    {
                                        required: true ? true : false,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        validator: passwordValidator,
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
export default UserEdit;
