import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyRequestResolver,
    RESPONSE,
    NySpinner,
    NyDataTable,
    getColumnSearch,
    ENUMS,
    getColumnSearchOption,
    NyUtils,
} from '@nybble/nyreact';
import { Button, Col, Descriptions, message, Modal, notification, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';

interface IDistributionNodeArea {
    dcName: string;
    visible: boolean;
    closeModal: () => void;
}

const DistributionNodeAreaIndex = ({ dcName, visible, closeModal }: IDistributionNodeArea) => {
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const { TabPane } = Tabs;
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 },{ field: 'regular', condition: 'equals_bool', value: 1 }];
    }
    
    function setDefaultFilterValueCompetitive() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 },{ field: 'regular', condition: 'equals_bool', value: 0 }];
    }

    function onRefreshClick(type:any) {
        setLoading(true);
        const params = { naz_dc: dcName };
        NyRequestResolver.requestPost(CONSTANTS_REQ.DISTRIBUTION_NODE_AREA.DATA, undefined, {
            params: params,
            requestType: type,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    }

    const columns=[
        {
            title: geti18nText('distribution.node.table.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('distribution.node.area.details.naz_dc'),
            dataIndex: 'nazDc',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.naz_nas'),
            dataIndex: 'nazNas',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.naz_ul'),
            dataIndex: 'nazUl',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.adr'),
            dataIndex: 'adr',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.adr.dguid'),
            dataIndex: 'adrDguid',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.adr.dost'),
            dataIndex: 'adrDost',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
         {
            title: geti18nText('distribution.node.area.details.status.izgr'),
            dataIndex: 'statusIzgr',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.vert'),
            dataIndex: 'vertikala',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('distribution.node.area.details.naj_izgr'),
            dataIndex: 'najIzgr',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.najIzgr) {
                    return (
                        <div>
                            {new Date(record.najIzgr).toLocaleDateString(
                                NyUtils.getSelectedLocale()
                            )}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('distribution.node.area.details.get_date'),
            dataIndex: 'getDate',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.getDate) {
                    return (
                        <div>
                            {new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}
                        </div>
                    );
                }
            },
        },

        {
            title: geti18nText('users.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
        },
    ]

    return (
        <Modal
            visible={visible}
            title={geti18nText('distribution.node.area.details.title')}
            width={1200}
            onCancel={closeModal}
            getContainer={false}
            destroyOnClose={true}
            footer={null}
        >
            {loading ? (
                <NySpinner />
            ) : (
                <> <Tabs type="card" >
                    <TabPane tab={geti18nText('distribution.node.area.tab.regular')} key="1">

                
                        <NyDataTable
                            key={tableKey}
                            headerTitle={geti18nText('distribution.node.table.header.regular')}
                            url={CONSTANTS_REQ.DISTRIBUTION_NODE_AREA.LIST}
                            showRecordModal={true}
                            hideNewButton={true}
                            
                            addedData={{
                                dcName: dcName,
                            }}
                            exportCSV={true}
                            colCSVCustomization={[
                                {
                                    active: (value: boolean) => {
                                        if (value === true) {
                                            return geti18nText('app.default.active');
                                        } else {
                                            return geti18nText('app.default.inactive');
                                        }
                                    },
                                },
                                {
                                    getDate: (value: string) => {
                                        return value != null
                                            ? new Date(value).toLocaleString(NyUtils.getSelectedLocale())
                                            : '';
                                    },
                                },
                                {
                                    najIzgr: (value: string) => {
                                        return value != null
                                            ? new Date(value).toLocaleDateString(NyUtils.getSelectedLocale())
                                            : '';
                                    },
                                },
                            ]}
                            setDefaultPageSize={20}
                            setDefaultFilterValue={setDefaultFilterValue}
                            columns={columns}
                        />
                        <Row justify="end">
                        <Col style={{ marginTop: '20px' }}>
                            <Button loading={loading} type="primary" onClick={()=>onRefreshClick(69)}>
                                {geti18nText('distribution.node.area.details.refresh.btn')}
                            </Button>
                        </Col>
                    </Row>
                    </TabPane>
                    <TabPane tab={geti18nText('distribution.node.area.tab.not.regular')} key="2">

                
                        <NyDataTable
                            key={tableKey}
                            headerTitle={geti18nText('distribution.node.table.header.not.regular')}
                            url={CONSTANTS_REQ.DISTRIBUTION_NODE_AREA.LIST}
                            showRecordModal={true}
                            hideNewButton={true}
                            
                            addedData={{
                                dcName: dcName,
                            }}
                            exportCSV={true}
                            colCSVCustomization={[
                                {
                                    active: (value: boolean) => {
                                        if (value === true) {
                                            return geti18nText('app.default.active');
                                        } else {
                                            return geti18nText('app.default.inactive');
                                        }
                                    },
                                },
                                {
                                    getDate: (value: string) => {
                                        return value != null
                                            ? new Date(value).toLocaleString(NyUtils.getSelectedLocale())
                                            : '';
                                    },
                                },
                                {
                                    najIzgr: (value: string) => {
                                        return value != null
                                            ? new Date(value).toLocaleDateString(NyUtils.getSelectedLocale())
                                            : '';
                                    },
                                },
                            ]}
                            setDefaultPageSize={20}
                            setDefaultFilterValue={setDefaultFilterValueCompetitive}
                            columns={columns}
                        />
                        <Row justify="end">
                        <Col style={{ marginTop: '20px' }}>
                            <Button loading={loading} type="primary" onClick={()=>onRefreshClick(73)}>
                                {geti18nText('distribution.node.area.details.refresh.btn')}
                            </Button>
                        </Col>
                        </Row>
                    </TabPane>
                    
                    </Tabs>
                </>
            )}
        </Modal>
    );
};

export default DistributionNodeAreaIndex;
