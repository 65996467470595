import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Modal, Form, Row, Col, Input, Divider, notification, Descriptions } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';

const CheckAvailabilityEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('menu.main.check.availability'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [data, setData] = useState<any>(undefined);
   
    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('menu.main.check.availability'));
    };

    function setValues(dataForm: any) {
        console.log(dataForm)
        setEditHeader(geti18nText('menu.main.check.availability') + ' '+ dataForm.getDate);
        setData(dataForm)
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CHECK_AVAILABILITY.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            hideButtons
            {...props}
        >
            {data &&<><Descriptions contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label={geti18nText('check.availability.table.telbr')}>
                            {data.telbr}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.oib')}>
                            {data.oib}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.naz.nas')}>
                            {data.naz_nas}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.naz.ul')}>
                            {data.naz_ul}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.kbr')}>
                            {data.kbr}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.slovo')}>
                            {data.slovo}
                        </Descriptions.Item>
                        </Descriptions>
                        <Divider/>
                        <Descriptions>
                        <Descriptions.Item label={geti18nText('check.availability.table.status')}>
                            {geti18nText('check.availability.adsl.status.'+data.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.vdsl.status')}>
                        {geti18nText('check.availability.vdsl.status.'+data.vdslstatus)}
                          
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.gf.status')}>
                        {geti18nText('check.availability.gfast.status.'+data.gfstatus)}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.adslbrz')}>
                            {data.adslbrz}
                        </Descriptions.Item>
                        
                        <Descriptions.Item label={geti18nText('check.availability.table.vdslbrz')}>
                            {data.vdslbrz}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.ftthstatus')}>
                            {data.ftthstatus}
                        </Descriptions.Item>
                        
                        <Descriptions.Item label={geti18nText('check.availability.table.ftthdost')}>
                            {data.ftthdost}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.duljina')}>
                            {data.duljina}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.fttbbrz')}>
                            {data.fttbbrz}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.fttbbrzgf')}>
                            {data.fttbbrzgf}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.vektbrz')}>
                            {data.vektbrz}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.svektbrz')}>
                            {data.svektbrz}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.napomena')}>
                            {data.napomena}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.napajanje')}>
                            {data.napajanje}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.ftthplz')}>
                            {data.ftthplz}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.op')}>
                            {data.op}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.usl')}>
                            {data.usl}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.stat')}>
                            {data.stat}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.tehpri')}>
                            {data.tehpri}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('check.availability.table.konpod')}>
                            {data.konpod}
                        </Descriptions.Item>
            </Descriptions></>}
            
        </NyDataEdit>
    );
};
export default CheckAvailabilityEdit;
