import { geti18nText, NyRequestResolver, RESPONSE, NyUtils, NySpinner, getColumnSearch, NyDataTable } from '@nybble/nyreact';
import { Divider, Typography, Descriptions, Modal, message, Tooltip, Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ, WsMethods } from '../../utils/Constants';

interface IWsRequestDetails {
    id: number;
    visible: boolean;
    closeModal: () => void;
}

const WsRequestDetails = ({ id, visible, closeModal }: IWsRequestDetails) => {
    const [loading, setLoading] = useState(false);
    const [loadingParameter, setLoadingParameter] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [showParameter, setShowParameter] = useState(false);
    const [wsRequestDetail, setWsRequestDetail] = useState<{ [index: string]: any }>({});
    const { Title, Link } = Typography;

    useEffect(() => {
        if (id > 0) {
            fetch();
        }
    }, [id]);


    function fetch() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.DETAILS_TABLE + '/' + id).then((result) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setFetchError(false);
                if (typeof result.data !== 'string') {
                    console.log(result.data);
                    setWsRequestDetail(result.data);
                }
            } else {
                setFetchError(true);
                message.error(geti18nText('app.default.error'));
            }
        });
    }

    const fileDownload = (attachmentId: any, fileName: String) => {
        setLoadingFile(true);

        NyRequestResolver.requestGet(
            CONSTANTS_REQ.WS_REQUEST.DOWNLOAD_FILE,
            { attachmentId: attachmentId },
            false,
            true
        ).then((result: { [index: string]: any }) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                NyUtils.downloadFile(result);
            } else {
                message.error(geti18nText('app.default.error'));
            }
            setLoadingFile(false);
        });
    };

    const parameterLabel = (label: String, code: String) => {
        return <Tooltip title={label}>{code === 'faaid' ? 'id' : code}</Tooltip>;
    };

    return (
        <Modal
            visible={visible}
            title={geti18nText('ws.request.details.header')}
            width={1500}
            onCancel={closeModal}
            getContainer={false}
            destroyOnClose={true}
            footer={null}
        >
            {loading && <NySpinner />}
            {!loading && !fetchError && (
                <React.Fragment>
                    {wsRequestDetail.requestFromHt && (
                        <Title level={5} mark>
                            {geti18nText('ws.request.edit.fromHT')}
                        </Title>
                    )}
                    {!wsRequestDetail.requestFromHt && <Title level={5}>{geti18nText('ws.request.edit.toHT')}</Title>}

                    <Descriptions contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label={geti18nText('ws.request.edit.id')}>
                            {wsRequestDetail.id}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.guuid')}>
                            {wsRequestDetail.guuid}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.method')}>
                            {wsRequestDetail.requestMethod}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.type')}>
                            {wsRequestDetail.requestType}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.time.generated')}>
                            {wsRequestDetail.timeGenerated}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.time.response')}>
                            {wsRequestDetail.timeResponse}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions
                        title={geti18nText('ws.request.details.operator')}
                        contentStyle={{ fontWeight: 'bold' }}
                    >
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.operator.id')}>
                            {wsRequestDetail.operatorId}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.operator.name')}>
                            {wsRequestDetail.operatorName}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions
                        title={geti18nText('ws.request.details.response')}
                        contentStyle={{ fontWeight: 'bold' }}
                    >
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.response.code')}>
                            {wsRequestDetail.responseCode}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.response.id')}>
                            {wsRequestDetail.responseId}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.response.message')}>
                            {wsRequestDetail.responseMessage}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.request.response.trace')}>
                            {wsRequestDetail.responseTrace}
                        </Descriptions.Item>
                        <Descriptions.Item label={geti18nText('ws.request.edit.user')}>
                            {wsRequestDetail.username}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    {wsRequestDetail.statusType && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.status')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                <Descriptions.Item label={geti18nText('ws.request.edit.status.type')}>
                                    {wsRequestDetail.statusType}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('ws.request.edit.status.description')}>
                                    {wsRequestDetail.statusDescription}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('ws.request.edit.status.date')}>
                                    {wsRequestDetail.statusDate}
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.note && (
                        <React.Fragment>
                            <Descriptions title={geti18nText('ws.request.details.note')}>
                                <Descriptions.Item>{wsRequestDetail.note}</Descriptions.Item>
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.createOrderParam && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.parameters')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.createOrderParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={parameterLabel(
                                                    geti18nText('params.' + wsRequestDetail.requestType + '.' + obj),
                                                    obj
                                                )}
                                            >
                                                {wsRequestDetail.createOrderParam[obj] instanceof Array
                                                    ? wsRequestDetail.createOrderParam[obj][2] +
                                                      '.' +
                                                      wsRequestDetail.createOrderParam[obj][1] +
                                                      '.' +
                                                      wsRequestDetail.createOrderParam[obj][0]
                                                    : wsRequestDetail.createOrderParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.reportFaultParam && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.parameters')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.reportFaultParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={parameterLabel(
                                                    geti18nText('params.' + wsRequestDetail.requestType + '.' + obj),
                                                    obj
                                                )}
                                            >
                                                {wsRequestDetail.reportFaultParam[obj] instanceof Array
                                                    ? wsRequestDetail.reportFaultParam[obj][2] +
                                                      '.' +
                                                      wsRequestDetail.reportFaultParam[obj][1] +
                                                      '.' +
                                                      wsRequestDetail.reportFaultParam[obj][0]
                                                    : wsRequestDetail.reportFaultParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.sendOrderStatusParam && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.parameters')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.sendOrderStatusParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={parameterLabel(
                                                    geti18nText('params.' + wsRequestDetail.requestMethod + '.' + obj),
                                                    obj
                                                )}
                                            >
                                                {wsRequestDetail.sendOrderStatusParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.updateOrderParam && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.parameters')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.updateOrderParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={parameterLabel(
                                                    geti18nText('params.' + wsRequestDetail.requestMethod + '.' + obj),
                                                    obj
                                                )}
                                            >
                                                {wsRequestDetail.updateOrderParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.sendFaultStatusParam && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.parameters')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.sendFaultStatusParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={parameterLabel(
                                                    geti18nText('params.' + wsRequestDetail.requestMethod + '.' + obj),
                                                    obj
                                                )}
                                            >
                                                {wsRequestDetail.sendFaultStatusParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.sendNotificationParam && (
                        <React.Fragment>
                            <Descriptions
                                title={geti18nText('ws.request.details.parameters')}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.sendNotificationParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={parameterLabel(
                                                    geti18nText('params.' + wsRequestDetail.requestMethod + '.' + obj),
                                                    obj
                                                )}
                                            >
                                                {wsRequestDetail.sendNotificationParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                            <Divider />
                        </React.Fragment>
                    )}
                    {wsRequestDetail.wsRequestAttachment && (
                        <Descriptions title={geti18nText('ws.request.details.attachments')}>
                            {wsRequestDetail.wsRequestAttachment.map((obj: any) => {
                                return (
                                    <Descriptions.Item>
                                        {loadingFile && <NySpinner />}
                                        <Link onClick={() => fileDownload(obj.id, obj.fileName)}>{obj.fileName}</Link>
                                        &nbsp;&nbsp;{NyUtils.formatBytes(obj.fileSize)}
                                    </Descriptions.Item>
                                );
                            })}
                        </Descriptions>
                    )}
                    {wsRequestDetail.requestMethod === WsMethods.GET_DATA&& 
                    <Button onClick={()=>setShowParameter(!showParameter)} disabled={loadingParameter} loading={loadingParameter} type='primary'>{!showParameter? geti18nText('ws.request.get.data.show.parameter.btn'):geti18nText('ws.request.get.data.hide.parameter.btn')}</Button>
                    }
                    {showParameter === true && <NyDataTable
                            headerTitle={geti18nText('ws.request.get.data.parameters.header.table')}
                            url={CONSTANTS_REQ.GET_DATA_PARAMETERS.LIST}
                            hideButtons={true}
                            addedData={{wsRequestId: id}}
                            setDefaultSortOrder={{order:'id',orderType:'asc'}}
                            setDefaultPageSize={20}
                            readonly
                            columns={[
                                {
                                    title: geti18nText('ws.request.get.data.parameters.table.name'),
                                    dataIndex: 'paramName',
                                    width: '5%',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('number'),
                                },
                                {
                                    title: geti18nText('ws.request.get.data.parameters.table.value'),
                                    dataIndex: 'paramValue',
                                    width: '5%',
                                    
                                    
                                },
                                {
                                    title: geti18nText('ws.request.get.data.parameters.table.responseRow'),
                                    dataIndex: 'responseRow',
                                    width: '5%',
                                    render: (text: string, record: { [index: string]: any }) => {
                                        return (
                                            <div style={{display:'flex'}}>
                                                <div >
                                                {record.responseRow}
                                                </div>
                                                <div style={{marginLeft:20}}>
                                                {record.paramType==='IN'?<ArrowRightOutlined style={{ fontSize: '22px', color:'blue' }} />:<ArrowLeftOutlined style={{ fontSize: '22px', color:'gray' }} color='red' />}
                                                </div>
                                            </div>
                                        );
                                    },
                                    
                                },
                                
                            ]}
                        />}
                </React.Fragment>
            )}
        </Modal>
    );
};

export default WsRequestDetails;
