import { geti18nText, NyLogin, NySession } from '@nybble/nyreact';
import { Spin } from 'antd';
import { Session } from 'inspector';
import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './assets/scss/index.scss';
import LayoutMain from './components/layout-main';
import { URL_PREFIX } from './utils/Constants';

export const AppContext = React.createContext<any | undefined>(undefined);

const App = () => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        initApp();
    }, []);

    const initApp = async () => {
        setLoading(true);
        await NySession.init();
        setLoading(false);
        setTitle(NySession.getSetting('ux').title);
        document.title = NySession.getSetting('ux').title;
    };

    if (loading) {
        return (
            <div className="spin-container">
                <Spin size="large" />
            </div>
        );
    } else {
        return (
            <HashRouter>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        component={() => <NyLogin url={URL_PREFIX + 'auth/login'} title={title} />}
                    />
                    <Route path="/" component={LayoutMain} />
                </Switch>
            </HashRouter>
        );
    }
};

export default App;
