import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText
} from '@nybble/nyreact';
import { Button, Col, Input, Row, notification } from 'antd';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import CheckAvailabilityEdit from './edit';


const CheckAvailability = () => {
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const [selectedServiceID, setSelectedServiceID] = useState<any>(undefined);
    const [selectedOib, setSelectedOib] = useState<any>(undefined);
    const [selectedSettlment, setSelectedSettlment] = useState<any>(undefined);
    const [selectedStreet, setSelectedStreet] = useState<any>(undefined);
    const [selectedNumber, setSelectedNumber] = useState<any>(undefined);
    const [selectedLetter, setSelectedLetter] = useState<any>(undefined);

    function setDefaultFilterValue() {
        
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }
    function onRefreshClick(e: any) {
        setLoading(true);
        const params = { telbr: selectedServiceID, oib:selectedOib, naz_nas:selectedSettlment, naz_ul:selectedStreet,kbr:selectedNumber, slovo:selectedLetter  };
        NyRequestResolver.requestPost(CONSTANTS_REQ.CHECK_AVAILABILITY.DATA, undefined, {
            params: params,
            requestType: 32,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setSelectedServiceID(undefined);
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    }

    const onChangeServiceIdText=(value:any)=>{
        setSelectedServiceID(value.target.value===''?undefined:value.target.value)
    }
    
    const onChangeOib=(value:any)=>{
        setSelectedOib(value.target.value===''?undefined:value.target.value)
    }
    
    const onChangeSettlment=(value:any)=>{
        setSelectedSettlment(value.target.value===''?undefined:value.target.value)
    }
    const onChangeStreet=(value:any)=>{
        setSelectedStreet(value.target.value===''?undefined:value.target.value)
    }
    
    const onChangeNumber=(value:any)=>{
        setSelectedNumber(value.target.value===''?undefined:value.target.value)
    }
    
    const onChangeLetter=(value:any)=>{
        setSelectedLetter(value.target.value===''?undefined:value.target.value)
    }

    return (
        <>
            <Row gutter={24} style={{alignItems: 'end'}}>
                <Col span={6}>
                    <div>{geti18nText('check.availability.table.telbr')}</div>
                    <Input onChange={onChangeServiceIdText} value={selectedServiceID}></Input>
                </Col>
                <Col span={6}>
                    <div>{geti18nText('check.availability.table.oib')}</div>
                    <Input max={11} onChange={onChangeOib} value={selectedOib}></Input>
                </Col>
                <Col>
                    <Button loading={loading} type="primary" disabled={selectedServiceID == undefined  && selectedOib===undefined && selectedSettlment===undefined && selectedStreet===undefined && selectedNumber===undefined&& selectedLetter===undefined} onClick={onRefreshClick}>
                        {geti18nText('get.data.fetch.data')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={24} style={{marginTop:10}}>
                <Col span={6}>
                    <div>{geti18nText('check.availability.table.naz.nas')}</div>
                    <Input onChange={onChangeSettlment} value={selectedSettlment}></Input>
                </Col>
                <Col span={6}>
                    <div>{geti18nText('check.availability.table.naz.ul')}</div>
                    <Input onChange={onChangeStreet} value={selectedStreet}></Input>
                </Col>
                
            </Row>
            <Row gutter={24} style={{marginTop:10}}>
                <Col span={6}>
                    <div>{geti18nText('check.availability.table.kbr')}</div>
                    <Input onChange={onChangeNumber} value={selectedNumber}></Input>
                </Col>
                <Col span={6}>
                    <div>{geti18nText('check.availability.table.slovo')}</div>
                    <Input onChange={onChangeLetter} value={selectedLetter}></Input>
                </Col>
                
            </Row>

            <NyDataTable
                key={tableKey}
                headerTitle={geti18nText('check.availability.table.header')}
                url={CONSTANTS_REQ.CHECK_AVAILABILITY.LIST}
                scroll={{x:900}}
                hideNewButton={true}
                modalComponent={CheckAvailabilityEdit}
                setDefaultPageSize={20}
                showRecordModal
                setDefaultFilterValue={setDefaultFilterValue}
                exportCSV={false}
                colCSVCustomization={[
                    {
                        county: (value: any) => {
                            if (value) {
                                return value.name;
                            }
                            return '';
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('app.default.active');
                            } else {
                                return geti18nText('app.default.inactive');
                            }
                        },
                    },
                    {
                        getDate: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale()) : '';
                        },
                    },
                ]}
                columns={[
                    {
                        title: geti18nText('check.availability.table.telbr'),
                        dataIndex: 'telbr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('check.availability.table.oib'),
                        dataIndex: 'oib',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('check.availability.table.naz.nas'),
                        dataIndex: 'nazNas',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('check.availability.table.naz.ul'),
                        dataIndex: 'nazUl',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('check.availability.table.kbr'),
                        dataIndex: 'kbr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('check.availability.table.slovo'),
                        dataIndex: 'slovo',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('check.availability.table.status'),
                        dataIndex: 'status',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText('check.availability.adsl.status.'+record.status)}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('check.availability.table.vdsl.status'),
                        dataIndex: 'vdslstatus',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText('check.availability.vdsl.status.'+record.vdslstatus)}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('check.availability.table.gf.status'),
                        dataIndex: 'gfstatus',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText('check.availability.gfast.status.'+record.gfstatus)}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('check.availability.table.adslbrz'),
                        dataIndex: 'adslbrz',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('check.availability.table.vdslbrz'),
                        dataIndex: 'vdslbrz',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('check.availability.table.fttbbrzgf'),
                        dataIndex: 'fttbbrz',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('distribution.node.table.get_date'),
                        dataIndex: 'getDate',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.getDate) {
                                return (
                                    <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                );
                            }
                        },
                    },
                ]}
            />
        </>
    );
};

export default CheckAvailability;
