import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
    getColumnDateOption,
} from '@nybble/nyreact';
import { Tag, Typography } from 'antd';
import React, { useState } from 'react';
import WsRequestDetails from './edit';
import { CONSTANTS_REQ, SettingsKey } from '../../utils/Constants';

const WsRequestIndex = () => {
    const [adedData, setAdedData] = useState<any>(undefined);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [wsRequestId, setWsRequestId] = useState(0);

    const { Title } = Typography;

    function closeDetailModal() {
        setDetailsVisible(false);
    }

    function openDetailModal(record: { [index: string]: any }) {
        setWsRequestId(record.id);
        setDetailsVisible(true);
    }

    const requestTypes = () => {
        const aTypes = (NySession.getAppValue(SettingsKey.REQ_TYPES) as []).filter((type: any) => {
            return type.active === true;
        });
        let types: any = [];
        aTypes.forEach((type: any) => {
            types.push({ id: type.typeId, text: type.name });
        });
        return types;
    };
    const requestMethod = () => {
        const aTypes = NySession.getAppValue(SettingsKey.WS_METHOD);
        let types: any = [];
        Object.keys(aTypes).forEach((type: any) => {
            types.push({ id: aTypes[type], text: aTypes[type] });
        });
        return types;
    };

    const statuses = () => {
        const aTypes = NySession.getAppValue(SettingsKey.STATUS_CODE);
        let types: any = [];
        Object.keys(aTypes).forEach((type: any) => {
            types.push({ id: type, text: geti18nText(aTypes[type]) });
        });
        return types;
    };

    return (
        <>
            <Title level={4}>{geti18nText('review.request.header')}</Title>
            <NyDataTable
                headerTitle={geti18nText('ws.request.table.header')}
                url={CONSTANTS_REQ.WS_REQUEST.LIST_LOG}
                showRecordModal={true}
                hideButtons={true}
                addedData={adedData}
                onRowSelect={openDetailModal}
                setDefaultPageSize={20}
                columns={[
                    {
                        title: geti18nText('review.request.response.table.column.id'),
                        dataIndex: 'id',
                        width: '5%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('review.request.response.table.column.type'),
                        dataIndex: 'requestType',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(requestTypes(), 'equals_string'),
                    },
                    {
                        title: geti18nText('ws.request.table.column.type.method'),
                        dataIndex: 'requestMethod',

                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearchOption(requestMethod()),
                    },
                    {
                        title: geti18nText('review.request.response.table.column.time.generated'),
                        dataIndex: 'timeGenerated',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.timeGenerated) {
                                return (
                                    <div>
                                        {new Date(record.timeGenerated).toLocaleString(NyUtils.getSelectedLocale())}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true),
                    },
                    {
                        title: geti18nText('review.request.response.table.column.user'),
                        dataIndex: 'username',
                        align: 'center',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('ws.request.table.column.request.from.hr'),
                        dataIndex: 'requestFromHt',
                        width: '10%',
                        align: 'center',
                        render: (text: any, record: { requestFromHt: any }) => {
                            if (record.requestFromHt) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                    },
                    {
                        title: geti18nText('review.request.response.table.column.status.recieved'),
                        dataIndex: 'responseCode',
                        align: 'center',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.responseCode === 0 ? (
                                        <Tag color="success">
                                            {geti18nText(
                                                NySession.getAppValue(SettingsKey.STATUS_CODE)[record.responseCode]
                                            )}
                                        </Tag>
                                    ) : (
                                        <Tag color="red">
                                            {geti18nText(
                                                NySession.getAppValue(SettingsKey.STATUS_CODE)[record.responseCode]
                                            )}
                                        </Tag>
                                    )}
                                </div>
                            );
                        },
                        ...getColumnSearchOption(statuses()),
                    },
                ]}
            />
            <WsRequestDetails visible={detailsVisible} id={wsRequestId} closeModal={closeDetailModal} />
        </>
    );
};

export default WsRequestIndex;
