import React from 'react';
import SendRequestIndex from "../send-request";
import { WsMethods } from '../../utils/Constants';

const GetDataIndex = () => {
    return (
        <SendRequestIndex wsMethod={WsMethods.GET_DATA} />
    );
};

export default GetDataIndex;
