import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    FolderAddOutlined,
    InboxOutlined,
    PlusCircleOutlined,
    PoweroffOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, DatePicker, Form, Popover, Row, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import { CONSTANTS_REQ, SettingsKey, WsMethods } from '../../utils/Constants';
import SendRequestIndex from '../send-request';
import { HtStatus } from './edit';

const ReviewRequestResponse = (props: any) => {
    const [stateAddedData, setStateAddedData] = useState<any>(undefined);
    const [typeColumns, setTypeColumns] = useState<any>(null);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));

    const [detailsVisible, setDetailsVisible] = useState(false);

    let history = useHistory();
    let location = useLocation();
    const { state, setState } = useContext<any>(AppContext);
    const { Title } = Typography;

    useEffect(() => {
        setTableKey(Math.random().toString(36));
        if (props.match.params.type) {
            setStateAddedData({ type: props.match.params.type.toUpperCase() });
        }
        if (props.match.params.searchPattern) {
            setStateAddedData({ searchPattern: props.match.params.searchPattern });
        }
    }, [location]);

    const setDefaultFilterValue = () => {
        return [{ field: 'responseCode', condition: 'equals', value: '0' }];
    };
    const setDefaultSortOrder = () => {
        return { order: 'unread desc,latestActivity', orderType: 'desc' };
    };

    const sortDefault = () => {
        if (state && history.action !== 'PUSH' && state.filter && state.filterInit === true && state.orderMap) {
            let orderMap: Map<string, string> = state.orderMap;
            let key: string = orderMap.keys().next().value;
            if (key && orderMap.get(key)) {
                return { order: key, orderType: orderMap.get(key) || '' };
            } else return setDefaultSortOrder();
        } else {
            return setDefaultSortOrder();
        }
    };

    const filterDefault = () => {
        if (state && history.action !== 'PUSH' && state.filter && state.filterInit === true && state.search) {
            let filter: any = state.search;
            return filter;
        } else {
            return setDefaultFilterValue();
        }
    };

    const addeddData = () => {
        let param: any = {};
        if (state && history.action !== 'PUSH' && state.filter && state.type && state.filterInit === true) {
            param.type = state.type;
        }
        if (state && history.action !== 'PUSH' && state.filter && state.timestampFrom && state.filterInit === true) {
            param.timestampFrom = state.timestampFrom;
        }

        if (state && history.action !== 'PUSH' && state.filter && state.timestampTo && state.filterInit === true) {
            param.timestampTo = state.timestampTo;
        }

        if (Object.keys(param).length > 0) {
            param.type = props.match.params.type.toUpperCase();
            param.searchPattern = props.match.params.searchPattern;
            return param;
        } else return null;
    };
    function closeDetailModal() {
        setTableKey(Math.random().toString(36));
        setDetailsVisible(false);
    }

    useEffect(() => {
        if (history.action === 'PUSH') {
            if (state) {
                let tmpState = state;
                tmpState.filter = false;
                setState(tmpState);
            }
        }
    }, []);

    const columns = () => {
        if (props.match.params.type) {
            let tmpColumns: any = [];
            let fixColumns: any;
            fixColumns = fixedColumns.filter((col: any) => {
                return col.dataIndex !== 'requestType';
            });
            (NySession.getAppValue(SettingsKey.REQ_PARAM_TYPE) as [])
                .filter((param: any) => {
                    return (
                        param.typeId === props.match.params.type.toUpperCase() &&
                        param.wsMethod === WsMethods.CREATE_ORDER &&
                        param.forTable === true
                    );
                })
                .forEach((el: any) => {
                    tmpColumns.push({
                        title: geti18nText('params.' + props.match.params.type.toUpperCase() + '.' + el.name),
                        dataIndex: el.name,
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    });
                });
            fixColumns.splice(
                fixColumns
                    .map((tmp: any) => {
                        return tmp.dataIndex;
                    })
                    .indexOf('timeGenerated') + 1,
                0,
                ...tmpColumns
            );

            if (state) {
                state.columns = tmpColumns;
            }

            return fixColumns;
        } else if (props.match.params.searchPattern) {
            let columnsTmp=fixedColumns;
            columnsTmp.splice(1,0,
                {
                    title: geti18nText('params.FAA.nazpr'),
                    dataIndex: 'nazpr',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                }
            )
            return columnsTmp;
        } else if (state && state.columns) {
            let columnsTmp: any = fixedColumns
                .filter((col: any) => {
                    return col.dataIndex !== 'requestType';
                })
                .slice();

            columnsTmp.splice(
                columnsTmp
                    .map((tmp: any) => {
                        return tmp.dataIndex;
                    })
                    .indexOf('timeGenerated') + 1,
                0,
                ...regenerateTypeColumns(state.columns)
            );
            return columnsTmp;
        } else return fixedColumns;
    };

    function regenerateTypeColumns(columns: any) {
        let tmpCol: any = [];
        (columns as []).forEach((el: any) => {
            tmpCol.push({
                title: el.title,
                dataIndex: el.dataIndex,
                width: el.width,
                sorter: (a: any, b: any) => {},
                ...getColumnSearch(
                    'string',
                    state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get(el.dataIndex)
                        ? state.initialMap.get(el.dataIndex)
                        : undefined,
                    state && state.orderMap && state.orderMap.get(el.dataIndex)
                        ? state.orderMap.get(el.dataIndex) === 'asc'
                            ? 'ascend'
                            : 'descend'
                        : undefined
                ),
            });
        });
        return tmpCol;
    }

    const requestTypes = () => {
        const aTypes = (NySession.getAppValue(SettingsKey.REQ_TYPES) as []).filter((type: any) => {
            return type.active === true;
        });
        let types: any = [];
        aTypes.forEach((type: any) => {
            types.push({ id: type.typeId, text: type.name });
        });
        return types;
    };

    const statuses = () => {
        const aTypes = NySession.getAppValue(SettingsKey.STATUS_CODE);
        let types: any = [];
        Object.keys(aTypes).forEach((type: any) => {
            types.push({ id: type, text: geti18nText(aTypes[type]) });
        });
        return types;
    };

    function saveUserActivity(id: any, guuid: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.LOG_REQUEST.SAVE, undefined, {
            wsRequest: { id: id, guuid: guuid },
            user: null,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
            }
        });
    }

    function onRowClikcHandler(value: any) {
        if (state) {
            let tmpState = state;

            if (typeColumns) {
                tmpState.columns = typeColumns;
            }

            if (state.sortClear === true) {
                let tmpMap: Map<string, string> = new Map();
                let sort: any = setDefaultSortOrder();
                tmpMap.set(sort.order, sort.orderType);
                tmpState.orderMap = tmpMap;
            }

            tmpState.filterInit = true;
            setState(tmpState);
        }
        saveUserActivity(value.ws_req_id, value.guuid);
        if(value.requestMethod === 'ReportFault') history.push({ pathname: '/reviewReportFault/' + value.guuid + '/details' });
        else history.push({ pathname: '/review/' + value.guuid + '/details' });
    }

    const realizedStatus = (status = undefined) => {
        let types:any = [
            {
                id : 0,
                text : geti18nText('ws.request.status.unrealized')
            },
            {
                id : 1,
                text : geti18nText('ws.request.status.realized')
            },
        ];
       
        return types;
    };

    const fixedColumns: any = [
        {
            dataIndex: 'realized',
            width: '3%',
           //...getColumnSearchOption(deviceStatus(status)),
           render: (text: any, record: { realized: any }) => {
            if (record.realized && record.realized===true) {
                return <CheckOutlined style={{ color: 'green' }} />;
            } else {
                return <CloseOutlined style={{ color: 'red' }} />;
            }
            },
            ...getColumnSearchOption(realizedStatus(), 'equals_bool'),
        },
        {
            title: geti18nText('review.request.response.table.column.id'),
            dataIndex: 'ws_req_id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch(
                'number',
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('ws_req_id')
                    ? state.initialMap.get('ws_req_id')
                    : undefined,
                state && history.action !== 'PUSH' && state.orderMap && state.orderMap.get('ws_req_id')
                    ? state.orderMap.get('ws_req_id') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : undefined
            ),
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.ws_req_id}
                        {record.unread && record.unread == true ? (
                            <Popover content={geti18nText('review.request.response.table.column.unread')}>
                                <InboxOutlined style={{ color: 'green', marginLeft: '5px' }} />
                            </Popover>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
        {
            title: geti18nText('review.request.response.table.column.type'),
            dataIndex: 'requestType',
            align: 'center',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(
                requestTypes(),
                'equals_string',
                undefined,
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('requestType')
                    ? state.initialMap.get('requestType').value
                    : undefined,
                state && history.action !== 'PUSH' && state.orderMap && state.orderMap.get('requestType')
                    ? state.orderMap.get('requestType') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : undefined
            ),
        },
        {
            title: geti18nText('review.request.response.table.column.time.generated'),
            dataIndex: 'timeGenerated',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(
                true,
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('timeGenerated')
                    ? {
                          type: state.initialMap.get('timeGenerated').condition,
                          value: state.initialMap.get('timeGenerated').value,
                      }
                    : undefined,
                state && history.action !== 'PUSH' && state.orderMap && state.orderMap.get('timeGenerated')
                    ? state.orderMap.get('timeGenerated') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : undefined
            ),

            render: (text: string, record: { [index: string]: any }) => {
                if (record.timeGenerated) {
                    return <div>{new Date(record.timeGenerated).toLocaleString(NyUtils.getSelectedLocale())}</div>;
                }
            },
        },
        {
            title: geti18nText('review.request.response.table.column.user'),
            dataIndex: 'username',
            align: 'center',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch(
                'string',
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('username')
                    ? state.initialMap.get('username')
                    : undefined,
                state && history.action !== 'PUSH' && state.orderMap && state.orderMap.get('username')
                    ? state.orderMap.get('username') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : undefined
            ),
        },
        {
            title: geti18nText('review.request.response.table.column.status.recieved'),
            dataIndex: 'responseCode',
            align: 'center',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.responseCode === 0 ? (
                            <Tag color="success">
                                {geti18nText(NySession.getAppValue(SettingsKey.STATUS_CODE)[record.responseCode])}
                            </Tag>
                        ) : (
                            <Tag color="red">
                                {geti18nText(NySession.getAppValue(SettingsKey.STATUS_CODE)[record.responseCode])}
                            </Tag>
                        )}
                    </div>
                );
            },
            ...getColumnSearchOption(
                statuses(),
                undefined,
                '0',
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('responseCode')
                    ? state.initialMap.get('responseCode').value
                    : undefined,
                state && history.action !== 'PUSH' && state.orderMap && state.orderMap.get('responseCode')
                    ? state.orderMap.get('responseCode') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : undefined
            ),
        },
        {
            title: geti18nText('review.request.response.table.column.status.request'),
            dataIndex: 'latestStatus',
            align: 'center',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch(
                'string',
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('latestStatus')
                    ? state.initialMap.get('latestStatus')
                    : undefined,
                state && history.action !== 'PUSH' && state.orderMap && state.orderMap.get('latestStatus')
                    ? state.orderMap.get('latestStatus') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : undefined
            ),
            render: (text: string, record: { [index: string]: any }) => {
                if (record.latestStatus && HtStatus[record.latestStatus]) {
                    return (
                        <div>
                            <Tag color={HtStatus[record.latestStatus].color} icon={HtStatus[record.latestStatus].icon}>
                                {' '}
                                {record.latestStatus}{' '}
                            </Tag>
                        </div>
                    );
                } else if (record.latestResponseCode == 0) {
                    return (
                        <div>
                            <Tag color="blue" icon={<ClockCircleOutlined />}>
                                {' '}
                                {geti18nText('ws.request.edit.status.wait.for.ht')}{' '}
                            </Tag>
                        </div>
                    );
                } else if (record.latestResponseCode != 0) {
                    return (
                        <div>
                            <Tag color="red" icon={<WarningOutlined />}>
                                {' '}
                                {geti18nText('status.error')}{' '}
                            </Tag>
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('review.request.response.table.column.last.activity'),
            dataIndex: 'latestActivity',
            align: 'center',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(
                true,
                state && history.action !== 'PUSH' && state.initialMap && state.initialMap.get('timeGenerated')
                    ? {
                          type: state.initialMap.get('timeGenerated').condition,
                          value: state.initialMap.get('timeGenerated').value,
                      }
                    : undefined,
                state && state.orderMap && state.orderMap.get('latestActivity')
                    ? state.orderMap.get('latestActivity') === 'asc'
                        ? 'ascend'
                        : 'descend'
                    : state && state.orderMap && history.action !== 'PUSH'
                    ? undefined
                    : 'descend'
            ),
            render: (text: string, record: { [index: string]: any }) => {
                if (record.latestActivity) {
                    return <div>{new Date(record.latestActivity).toLocaleString(NyUtils.getSelectedLocale())}</div>;
                }
            },
        },
    ];

    function onDataLoaded(val: any, par: any) {
        if (state) {
            let tmpState: any = state;
            tmpState.filterInit = false;
            tmpState.filter = true;

            if (par.search) {
                tmpState.search = JSON.parse(decodeURI(par.search));

                if (state.initialMap) {
                    state.initialMap = null;
                }
                let initialMap: Map<string, Object> = new Map();
                if (Object.keys(tmpState.search).length !== 0) {
                    (tmpState.search as []).forEach((element: any) => {
                        Object.keys(element).forEach((obj: string) => {
                            if (obj === 'field') {
                                initialMap.set(element[obj], { condition: element.condition, value: element.value });
                                tmpState.initialMap = initialMap;
                            }
                        });
                    });
                }
            }
            if (par.order) {
                if (state.orderMap) {
                    let orderMap: Map<String, String> = new Map();
                    orderMap.set(par.order, par.orderType);
                    tmpState.orderMap = orderMap;
                }
            }
            if (par.orderType) {
                tmpState.orderType = par.orderType;
            }
            if (par.offset != null) {
                tmpState.offset = par.offset;
            }
            if (par.max) {
                tmpState.max = par.max;
            }
            setState(tmpState);
        } else {
            let tmpState: any = {};
            tmpState.timestampTo = null;
            tmpState.timestampFrom = null;
            tmpState.type = null;
            tmpState.typeText = null;
            if (tmpState.orderMap) {
                tmpState.orderMap = null;
            }
            if (par.search) {
                tmpState.search = JSON.parse(decodeURI(par.search));
                let initialMap: Map<string, Object> = new Map();
                if (Object.keys(tmpState.search).length !== 0) {
                    (tmpState.search as []).forEach((element: any) => {
                        Object.keys(element).forEach((obj: string) => {
                            if (obj === 'field') {
                                initialMap.set(element[obj], { condition: element.condition, value: element.value });
                                tmpState.initialMap = initialMap;
                            }
                        });
                    });
                }
            }
            if (par.order) {
                let orderMap: Map<String, String> = new Map();
                orderMap.set(par.order, par.orderType);
                tmpState.orderMap = orderMap;
            }
            if (par.orderType) {
                tmpState.orderType = par.orderType;
            }
            if (par.offset) {
                tmpState.offset = par.offset;
            }
            if (par.max) {
                tmpState.max = par.max;
            }

            if (Object.keys(tmpState).length !== 0) {
                tmpState.filter = true;
                tmpState.filterInit = false;
                setState(tmpState);
            }
        }
    }

    function clearFilters(e: any) {
        if (state) {
            let tmpState = state;

            Object.keys(tmpState).forEach((el: any) => {
                tmpState[el] = null;
            });
            setState(tmpState);
        }
        setTableKey(Math.random().toString(36));
        setTypeColumns(null);
        if (props.match.params.type) {
            setStateAddedData({ type: props.match.params.type.toUpperCase() });
        }
        if (props.match.params.searchPattern) {
            setStateAddedData({ searchPattern: props.match.params.searchPattern });
        }
    }

    function createRequest() {
        setDetailsVisible(true);
    }

    return (
        <>
            <Title level={4}>
                {props.match.params.type
                    ? geti18nText('review.request.response.table.header') +
                      ' - ' +
                      props.match.params.type.toUpperCase()
                    : props.match.params.searchPattern
                    ? geti18nText('search.title') + ' "' + decodeURIComponent(props.match.params.searchPattern) + '":'
                    : geti18nText('review.request.response.table.header')}
            </Title>
            <Row style={{ marginTop: '35px', marginBottom: '15px' }} gutter={24}>
                {/* <Col span={5}>
                    <Form.Item label={geti18nText('review.request.response.table.column.time.generated.from')}>
                        <DatePicker
                            format="DD.MM.YYYY HH:mm:ss"
                            onChange={onChangeFrom}
                            key={fromKey}
                            defaultValue={
                                state && history.action !== 'PUSH' && state.timestampFrom
                                    ? moment(state.timestampFrom)
                                    : undefined
                            }
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={geti18nText('review.request.response.table.column.time.generated.to')}>
                        <DatePicker
                            format="DD.MM.YYYY HH:mm:ss"
                            key={toKey}
                            onChange={onChangeTo}
                            defaultValue={
                                state && history.action !== 'PUSH' && state.timestampTo
                                    ? moment(state.timestampTo)
                                    : undefined
                            }
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        />
                    </Form.Item>
                </Col> */}
                <Col >
                    <Button onClick={(e) => clearFilters(e)} type="dashed" icon={<DeleteOutlined />}>
                        {geti18nText('ws.request.clear.filter')}
                    </Button>
                    {props.match.params.type && (
                        <Button
                            disabled={!props.match.params.type}
                            style={{ marginLeft: '15px' }}
                            onClick={(e) => createRequest()}
                            type="primary"
                            icon={<FolderAddOutlined />}
                        >
                            {geti18nText('create.order.header')}
                        </Button>
                    )}
                </Col>
            </Row>

            <NyDataTable
                key={tableKey}
                url={props.match.path === '/' || props.match.path.includes('search')? CONSTANTS_REQ.WS_REQUEST.LIST_ALL : CONSTANTS_REQ.WS_REQUEST.LIST_CREATE}
                showRecordModal={false}
                hideButtons={true}
                addedData={addeddData() != null ? addeddData() : stateAddedData}
                columns={columns()}
                setDefaultCurrentPage={
                    state && history.action !== 'PUSH' && state.offset ? state.offset + 1 : undefined
                }
                setDefaultPageSize={state && history.action !== 'PUSH' && state.max ? state.max : 20}
                setDefaultSortOrder={sortDefault()}
                setDefaultFilterValue={() => filterDefault()}
                onRowSelect={onRowClikcHandler}
                onDataLoaded={onDataLoaded}
                
            />
            <SendRequestIndex
                visible={detailsVisible}
                requestType={props.match.params.type ? props.match.params.type.toUpperCase() : undefined}
                closeModal={closeDetailModal}
                wsMethod={WsMethods.CREATE_ORDER}
            />
        </>
    );
};

export default ReviewRequestResponse;
