//TODO: change server url
export const URL_PREFIX =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api_backend/' : '/api_backend/';

//TODO: change application key
export const APPLICATION_KEY = 'T-COM B2B';

export const CONSTANTS_REQ = {
    INIT: URL_PREFIX + 'init',
    REQUEST_TYPE: {
        SEARCH: URL_PREFIX + 'requestType/search',
    },
    REQUEST_PARAM: {
        PARAM_BY_TYPE: URL_PREFIX + 'requestTypeParameterDto/paramByType',
        LIST: URL_PREFIX + 'requestTypeParameterDto/list',
    },
    RECIEVING_REQUEST: {
        SEND_REQUEST: URL_PREFIX + 'recieving_request/send_request',
        CANCEL_ORDER: URL_PREFIX + 'recieving_request/cancel_order',
        UPDATE_ORDER: URL_PREFIX + 'recieving_request/update_order',
    },
    WS_REQUEST: {
        LIST_CREATE: URL_PREFIX + 'ws_request/listRequest',
        LIST_DASHBOARD: URL_PREFIX + 'ws_request/listDashboardRequest',
        LIST_ALL: URL_PREFIX + 'ws_request/listAllOrders',
        LIST_LOG: URL_PREFIX + 'ws_request/list-log',
        DETAILS: URL_PREFIX + 'ws_request/details',
        DETAILS_TABLE: URL_PREFIX + 'ws_request/detailsTable',
        FETCH_GET_DATA_PARAMETER: URL_PREFIX + 'ws_request/fetchGetDataParameter',
        DOWNLOAD_FILE: URL_PREFIX + 'ws_request/download',
        DASHBOARD_STATISTIC: URL_PREFIX + 'ws_request/dashboardRequestStatistic',
        DASHBOARD_STATISTIC_BY_TYPE: URL_PREFIX + 'ws_request/dashboardRequestStatisticByType',
        DASHBOARD_ALL_REQUESTS: URL_PREFIX + 'ws_request/dashboardRequestAll',
        DASHBOARD_SEND_NOTIFICATION_UNREADED: URL_PREFIX + 'ws_request/dashboarSendNotificationUnreaded',
        FAA_REALIZED_REQUEST: URL_PREFIX + 'ws_request/faaRealizedRequest',
        FAA_ACCEPTED_REQUEST: URL_PREFIX + 'ws_request/faaAcceptedRequest',
        FAA_REJECTED_REQUEST: URL_PREFIX + 'ws_request/faaRejectedRequest',
        REALIZED: URL_PREFIX + 'ws_request/realized',
    },
    CERT: {
        AUDIT: URL_PREFIX + 'cert/listAudit',
        DETAILS: URL_PREFIX + 'cert/details',
        UPLOAD: URL_PREFIX + 'cert/upload',
        STOP_SERVICE: URL_PREFIX + 'cert/stopService',
        START_SERVICE: URL_PREFIX + 'cert/startService',
    },
    USER: {
        LIST: URL_PREFIX + 'users/list',
        EDIT: URL_PREFIX + 'users/edit',
        RESET: URL_PREFIX + 'users/reset',
        USERNAME_EXIST: URL_PREFIX + 'users/username_validate',
    },
    ROLE: {
        LIST: URL_PREFIX + 'role/list',
        EDIT: URL_PREFIX + 'role/edit',
        SEARCH: URL_PREFIX + 'role/search',
    },
    LOG_REQUEST: {
        SAVE: URL_PREFIX + 'log_request',
        LIST: URL_PREFIX + 'log_request/list',
        IS_READ: URL_PREFIX + 'log_request/isRead',
    },
    SETTINGS: {
        MAIL: URL_PREFIX + 'mailSettings/edit',
        TEST_MAIL: URL_PREFIX + 'mailSettings/testMail',
    },
    REPORT_FAULT: {
        LIST: URL_PREFIX + 'reportFault/listRequest',
        SEND_REQUEST: URL_PREFIX + 'reportFault/sendReportFault',
        DETAILS: URL_PREFIX + 'reportFault/details',
        ESCALATION: URL_PREFIX + 'reportFault/escalation',
    },
    DISTRIBUTION_NODE_DATA: {
        LIST: URL_PREFIX + 'distribution_node_data/list',
        EDIT: URL_PREFIX + 'distribution_node_data/edit',
        DATA: URL_PREFIX + 'distribution_node_data/data',
    },
    OLT_FREE_CAPACITY_DATA: {
        LIST: URL_PREFIX + 'olt_free_capacity_data/list',
        EDIT: URL_PREFIX + 'olt_free_capacity_data/edit',
        DATA: URL_PREFIX + 'olt_free_capacity_data/data',
    },
    LOCATION_DIVIDER: {
        LIST: URL_PREFIX + 'location_divider/list',
        EDIT: URL_PREFIX + 'location_divider/edit',
        DATA: URL_PREFIX + 'location_divider/data',
    },
    
    CONNECTION_LOCATION: {
        LIST: URL_PREFIX + 'connection_location/list',
        EDIT: URL_PREFIX + 'connection_location/edit',
        DATA: URL_PREFIX + 'connection_location/data',
    },
    CHECK_OPERATOR_INFORMATION: {
        LIST: URL_PREFIX + 'check_operator_information/list',
        EDIT: URL_PREFIX + 'check_operator_information/edit',
        DATA: URL_PREFIX + 'check_operator_information/data',
    },
    STREET_COVERAGE_BY_LOCATION: {
        LIST: URL_PREFIX + 'street_coverage_by_location/list',
        EDIT: URL_PREFIX + 'street_coverage_by_location/edit',
        DATA: URL_PREFIX + 'street_coverage_by_location/data',
    },
    SEARCH_LOCATION_BY_PHONE: {
        LIST: URL_PREFIX + 'search_location_by_phone/list',
        EDIT: URL_PREFIX + 'search_location_by_phone/edit',
        DATA: URL_PREFIX + 'search_location_by_phone/data',
    },
    INFO_ABOUT_NETWORK_TOPOLOGY: {
        LIST: URL_PREFIX + 'info_about_network_topology/list',
        EDIT: URL_PREFIX + 'info_about_network_topology/edit',
        DATA: URL_PREFIX + 'info_about_network_topology/data',
    },
    FTH_AVAILABILITY: {
        LIST: URL_PREFIX + 'ftth_availability/list',
        EDIT: URL_PREFIX + 'ftth_availability/edit',
        DATA: URL_PREFIX + 'ftth_availability/data',
    },
    ADSL_VDSL_AVAILABILITY: {
        LIST: URL_PREFIX + 'adsl_vdsl_availability/list',
        EDIT: URL_PREFIX + 'adsl_vdsl_availability/edit',
        DATA: URL_PREFIX + 'adsl_vdsl_availability/data',
    },
    NEW_INSTALLATION_FTTB_FTTDB: {
        LIST: URL_PREFIX + 'new_installation_fttb_fttdp/list',
        EDIT: URL_PREFIX + 'new_installation_fttb_fttdp/edit',
        DATA: URL_PREFIX + 'new_installation_fttb_fttdp/data',
    },
    
    OLT_FREE_CAPACITY_AREA: {
        LIST: URL_PREFIX + 'olt_free_capacity_area/listItems',
        EDIT: URL_PREFIX + 'olt_free_capacity_area/details',
        DATA: URL_PREFIX + 'olt_free_capacity_area/data',
    },
    DISTRIBUTION_NODE_AREA: {
        LIST: URL_PREFIX + 'distribution_node_area/listItems',
        DETAILS: URL_PREFIX + 'distribution_node_area/details',
        DATA: URL_PREFIX + 'distribution_node_area/data',
    },
    DSLAM_FREE_CAPACITY_DATA: {
        LIST: URL_PREFIX + 'dslam_free_capacity_data/list',
        EDIT: URL_PREFIX + 'dslam_free_capacity_data/edit',
        DATA: URL_PREFIX + 'dslam_free_capacity_data/data',
    },
    DSLAM_FREE_CAPACITY_AREA: {
        LIST: URL_PREFIX + 'dslam_free_capacity_area/listItems',
        DETAILS: URL_PREFIX + 'dslam_free_capacity_area/details',
        DATA: URL_PREFIX + 'dslam_free_capacity_area/data',
    },
    RESET_DSLAM_SETTINGS: {
        LIST: URL_PREFIX + 'reset_dslam_settings_data/list',
        EDIT: URL_PREFIX + 'reset_dslam_settings_data/edit',
        DATA: URL_PREFIX + 'reset_dslam_settings_data/data',
    },

    DSLAM_CONFIGURATION: {
        LIST: URL_PREFIX + 'dslam_configuration_data/list',
        EDIT: URL_PREFIX + 'dslam_configuration_data',
        DATA: URL_PREFIX + 'dslam_configuration_data/data',
    },

    CHECK_AVAILABILITY: {
        LIST: URL_PREFIX + 'check_availability_data/list',
        EDIT: URL_PREFIX + 'check_availability_data',
        DATA: URL_PREFIX + 'check_availability_data/data',
    },
    GET_DATA_PARAMETERS: {
        LIST: URL_PREFIX + 'getDataParameters/listItems',
    },
    TENANT: {
            LIST: URL_PREFIX + 'tenant/list',
            EDIT: URL_PREFIX + 'tenant/edit',
            SEARCH: URL_PREFIX + 'tenant/search',
            DASHBOARD_TYPE: URL_PREFIX + 'tenant/dashboardType',
    },
    COUNTY: {
            LIST: URL_PREFIX + 'county/list',
            EDIT: URL_PREFIX + 'county/edit',
            SEARCH: URL_PREFIX + 'county/search',
    },
};

export enum WsMethods {
    CREATE_ORDER = 'CreateOrder',
    UPDATE_ORDER = 'UpdateOrder',
    SEND_ORDER_STATUS = 'SendOrderStatus',
    SEND_FAULT_STATUS = 'SendFaultStatus',
    CANCEL_ORDER = 'CancelOrder',
    GET_DATA = 'GetData',
    REPORT_FAULT = 'ReportFault',
    SEND_NOTIFICATION = 'SendNotification',
}

export enum SettingsKey {
    REQ_PARAM_TYPE = 'REQ_PARAMS_TYPE',
    REQ_TYPES = 'REQ_TYPES',
    STATUS_CODE = 'STATUS_CODE',
    WS_METHOD = 'WS_METHOD',
    STATUS_TYPE = 'STATUS_TYPE',
    CERT_ACTIONS = 'CERT_ACTIONS',
    COUNTIES = 'COUNTIES',
}

export enum ParamStatus {
    IN = 'IN',
    OUT = 'OUT',
}

export enum DefaultSettings {
    DEFAULT_DATE_TIME_REQUEST = 'yyyy-MM-DD HH:mm:ss',
}
