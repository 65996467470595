import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.compact.css';
import 'react-grid-layout/css/styles.css';
import hrHR from 'antd/es/locale/hr_HR';
import enEN from 'antd/es/locale/en_US';
import {ConfigProvider} from 'antd';
import { APPLICATION_KEY, URL_PREFIX } from './utils/Constants';
import { NySettings, NyUtils } from '@nybble/nyreact';

ReactDOM.render(
    <React.StrictMode>
        {/* ConfigProvider - ant design components language */}
        <ConfigProvider locale={NyUtils.getSelectedLocale() == 'hr-HR' ? hrHR : enEN }>
            {/* Initial settings for NyReact framework */}
            <NySettings
                applicationKey={APPLICATION_KEY}
                urlPreffix={URL_PREFIX}
                urlRefresh={URL_PREFIX + 'auth/refresh'}
            />
            <App />
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
