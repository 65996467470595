import { CheckOutlined, CloseOutlined, InboxOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
    getColumnDateOption,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { Popover, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ, SettingsKey, WsMethods } from '../../utils/Constants';
import WsRequestDetails from '../ws-request/edit';
import InformationFromHtDetails from './edit';

const InformationHt = () => {
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const { Title } = Typography;


    const onSave=()=>{
        setTableKey(Math.random().toString(36));
    }

    return (
        <>
            <Title level={4}>{geti18nText('menu.information.ht')}</Title>
            <NyDataTable
                //headerTitle={geti18nText('ws.request.table.header')}
                url={CONSTANTS_REQ.WS_REQUEST.LIST_LOG}
                showRecordModal={true}
                setDefaultPageSize={20}
                hideButtons={true}
                addedData={{requestMethod: WsMethods.SEND_NOTIFICATION}}
                key={tableKey}
                modalComponent={InformationFromHtDetails}
                onSaveAndGetID={onSave}
                scroll={{x: 700 }}
                columns={[
                    {
                        title: geti18nText('review.request.response.table.column.time.generated'),
                        dataIndex: 'timeGenerated',
                        width: '13%',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.timeGenerated) {
                                return (
                                    <div>
                                        {new Date(record.timeGenerated).toLocaleString(NyUtils.getSelectedLocale())}
                                        {record.unread && record.unread == true ? (
                                            <Popover content={geti18nText('review.request.response.table.column.unread')}>
                                                <InboxOutlined style={{ color: 'green', marginLeft: '5px' }} />
                                            </Popover>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                );
                            }
                        },
                        ...getColumnDateOption(true),
                    },
                    {
                        title: geti18nText('params.SendNotification.idusl'),
                        dataIndex: ['paramSendNotification','idusl'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.telbr'),
                        dataIndex: ['paramSendNotification','telbr'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.pdisk'),
                        dataIndex: ['paramSendNotification','pdisk'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.nadrsin'),
                        dataIndex: ['paramSendNotification','nadrsin'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.trajisk'),
                        dataIndex: ['paramSendNotification','trajisk'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.nazkk'),
                        dataIndex: ['paramSendNotification','nazkk'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.dtmre'),
                        dataIndex: ['paramSendNotification','dtmre'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.option82'),
                        dataIndex: ['paramSendNotification','option82'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.fttbbrzgf'),
                        dataIndex: ['paramSendNotification','fttbbrzgf'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.idbsa'),
                        dataIndex: ['paramSendNotification','idbsa'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.idnbsa'),
                        dataIndex: ['paramSendNotification','idnbsa'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('params.SendNotification.idull'),
                        dataIndex: ['paramSendNotification','idull'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                ]}
            />
        </>
    );
};

export default InformationHt;
