import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    geti18nText,
    getColumnSearch,
    getColumnDateOption,
    getColumnSearchOption,
    ENUMS,
    NyUtils,
    NySession,
    NyRequestResolver,
    RESPONSE,
    NySearchField,
} from '@nybble/nyreact';
import { Button, Col, Input, message, notification, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ, SettingsKey } from '../../utils/Constants';

const ConnectionLocationIndex = () => {
    const { Option } = Select;
    const [selectedTelBr, setSelectedTelBr] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const timeout: any = useRef(null);


    useEffect(() => {
        setTableKey(Math.random().toString(36));
    }, [selectedTelBr]);

    function setDefaultFilterValue() {
        if (selectedTelBr) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'telBrIn', condition: 'equals_string', value: selectedTelBr.toString() },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }


    function onRefreshClick(e: any) {
        setLoading(true);
        const params = { telBr: selectedTelBr };
        NyRequestResolver.requestPost(CONSTANTS_REQ.CONNECTION_LOCATION.DATA, undefined, {
            params: params,
            requestType: 42,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    }

    const onchangeTelBr=(value:any)=>{
        if (timeout) {
            clearTimeout(timeout.current);
          }
          timeout.current = setTimeout(() => {
            setSelectedTelBr(value.target.value)          
        }, 1000);
        
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={6}>
                    <Input  onChange={(e)=>onchangeTelBr(e)}></Input>
                </Col>
                <Col>
                    <Button loading={loading} type="primary" disabled={selectedTelBr == null} onClick={onRefreshClick}>
                        {geti18nText('distribution.node.header.refresh.btn')}
                    </Button>
                </Col>
            </Row>

            <NyDataTable
                key={tableKey}
                headerTitle={geti18nText('menu.connection.location')}
                url={CONSTANTS_REQ.CONNECTION_LOCATION.LIST}
                showRecordModal={false}
                hideNewButton={true}
                readonly
                setDefaultPageSize={20}
                setDefaultFilterValue={setDefaultFilterValue}
                exportCSV={true}
                colCSVCustomization={[
                    {
                        county: (value: any) => {
                            if (value) {
                                return value.name;
                            }
                            return '';
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('app.default.active');
                            } else {
                                return geti18nText('app.default.inactive');
                            }
                        },
                    },
                    {
                        getDate: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale()) : '';
                        },
                    },
                ]}
                columns={[
                    {
                        title: geti18nText('distribution.node.table.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('connection.location.telBrIn'),
                        dataIndex: 'telBrIn',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('connection.location.lok.razd'),
                        dataIndex: 'location',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('connection.location.mjesto'),
                        dataIndex: 'mjesto',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('connection.location.adr'),
                        dataIndex: 'adr',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('connection.location.zup'),
                        dataIndex: 'zup',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('distribution.node.table.get_date'),
                        dataIndex: 'getDate',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.getDate) {
                                return (
                                    <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                );
                            }
                        },
                    },

                    {
                        title: geti18nText('users.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                    },
                ]}
            />
        </>
    );
};

export default ConnectionLocationIndex;
