import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Modal, Form, Row, Col, Input, Divider, notification, Select, Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import NyFileUpload from '../../components/file-upload';

const ClientEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('client.table.header.new'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [emails, setEmails] = useState<any>(null);
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [file, setFile] = useState<any>(null);
    const [previewFile, setPreviewFile] = useState<any>(undefined);
    const [imageUrl, setImageUrl] = useState<any>(null);
    const [dashboardType, setDashboardType] = useState(1);

    const optionsDashboarDtypes = [
        { label: geti18nText('enum.DASHBOARD_TYPE.standard'), value: 1 },
        { label: geti18nText('enum.DASHBOARD_TYPE.FAA'), value: 2 },
      ];


    useEffect(()=>{
        if(file) {
            setPreviewFile(undefined);
            getBase64(file[0],setImageUrl);
        }
    },[file])
 
    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('client.table.header.new'));
    };

    function setValues(dataForm: any) {
        if(dataForm.mailAddresses) {
            setEmails(dataForm.mailAddresses.split(','))
            dataForm.mailAddresses=dataForm.mailAddresses.split(',')
        }
        if(dataForm.logo) {
            setImageUrl(dataForm.logo)
            let imgFile='data:image/jpeg;base64,' + dataForm.logo;
            setPreviewFile(imgFile);
        }
        if(dataForm.dashboardType) {
            setDashboardType(dataForm.dashboardType)
        }
        form.setFieldsValue(dataForm);
    }

    function normalizeValues(values: any) {
        let normalized = { ...values };
        normalized.active = 'active' in values ? values['active'] : true;
        if(emails) {
            normalized.mailAddresses=emails.join(',')
        }
        if(imageUrl) {
            normalized.file=imageUrl
        }
        if(normalized.logo) {
            delete normalized.logo;
        }
        normalized.dashboardType=dashboardType
        return normalized;
    }

    const changeEmails = (value:any) => {
        setEmails(value);
    };

    function getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const onChangeDashboardType=({ target: { value } }: RadioChangeEvent)=>{
        console.log('radio3 checked', value);
        setDashboardType(value)

    }


    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.TENANT.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalizeValues}
            {...props}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('client.edit.name')} name="name" rules={[{required: true,message: geti18nText('app.default.required'),},]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  label={geti18nText('client.edit.code')} name="code" rules={[{pattern: new RegExp("^[a-zA-Z0-9]*$",),message: geti18nText('client.edit.code.input.validate.message')},
                        {required: true,message: geti18nText('app.default.required'),},]}>
                        <Input disabled={!isCreate} />
                    </Form.Item>
                </Col>
                
            </Row>
            <Row gutter={24}>
                    <Col span={12}>    
                        <Form.Item label={geti18nText('client.edit.domain')} name="domain" rules={[{required: true,message: geti18nText('app.default.required'),},]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>    
                        <Form.Item label={geti18nText('client.edit.port')} name="port" rules={[{required: true,message: geti18nText('app.default.required'),},]}>
                            <Input />
                        </Form.Item>
                    </Col>
                
            </Row>
            <Row gutter={24}>
                    <Col span={12}>    
                        <Form.Item label={geti18nText('client.edit.operator.id')} name="operatorId" rules={[{required: true,message: geti18nText('app.default.required')}]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>    
                        <Form.Item label={geti18nText('client.edit.operator.name')} name="operatorName" rules={[{required: true,message: geti18nText('app.default.required')}]}>
                            <Input />
                        </Form.Item>
                    </Col>
                
            </Row>
            <Row gutter={24}>
                    <Col span={12}>    
                        <Form.Item label={geti18nText('client.edit.operator.footer.domain')} name="footerDomain" >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>    
                        <Form.Item label={geti18nText('client.edit.operator.footer.name')} name="footerName" >
                            <Input />
                        </Form.Item>
                    </Col>
                
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                        <Form.Item label={geti18nText('client.edit.mail.address')} name="mailAddresses">
                            <Select
                                                        mode="tags"
                                                        notFoundContent={null}
                                                        style={{ width: '100%' }}
                                                        onChange={changeEmails}
                                                    />
                        </Form.Item>
                </Col>
                <Col span={12}>
                        <Form.Item label={geti18nText('client.edit.dashboard.type')} >
                            <Radio.Group buttonStyle="solid" options={optionsDashboarDtypes} onChange={onChangeDashboardType} value={dashboardType} optionType="button" />
                        </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item name='logo' label={geti18nText('client.edit.logo')}>
                        {previewFile &&  (
                                <img style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} src={previewFile}></img>
                        )}
                        <NyFileUpload accept='image/*' key={uploadKey} files={file} setFiles={setFile} multiple={false} />
                        </Form.Item>
                    </Col>
                   
                
            </Row>
        </NyDataEdit>
    );
};

export default ClientEdit;
