import { ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import {
    NyRequestResolver,
    NySpinner,
    RESPONSE,
    geti18nText
} from '@nybble/nyreact';
import { Button, List, Popover, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardProgress from '../../components/dashboard-progress';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { HtStatus } from '../review-request-response/edit';



const Dashboard = () => {
    const [loading, setLoading] = useState<any>(true);
    const [loadingRealized, setLoadingRealized] = useState<any>(false);
    const [loadingMoreRealized, setLoadingMoreRealized] = useState<any>(false);
    const [loadingOpened, setLoadingOpened] = useState<any>(false);
    const [loadingMoreOpened, setLoadingMoreOpened] = useState<any>(false);
    const [loadingRejected, setLoadingRejected] = useState<any>(false);
    const [loadingMoreRejected, setLoadingMoreRejected] = useState<any>(false);
    const [data, setData] = useState<any>(undefined);
    const [dataDashboard, setDataDashboard] = useState<any>(undefined);
    const [sendNotificationUnreaded, setSendNotificationUnreaded] = useState<any>(undefined);
    const [dataByType, setDataByType] = useState<any>(undefined);
    const [selectedType, setSelectedType] = useState(undefined);
    const [maxHeightChart, setMaxHeightChart] = useState<any>(undefined);
    const [dashboardType, setDashboardType] = useState<any>(undefined);
    const [realizedRequest, setRealizedRequest] = useState<any>(undefined);
    const [rejectedRequest, setRejectedRequest] = useState<any>(undefined);
    const [openedRequest, setOpenedRequest] = useState<any>(undefined);
    const [realizedOffset, setRealizedOffset] = useState<any>(0);
    const [openedOffset, setOpenedOffset] = useState<any>(0);
    const [rejectedOffset, setRejectedOffset] = useState<any>(0);
    let history = useHistory();

    useEffect(()=>{
        const init=async()=>{
            await fetchDashboardType();
            await fetchData()
            await fetchDataAllRequest()
            await fetchDataSendNotification()
            setLoading(false)
        }
        init();
        
    },[])


    const fetchDashboardType=async ()=>{
        let result:any= await NyRequestResolver.requestGet(CONSTANTS_REQ.TENANT.DASHBOARD_TYPE)
        if(result.status ===RESPONSE.OK && result.data) {
            let data=result.data;
            if(data.dashboardType && data.dashboardType ===2) {
                await fetchFaaOpenedRequest(0);
                await fetchFaaRealizedRequest(0);
                await fetchFaaRejectedRequest(0);
            }
            setDashboardType(data.dashboardType?data.dashboardType:undefined)
        }
    }
    
    const fetchFaaRealizedRequest=async (offset?:any)=>{
        let params:any={}
        if(offset || offset ===0) {
            params.offset=offset
            params.status="REALIZIRAN"
            params.realized="false"
            params.order="latestActivity"
            params.orderType="desc"
            setRealizedOffset(offset)
            
        }
        let result:any= await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.LIST_DASHBOARD,params)
        if(result.status ===RESPONSE.OK && result.data) {
            let data=result.data;
            let oldData=realizedRequest && realizedRequest.content ? realizedRequest.content: []
            setRealizedRequest({...data, content: [...oldData,...data.content?data.content:[]]})
        }
    }
    const fetchFaaOpenedRequest=async (offset?:any)=>{
        let params:any={}
        if(offset || offset ===0) {
            params.offset=offset
            params.requestFromHt=true
            params.order="latestActivity"
            params.orderType="desc"
            params.statuses=["REALIZIRAN","ODBIJEN","STORNO"]
            setOpenedOffset(offset)
            
        }
        let result:any= await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.LIST_DASHBOARD,params)
        if(result.status ===RESPONSE.OK && result.data) {
            let data=result.data;
            let oldData=openedRequest && openedRequest.content ? openedRequest.content: []
            setOpenedRequest({...data, content: [...oldData,...data.content?data.content:[]]})
        }
    }
    const fetchFaaRejectedRequest=async (offset?:any)=>{
        let params:any={}
        if(offset || offset ===0) {
            params.offset=offset
            params.status="ODBIJEN"
            params.order="latestActivity"
            params.orderType="desc"
            setRejectedOffset(offset)
            
        }
        let result:any= await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.LIST_DASHBOARD,params)
        
        if(result.status ===RESPONSE.OK && result.data) {
            let data=result.data;
            let oldData=rejectedRequest && rejectedRequest.content ? rejectedRequest.content: []
            setRejectedRequest({...data, content: [...oldData,...data.content?data.content:[]]})
        }
    }
    const fetchData=async ()=>{
        let result:any=await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.DASHBOARD_STATISTIC);
        if(result.status ===RESPONSE.OK && result.data) {
            let data=result.data;
            data.forEach((item:any)=>{
                item.visible=false;
            })
            setData(data)
        }
    }
    const fetchDataSendNotification=async ()=>{
        let result:any=await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.DASHBOARD_SEND_NOTIFICATION_UNREADED)
        if(result.status ===RESPONSE.OK && result.data) {
            let data=result.data;
            setSendNotificationUnreaded(data)
           }
    }
    
    const fetchDataAllRequest=async ()=>{
        let result:any=await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.DASHBOARD_ALL_REQUESTS)
            if(result.status ===RESPONSE.OK && result.data) {
                setDataDashboard(result.data)
                let highBadStatus=result.data.reduce((max:any, status:any) =>status.badStatus > max ? status.badStatus : max, 0);
                let succesStatus=result.data.reduce((max:any, status:any) =>status.successStatus > max ? status.successStatus : max, 0);
                setMaxHeightChart(highBadStatus>succesStatus?highBadStatus:succesStatus)
           }
    }

    const isOpen=(requestType:any)=>{
        if(data) {
           let type=data.find((item:any)=>item.requestType === requestType)
           return type.visible
        }
        return false;
    }

    const fetchDataByType=async (type:any,newOpen:any)=>{
        let result:any=await NyRequestResolver.requestGet(CONSTANTS_REQ.WS_REQUEST.DASHBOARD_STATISTIC_BY_TYPE,{requestType: type})
        if(result.status ===RESPONSE.OK && result.data) {
            setData((prevItemState: any) =>
            prevItemState.map((item: any, index: any) =>{
                if(item.requestType === type) {
                    item.visible=newOpen
                }
                return item
             
            })
          );
          setDataByType(result.data);
        }
    }

    const StatusByType=(props:any)=>{
        return <div>
            <div style={{flexDirection:'row', display:'flex', alignItems:'center',marginBottom:15}}>
                    <div style={{fontSize:'20px', fontWeight:'bolder',color:'#1C1D21'}}>{props.name}</div>
                    <div style={{color:'#8181A5', fontSize:'13px',marginLeft:30}}>{geti18nText('dashboard.status.by.type.view.title')}</div>
            </div>
            {dataByType && dataByType.map((item:any)=>{
                return <div>
                    <div style={{flexDirection:'row', display:'flex', alignItems:'center',marginBottom:15}}>
                        <div style={{flex:0.7}}>
                            <div style={{flex:0.5,color:'#8181A5', fontSize:'13px'}}>{item?.latestStatus.toUpperCase()}</div>
                        </div>
                        <div style={{fontSize:'20px', fontWeight:'bolder', color:'#1C1D21', flex:0.3,display:'flex', justifyContent:'flex-end' }}>{item.numberStatus}</div>
                    </div>
                    <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                       {item.latestStatus != undefined && <DashboardProgress color={item.latestStatus?HtStatus[item.latestStatus.toUpperCase()].color:undefined}  percent={(item.numberStatus/props.totalRequest)*100} />}
                    </div>
                </div>
            })}
            {dataByType && dataByType.length>0 && (props.totalRequest> dataByType.map((item:any)=>item.numberStatus).reduce((sum:any, current:any) => sum + current, 0)) && <div>
                    <div style={{flexDirection:'row', display:'flex', alignItems:'center',marginBottom:15}}>
                        <div style={{flex:0.7}}>
                            <div style={{flex:0.5,color:'#8181A5', fontSize:'13px'}}>{geti18nText('ws.request.edit.status.wait.for.ht')}</div>
                        </div>
                        <div style={{fontSize:'20px', fontWeight:'bolder', color:'#1C1D21', flex:0.3,display:'flex', justifyContent:'flex-end' }}>{(props.totalRequest-dataByType.map((item:any)=>item.numberStatus).reduce((sum:any, current:any) => sum + current, 0))}</div>
                    </div>
                    <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                       {<DashboardProgress color={'#0e9af2'}  percent={((props.totalRequest-dataByType.map((item:any)=>item.numberStatus).reduce((sum:any, current:any) => sum + current, 0))/props.totalRequest)*100} />}
                    </div>
            </div>}
            {(!dataByType || dataByType.length===0) && <div>
                    <div style={{flexDirection:'row', display:'flex', alignItems:'center',marginBottom:15}}>
                        <div style={{flex:0.7}}>
                            <div style={{flex:0.5,color:'#8181A5', fontSize:'13px'}}>{geti18nText('ws.request.edit.status.wait.for.ht')}</div>
                        </div>
                        <div style={{fontSize:'20px', fontWeight:'bolder', color:'#1C1D21', flex:0.3,display:'flex', justifyContent:'flex-end' }}>{props.totalRequest}</div>
                    </div>
                    <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                       {<DashboardProgress color={'#0e9af2'}  percent={100} />}
                    </div>
            </div>}
            
        </div>
    }

    const handleOpenChange=(newOPen:any,type:any)=>{
            setSelectedType(type)
            fetchDataByType(type,newOPen)
    }

    const openType=(name:any)=>{
        history.push({ pathname: '/review/' + name.toLowerCase(), state: {selected : '/review/'} });
    }
    
    const openDetails=(uuuid:any)=>{
        history.push({ pathname: '/review/' + uuuid + '/details', });
    }

    const showUnreadIcon=(unread:any)=>{
        if (unread && unread>0) return <InboxOutlined className="ny-blink" style={{ color: 'green', marginLeft: '5px',  }} />
    }
   
    const TypeReport=(props:any)=>{
        return <div className='container_type_report' >
            <div onClick={()=>{  openType(props.name)}} style={{flexDirection:'row', display:'flex',marginBottom:15, cursor:'pointer'}}>
                <div style={{flex:0.7}}>
                    <div style={{fontSize:'20px', fontWeight:'bolder',color:'#1C1D21'}}>{props.name}{showUnreadIcon(props.unread)}</div>
                    <div style={{flex:0.5,color:'#8181A5', fontSize:'13px',textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden', width:'250px'}}>{geti18nText('request.type.'+props.name)}</div>
                </div>
                <div style={{fontSize:'20px', fontWeight:'bolder', color:'#1C1D21', flex:0.3,display:'flex', justifyContent:'flex-end' }}>{props.requests}</div>
            </div>
            <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <DashboardProgress percent={(props.numberStatus/props.requests)*100} />
                <Popover trigger="click"
                        visible={isOpen(props.name)}
                        onVisibleChange={(visible)=>handleOpenChange(visible,props.name)}
                        content={()=><StatusByType name={props.name} totalRequest={props.requests}/>} title="">
                <ExclamationCircleOutlined style={{color:'#FFA200', marginLeft:10, fontSize:'15px'}}/>
                </Popover>
            </div>
        </div>
    }

    const calculateHeight=(status:any)=>{
        if(status===0) return '0px'
        if(status === maxHeightChart) {
            return '200px'
        }
        else { 
            let calculated=(status/maxHeightChart)*200
            return calculated+'px'
        }
        return '200px' 

        
    }

    const renderDashboardChart=()=>{
        return <div style={{flexWrap: 'wrap', display:'flex', cursor:'pointer'}} onClick={()=>{
            history.push({ pathname: '/ws-request', state: { selected: 'ws-request'} });
        }}>
                {dataDashboard && dataDashboard.map((status:any)=>{
                    return <div className='popover__wrapper' style={{flexDirection:'row', display:'flex', marginLeft:'10px', alignItems:'end'}}>
                        <div style={{background:'#7581C9', height: calculateHeight(status.successStatus), width:'15px'}}></div>
                        <div style={{background:'#DFB7BF',height: calculateHeight(status.badStatus), width:'15px', marginLeft:'1px'}}></div>
                        <div className="popover__content">
                            <div style={{textAlign:'center',fontWeight:'bolder',color:'#1C1D21'}}>{status.day}</div>
                            <div style={{textAlign:'center',fontWeight:'bolder',color:'#1C1D21'}}>{geti18nText('ht.chart.all.success')}{': '}{status.successStatus}</div>
                            <div style={{textAlign:'center',fontWeight:'bolder',color:'#1C1D21'}}>{geti18nText('ht.chart.all.bad')}{': '} {status.badStatus}</div>

                        </div>
                    </div>
                })}
             </div>
        
    }

    const onLoadMoreRealized = () => {
        setLoadingRealized(true);
        setLoadingMoreRealized(true)
        fetchFaaRealizedRequest(realizedOffset+1)
        setLoadingRealized(false)
       
    };

    const onLoadMoreOpened = () => {
        setLoadingOpened(true);
        setLoadingMoreOpened(true)
        fetchFaaOpenedRequest(openedOffset+1)
        setLoadingOpened(false)
       
    };
    const onLoadMoreRejected = () => {
        setLoadingRejected(true);
        setLoadingMoreRejected(true)
        fetchFaaRejectedRequest(rejectedOffset+1)
        setLoadingRejected(false)
       
    };

    const loadMore =
    realizedRequest && (realizedOffset< (realizedRequest.totalPages-1)) && realizedRequest.totalSize>10 && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMoreRealized}>{geti18nText('dashboard.loading.more.btn')}</Button>
      </div>
    ) : null;
    
    const loadMoreRejected =
    rejectedRequest && (rejectedOffset< (rejectedRequest.totalPages-1)) && rejectedRequest.totalSize>10 && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMoreRejected}>{geti18nText('dashboard.loading.more.btn')}</Button>
      </div>
    ) : null;


    const loadMoreOpened =
    openedRequest && (openedOffset< (openedRequest.totalPages-1)) && openedRequest.totalSize>10 && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMoreOpened}>{geti18nText('dashboard.loading.more.btn')}</Button>
      </div>
    ) : null;

   
    const DashboardFAA=()=>{
        return <div style={{display:'flex', justifyContent:'space-around'}}>
            <div style={{flex:0.33}}>
            <List
                header={<div>{geti18nText('dashboard.faa.realiziran')}</div>}
                bordered
                loading={loadingRealized}
                loadMore={loadMore}
                dataSource={realizedRequest && realizedRequest.content}
                renderItem={(item:any) => (
                    <List.Item style={{flexDirection:'column', alignItems: 'flex-start'}} onClick={()=>openDetails(item.guuid)}>
                        <Tag color="blue" style={{marginBottom: 20}}>{item.requestType}</Tag>
                       <a> <Typography.Text mark>{item.adrs}</Typography.Text><Typography.Text > {item.nazpr}</Typography.Text><Typography.Text > {', '+item.latestActivity}</Typography.Text></a>
                    </List.Item>
                )}
            />
            </div>
            <div style={{flex:0.33}}>
            <List
                header={<div>{geti18nText('dashboard.faa.prihvacen')}</div>}
                bordered
                loading={loadingOpened}
                loadMore={loadMoreOpened}
                dataSource={openedRequest && openedRequest.content}
                renderItem={(item:any) => (
                    <List.Item style={{flexDirection:'column', alignItems: 'flex-start'}} onClick={()=>openDetails(item.guuid)}>
                        <Tag color="blue" style={{marginBottom: 20}}>{item.requestType}</Tag>
                       <a> <Typography.Text mark>{item.adrs}</Typography.Text><Typography.Text > {item.nazpr}</Typography.Text><Typography.Text > {', '+item.latestActivity}</Typography.Text></a>
                    </List.Item>
                )}
            />
            </div>
            <div style={{flex:0.33}}>
            <List
                header={<div>{geti18nText('dashboard.faa.odbijen')}</div>}
                bordered
                loading={loadingRejected}
                loadMore={loadMoreRejected}
                dataSource={rejectedRequest&& rejectedRequest.content}
                renderItem={(item:any) => (
                    <List.Item style={{flexDirection:'column', alignItems: 'flex-start'}} onClick={()=>openDetails(item.guuid)}>
                        <Tag color="blue" style={{marginBottom: 20}}>{item.requestType}</Tag>
                       <a> <Typography.Text mark>{item.adrs}</Typography.Text><Typography.Text > {item.nazpr}</Typography.Text><Typography.Text > {', '+item.latestActivity}</Typography.Text></a>
                    </List.Item>
                )}
            />
            </div>

        </div>
    }

    return (
        loading ? <NySpinner />: dashboardType===2 ? DashboardFAA(): <div style={{width:'100%', background:'#F2F2F2',padding:10}}>
            {data && data.filter((itm:any)=>itm.requestType.startsWith('FA')).length>0 && <div style={{marginLeft:20,fontSize:'16px', fontWeight:'bold'}}>
                {geti18nText('dashboard.FAA')}
            </div>
            
            }
            <div style={{width:'100%', background:'#F2F2F2',padding:10,display:'flex', flexWrap:'wrap'}}>
            {data && data.filter((itm:any)=>itm.requestType.startsWith('FA')).map((item:any)=>{
                    return <TypeReport unread={item.unreaded} numberStatus={item.numberStatus} name={item.requestType} requests={item.numberRequest}></TypeReport>
            })}
            </div>
            
            {data && data.filter((itm:any)=>itm.requestType.startsWith('PIA')).length>0 && <div style={{marginLeft:20,fontSize:'16px', fontWeight:'bold'}}>
                {geti18nText('dashboard.PIA')}
            </div>
            
            }
            <div style={{width:'100%', background:'#F2F2F2',padding:10,display:'flex', flexWrap:'wrap'}}>
            {data && data.filter((itm:any)=>itm.requestType.startsWith('PIA')).map((item:any)=>{
                    return <TypeReport unread={item.unreaded} numberStatus={item.numberStatus} name={item.requestType} requests={item.numberRequest}></TypeReport>
            })}
            </div>
            
            {data && data.filter((itm:any)=>itm.requestType.startsWith('DSL')).length>0 && <div style={{marginLeft:20,fontSize:'16px', fontWeight:'bold'}}>
                {geti18nText('dashboard.DSL')}
            </div>
            
            }
            <div style={{width:'100%', background:'#F2F2F2',padding:10,display:'flex', flexWrap:'wrap'}}>
            {data && data.filter((itm:any)=>itm.requestType.startsWith('DSL')).map((item:any)=>{
                    return <TypeReport unread={item.unreaded} numberStatus={item.numberStatus} name={item.requestType} requests={item.numberRequest}></TypeReport>
            })}
            </div>

            {data && data.filter((itm:any)=>itm.requestType.startsWith('HTSL')).length>0 && <div style={{marginLeft:20,fontSize:'16px', fontWeight:'bold'}}>
                {geti18nText('dashboard.HTSL')}
            </div>
            
            }
            <div style={{width:'100%', background:'#F2F2F2',padding:10,display:'flex', flexWrap:'wrap'}}>
            {data && data.filter((itm:any)=>itm.requestType.startsWith('HTSL')).map((item:any)=>{
                    return <TypeReport unread={item.unreaded} numberStatus={item.numberStatus} name={item.requestType} requests={item.numberRequest}></TypeReport>
            })}
            </div>

            {dataDashboard && <div style={{marginLeft:20,fontSize:'16px', fontWeight:'bold'}}>
                {geti18nText('ht.chart.all.request')} {sendNotificationUnreaded && sendNotificationUnreaded>0&& <InboxOutlined onClick={()=> history.push({ pathname: '/information-ht', state: {selected: '/information-ht'}  })} className="ny-blink" style={{fontSize:'22px', color: 'green', marginLeft: '5px',  }} />}
            </div>}
            {dataDashboard && <div style={{marginLeft:20,fontSize:'12px', fontWeight:'bold',color:'#8181A5'}}>
                {geti18nText('ht.chart.all.request.last.30.days')}
            </div>}
            {dataDashboard &&  renderDashboardChart()}
            
            
        </div>
    );
};

export default Dashboard;
