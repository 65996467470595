import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    geti18nText,
    getColumnSearch,
    getColumnDateOption,
    getColumnSearchOption,
    ENUMS,
    NyUtils,
    NySession,
    NyRequestResolver,
    RESPONSE,
    NySearchField,
} from '@nybble/nyreact';
import { Button, Col, message, notification, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ, SettingsKey } from '../../utils/Constants';
import OltFreeCapacityAreaIndex from './edit';

const OltFreeCapacityDataIndex = () => {
    const { Option } = Select;
    const [selectedCounty, setSelectedCounty] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [selectedtLocOlt, setSelectedLocOlt] = useState<any>(null);

    useEffect(() => {
        setTableKey(Math.random().toString(36));
    }, [selectedCounty]);

    function setDefaultFilterValue() {
        if (selectedCounty && selectedCounty.id !== -1) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'zup_id', condition: 'equals', value: selectedCounty.id },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }


    function onRefreshClick(e: any) {
        setLoading(true);
        const params = { zup: selectedCounty.name };
        NyRequestResolver.requestPost(CONSTANTS_REQ.OLT_FREE_CAPACITY_DATA.DATA, undefined, {
            params: params,
            requestType: 39,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    }

    function closeDetailModal() {
        setSelectedLocOlt(null);
        setDetailsVisible(false);
    }

    function openDetailModal(record: { [index: string]: any }) {
        setSelectedLocOlt(record);
        setDetailsVisible(true);
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={6}>
                    <NySearchField
                        style={{width:'100%'}}
                        onChange={setSelectedCounty}
                        url={CONSTANTS_REQ.COUNTY.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                       
                        
                    />
                </Col>
                <Col>
                    <Button loading={loading} type="primary" disabled={selectedCounty == null} onClick={onRefreshClick}>
                        {geti18nText('distribution.node.header.refresh.btn')}
                    </Button>
                </Col>
            </Row>

            <NyDataTable
                key={tableKey}
                headerTitle={geti18nText('menu.main.olt.free.capacity.data')}
                url={CONSTANTS_REQ.OLT_FREE_CAPACITY_DATA.LIST}
                showRecordModal={true}
                hideNewButton={true}
                onRowSelect={openDetailModal}
                setDefaultPageSize={20}
                setDefaultFilterValue={setDefaultFilterValue}
                exportCSV={true}
                colCSVCustomization={[
                    {
                        county: (value: any) => {
                            if (value) {
                                return value.name;
                            }
                            return '';
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('app.default.active');
                            } else {
                                return geti18nText('app.default.inactive');
                            }
                        },
                    },
                    {
                        getDate: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale()) : '';
                        },
                    },
                ]}
                columns={[
                    {
                        title: geti18nText('distribution.node.table.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('distribution.node.table.zup'),
                        dataIndex: ['county','name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.county) {
                                return <div>{record.county.name}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('olt.free.capacity.data.lok.olt'),
                        dataIndex: 'lokOlt',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                     {
                        title: geti18nText('olt.free.capacity.data.grad'),
                        dataIndex: 'grad',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('olt.free.capacity.slob.kap'),
                        dataIndex: 'slobKap',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('distribution.node.table.get_date'),
                        dataIndex: 'getDate',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.getDate) {
                                return (
                                    <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                );
                            }
                        },
                    },

                    {
                        title: geti18nText('users.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                    },
                ]}
            />
            <OltFreeCapacityAreaIndex
                county={selectedtLocOlt?.county}
                loks={selectedtLocOlt?.lokOlt}
                zupId={selectedtLocOlt?.county?.id}
                visible={detailsVisible}
                closeModal={closeDetailModal}
            ></OltFreeCapacityAreaIndex>
        </>
    );
};

export default OltFreeCapacityDataIndex;
