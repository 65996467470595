import {
    geti18nText,
    NyDataEdit,
    NySession,
    NyMaskContent,
    NyRequestResolver,
    RESPONSE,
    NyDataTable,
    getColumnDateOption,
    NyUtils,
} from '@nybble/nyreact';
import {
    Col,
    Form,
    Input,
    Row,
    Tabs,
    Image,
    Descriptions,
    Tooltip,
    Button,
    Modal,
    InputNumber,
    notification,
    Checkbox,
    Collapse,
} from 'antd';

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../utils/Constants';

const { Panel } = Collapse;
const MailSettingsEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const [isCreated, setIsCreated] = useState(false);
    const [emailTest, setEmailTest] = useState(false);

    useEffect(() => {
        fetch();
    }, []);

    function setValues(dataForm: any) {
        form.setFieldsValue(dataForm);
    }
    const onFinish = (values: any) => {
        if (isCreated) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.SETTINGS.MAIL + '/' + values.id, undefined, {
                ...values,
                id: values.id,
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                }
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.MAIL, undefined, {
                ...values,
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                }
            });
        }
    };

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.MAIL).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (result.data != null) {
                    setIsCreated(true);
                    setValues(result.data);
                }
            }
            setLoading(false);
        });
    };

    const testMail = () => {
        const values = form.getFieldsValue();
        setEmailTest(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.SETTINGS.TEST_MAIL, undefined, { ...values }).then(
            (result: any) => {
                setEmailTest(false);
                if (result.status === RESPONSE.OK) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        description: result.data?.message,
                        duration: 5,
                    });
                }
                setLoading(false);
            }
        );
    };

    const validateMultipleEmails = (rule: any, value: any, callback: any) => {
        if (value == undefined || value == '') return callback();
        var regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/;
        return regex.test(value) ? callback() : callback(geti18nText('settings.mail.receivers.validation'));
    };

    return (
        <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={geti18nText('settings.tab.mail')} key="1">
                <Form form={form} onFinish={onFinish} labelCol={{ span: 7 }}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly={true} />
                        </Form.Item>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('settings.mail.host')}
                                name="host"
                            >
                                <Input disabled={emailTest} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('settings.mail.port')}
                                name="port"
                            >
                                <InputNumber min={1} disabled={emailTest} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label={geti18nText('settings.mail.username')} name="username">
                                <Input disabled={emailTest} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={geti18nText('settings.mail.password')} name="password">
                                <Input type="password" disabled={emailTest} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('settings.mail.sender'),
                                    },
                                ]}
                                label={geti18nText('settings.mail.sender')}
                                name="sender"
                            >
                                <Input disabled={emailTest} type="email" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Collapse collapsible="header" defaultActiveKey={'1'}>
                        <Panel header={geti18nText('settings.mail.collapse.title')} key="1">
                            {/* ostale postavke  */}
                            <Row>
                                <Col span={6}>
                                    <Form.Item name="smtpAuth" valuePropName="checked" noStyle>
                                        <Checkbox disabled={emailTest}>
                                            {geti18nText('settings.mail.smtp_auth')}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="startTls" valuePropName="checked" noStyle>
                                        <Checkbox disabled={emailTest}>
                                            {geti18nText('settings.mail.starttls')}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name="sslConnection" valuePropName="checked" noStyle>
                                        <Checkbox disabled={emailTest}>{geti18nText('settings.mail.ssl')}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col span={6}>
                                    <Form.Item name="socketFactoryFallback" valuePropName="checked" noStyle>
                                        <Checkbox disabled={emailTest}>
                                            {geti18nText('settings.mail.socket_factor_fallback')}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        labelAlign="left"
                                        label={geti18nText('settings.mail.socket_factor_port')}
                                        name="socketFactoryPort"
                                    >
                                        <InputNumber min={1} disabled={emailTest} />
                                    </Form.Item>
                                </Col>
                                <Col span={10} style={{ textAlign: 'left' }}>
                                    <Form.Item
                                        labelAlign="left"
                                        label={geti18nText('settings.mail.socket_factor_class')}
                                        name="socketFactoryClass"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>

                    <Row gutter={8}>
                        <Row style={{ textAlign: 'left', marginTop: '20px', marginLeft: '20px' }}>
                            <Form.Item style={{ marginRight: '20px' }}>
                                <Button type="primary" htmlType="submit">
                                    {geti18nText('settings.mail.btn.save')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="default" onClick={testMail}>
                                    {geti18nText('settings.mail.btn.test')}
                                </Button>
                            </Form.Item>
                        </Row>
                    </Row>
                </Form>
            </TabPane>
        </Tabs>
    );
};

export default MailSettingsEdit;
