import { CheckOutlined, CloseOutlined, InboxOutlined, ZoomInOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    geti18nText,
    getColumnSearch,
    getColumnDateOption,
    getColumnSearchOption,
    ENUMS,
    NyUtils,
    NySession,
    NyRequestResolver,
    RESPONSE,
    NySearchField,
} from '@nybble/nyreact';
import { Button, Col, Descriptions, message, notification, Popover, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ, SettingsKey } from '../../utils/Constants';
import DistributionNodeAreaIndex from './edit';
import DSLAMFreeCapacityAreaIndex from './edit';

const DSLAMFreeCapacityDataIndex = () => {
    const { Option } = Select;
    const [selectedCounty, setSelectedCounty] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [selectedLokDslam, setSelectedLokDslam] = useState<any>(null);

    useEffect(() => {
        setTableKey(Math.random().toString(36));
    }, [selectedCounty]);

    function setDefaultFilterValue() {
        if (selectedCounty) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'zup_id', condition: 'equals', value: selectedCounty },
            ];
        }
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    function onRefreshClick(e: any) {
        setLoading(true);
        const params = { zup: selectedCounty.name };
        NyRequestResolver.requestPost(CONSTANTS_REQ.DSLAM_FREE_CAPACITY_DATA.DATA, undefined, {
            params: params,
            requestType: 34,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setTableKey(Math.random().toString(36));
            } else {
                notification.error({
                    message: geti18nText('app.default.error'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    }

    function closeDetailModal() {
        setSelectedLokDslam(null);
        setDetailsVisible(false);
    }

    function openDetailModal(record: { [index: string]: any }) {
        setSelectedLokDslam(record.lokDslam);
        setDetailsVisible(true);
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={6}>
                    <NySearchField
                        style={{width:'100%'}}
                        onChange={setSelectedCounty}
                        url={CONSTANTS_REQ.COUNTY.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                       
                        
                    />
                </Col>
                <Col>
                    <Button loading={loading} type="primary" disabled={selectedCounty == null} onClick={onRefreshClick}>
                        {geti18nText('distribution.node.header.refresh.btn')}
                    </Button>
                </Col>
            </Row>

            <NyDataTable
                key={tableKey}
                headerTitle={geti18nText('dslam.free.capacity.data.table.header')}
                url={CONSTANTS_REQ.DSLAM_FREE_CAPACITY_DATA.LIST}
                showRecordModal={true}
                hideNewButton={true}
                onRowSelect={openDetailModal}
                setDefaultPageSize={20}
                setDefaultFilterValue={setDefaultFilterValue}
                exportCSV={false}
                colCSVCustomization={[
                    {
                        county: (value: any) => {
                            if (value) {
                                return value.name;
                            }
                            return '';
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('app.default.active');
                            } else {
                                return geti18nText('app.default.inactive');
                            }
                        },
                    },
                    {
                        getDate: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale()) : '';
                        },
                    },
                ]}
                columns={[
                    {
                        title: geti18nText('dslam.free.capacity.data.table.details'),
                        render: (text: any, record: any) => {
                            return <Popover placement='right' content={<div style={{width:'100%'}}>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>
                                    <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}>
                                    <p>{geti18nText('dslam.free.capacity.data.table.id')+': '}</p>
                                    <p>{record.id}</p>
                                    </div>
                                    <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}>
                                    <p >{geti18nText('dslam.free.capacity.data.table.zup')+': '}</p>
                                    <p>{record.county?.name}</p>
                                    </div>
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.lokDslam && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.lok')+': '}</p>
                                    <p>{record.lokDslam}</p></div>}
                                    {record.grad && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.grad')+': '}</p>
                                    <p>{record.grad}</p></div>}
                                    
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.proDslam && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p>{geti18nText('dslam.free.capacity.data.table.pro.dslam')+': '}</p>
                                    <p>{record.proDslam}</p></div>}
                                    {record.slobKap && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.slo.kap')+': '}</p>
                                    <p>{record.slobKap}</p></div>}
                                    
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.slobKapVdsl && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.slo.kap.vdsl')+': '}</p>
                                    <p>{record.slobKapVdsl}</p></div>}
                                    {record.slobKapVdslvekt && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p>{geti18nText('dslam.free.capacity.data.table.slo.kap.vdsl.vdslvekt')+': '}</p>
                                    <p>{record.slobKapVdslvekt}</p></div>}
                                    
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.slobKapVdslsvekt && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.slo.kap.vdsl.vdslsvekt')+': '}</p>
                                    <p>{record.slobKapVdslsvekt}</p></div>}
                                    {record.slobKapGfast && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.slo.kap.vdsl.gfast')+': '}</p>
                                    <p>{record.slobKapGfast}</p></div>}
                                    
                
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.datPlanProDslam && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p>{geti18nText('dslam.free.capacity.data.table.dat.plan.pro.dslam')+': '}</p>
                                    <p>{record.datPlanProDslam}</p></div>}
                                    {record.vrcvo && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.vrcvo')+': '}</p>
                                    <p>{record.vrcvo}</p></div>}
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>

                                    {record.tehsuc && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.tehsuc')+': '}</p>
                                    <p>{record.tehsuc}</p></div>}
                                    {record.napomena && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p>{geti18nText('dslam.free.capacity.data.table.napomena')+': '}</p>
                                    <p>{record.napomena}</p></div>}
                                    
                                </div>
                                <div style={{flexDirection:'row', display:'flex', width:'900px', flexWrap:'wrap', justifyContent: 'space-between'}}>
                                    {record.napajanje && <div style={{flexDirection:'row', display:'flex', flex: '1 0 50%'}}><p >{geti18nText('dslam.free.capacity.data.table.napajanje')+': '}</p>
                                    <p>{record.napajanje}</p></div>}
                                </div>
                              </div>}>
                                <ZoomInOutlined style={{fontSize:20 , color:'#1890ff', marginLeft: '5px' }}/>
                            </Popover>
                        },
                    },
                    {
                        title: geti18nText('dslam.free.capacity.data.table.zup'),
                        dataIndex: ['county','name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.county) {
                                return <div>{record.county.name}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('dslam.free.capacity.data.table.lok'),
                        dataIndex: 'lokDslam',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('dslam.free.capacity.data.table.grad'),
                        dataIndex: 'grad',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('dslam.free.capacity.data.table.pro.dslam'),
                        dataIndex: 'proDslam',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('dslam.free.capacity.data.table.slo.kap'),
                        dataIndex: 'slobKap',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('dslam.free.capacity.data.table.slo.kap.vdsl'),
                        dataIndex: 'slobKapVdsl',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                     {
                        title: geti18nText('dslam.free.capacity.data.table.vrcvo'),
                        dataIndex: 'vrcvo',
                        sorter: (a: any, b: any) => {},
                        ...getColumnDateOption(true, undefined, undefined, true),
                    },
                    {
                        title: geti18nText('distribution.node.table.get_date'),
                        dataIndex: 'getDate',
                        sorter: (a: any, b: any) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.getDate) {
                                return (
                                    <div>{new Date(record.getDate).toLocaleString(NyUtils.getSelectedLocale())}</div>
                                );
                            }
                        },
                    },
                ]}
            />
            <DSLAMFreeCapacityAreaIndex
                lokDslam={selectedLokDslam}
                visible={detailsVisible}
                closeModal={closeDetailModal}
            ></DSLAMFreeCapacityAreaIndex>
        </>
    );
};

export default DSLAMFreeCapacityDataIndex;
