import { geti18nText } from '@nybble/nyreact';
import { notification } from 'antd';

export const StatusNotification = (function () {
    type typeMessage = 'success' | 'info' | 'error' | 'warning';

    return {
        notify: (code: number, message: string) => {
            switch (code) {
                case 0:
                    StatusNotification.openNotification('success', geti18nText('message.STATUS_' + code), '');
                    break;
                case 1:
                    StatusNotification.openNotification('error', geti18nText('message.STATUS_' + code), '', 0);
                    break;
                case 2:
                    StatusNotification.openNotification('error', geti18nText('message.STATUS_' + code), message, 0);
                    break;
                case -1:
                    StatusNotification.openNotification('error', geti18nText('message.STATUS_' + code), '', 0);
                    break;

                default:
                    break;
            }
        },

        openNotification: (type: typeMessage, message: string, description: string, duration?: number) => {
            notification[type]({
                duration: duration,
                message: message,
                description: description,
            });
        },
    };
})();
