import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ConsoleSqlOutlined,
    DownloadOutlined,
    InfoCircleOutlined,
    MinusCircleOutlined,
    SearchOutlined,
    StopOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import {
    Button,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Popover,
    Row,
    Tag,
    Timeline,
    Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ, SettingsKey, WsMethods } from '../../utils/Constants';
import { StatusNotification } from '../../utils/StatusNotification';
import WsRequestDetails from '../ws-request/edit';
import { GetParameterValue } from '../../utils/Utils';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
const { Text } = Typography;

export const HtStatus: { [index: string]: any } = {
    PRIMLJEN: { color: 'green', icon: <CheckCircleOutlined /> },
    PRIHVAĆEN: { color: 'green', icon: <CheckCircleOutlined /> },
    REALIZIRAN: { color: 'green', icon: <CheckCircleOutlined /> },
    RIJEŠEN: { color: 'green', icon: <CheckCircleOutlined /> },
    ODBIJEN: { color: 'orange', icon: <StopOutlined /> },
    STORNO: { color: 'gold', icon: <MinusCircleOutlined /> },
    NEDOZVOLJEN: { color: 'red', icon: <CloseCircleOutlined /> },
    INFO: { color: 'blue', icon: <InfoCircleOutlined /> },
    ODGOĐEN: { color: 'geekblue', icon: <ClockCircleOutlined /> },
};

const ReportFaultDetails = React.forwardRef((props: any, ref: any) => {
    const [guuid, setGuuid] = useState(props.match.params.guuid);
    const [requestType, setRequestType] = useState(null);
    const [statusType, setStatusType] = useState(null);
    const [data, setData] = useState<any>(null);
    const [createOrderData, setCreateOrderData] = useState<any>(null);
    const [location, setLocation] = useState<any>(null);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { Title, Link } = Typography;
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [wsRequestId, setWsRequestId] = useState(0);
    const [loadingFile, setLoadingFile] = useState(false);
    const [printPdf, setPrintPdf] = useState(true);
    const [modalViewActivity, setModalViewActivity] = useState(false);
    const [escalationModalVisible, setEscalationModalVisible] = useState(false);

    let history = useHistory();

    useEffect(() => {
        fetchData();
    }, []);

    const stornoNotAllowedStatus = () => {
        return ['ODBIJEN', 'STORNO', 'NEDOZVOLJEN', 'REALIZIRAN'];
    };
    const escalationNotAllowedStatus = () => {
        return [ 'STORNO', 'RIJEŠEN'];
    };

    function fetchData() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.REPORT_FAULT.DETAILS + '/' + guuid).then((result) => {
            if (result.status === RESPONSE.OK) {
                const responseData: { [index: string]: any } | Object | undefined | any = result.data;
                setData(responseData);
                setRequestType(responseData[0].requestType);
                setStatusType(responseData[0].statusType);
                setCreateOrderData(responseData[0]);
                setLoading(false);
            }
        });
    }

    function renderCreateOrderParam() {
        function getParamValues() {
            let params: any[] = [];

            if (createOrderData.reportFaultParam) {
                const types = NySession.getAppValue(SettingsKey.REQ_PARAM_TYPE) as [];
                (NySession.getAppValue(SettingsKey.REQ_PARAM_TYPE) as []).forEach((param: any) => {
                    if (
                        param.typeId === requestType &&
                        param.wsMethod === WsMethods.REPORT_FAULT &&
                        createOrderData.reportFaultParam.hasOwnProperty(param.name)
                    ) {
                        let paramValue: any = {};
                        paramValue.text = geti18nText('params.' + requestType + '.' + param.name);
                        paramValue.value = GetParameterValue(
                            createOrderData.reportFaultParam[param.name],
                            param.dataType,
                            param.allowedValuesAlternate?param.allowedValuesAlternate: param.allowedValues
                        );
                        params.push(paramValue);
                    }
                });
            }

            return params;
        }

        return (
            <>
                <Descriptions.Item key={'guuid'} label={geti18nText('ws.request.edit.guuid')}>
                    <b>{guuid}</b>
                </Descriptions.Item>

                {getParamValues().map((obj: any, index: number) => {
                    return (
                        <Descriptions.Item key={index} label={obj.text}>
                            <b>{obj.value}</b>
                        </Descriptions.Item>
                    );
                })}

                {createOrderData.wsRequestAttachment && (
                    <Descriptions.Item label={geti18nText('ws.request.details.attachments')}>
                        {createOrderData.wsRequestAttachment.map((obj: any) => {
                            return (
                                <>
                                    <div>
                                        {loadingFile && <NySpinner />}
                                        <Link onClick={() => fileDownload(obj.id, obj.fileName)}>{obj.fileName}</Link>
                                    </div>
                                    {createOrderData.wsRequestAttachment.size > 1 ? <br /> : null}
                                </>
                            );
                        })}
                    </Descriptions.Item>
                )}
                {renderCreateStatus()}
            </>
        );
    }

    function renderCreateStatus() {
        if (createOrderData.statusType) {
            return (
                <>
                    <Descriptions.Item key={'status'} label={geti18nText('request.details.status')}>
                        <Tag
                            color={
                                HtStatus[createOrderData.statusType]
                                    ? HtStatus[createOrderData.statusType].color
                                    : undefined
                            }
                            icon={
                                HtStatus[createOrderData.statusType]
                                    ? HtStatus[createOrderData.statusType].icon
                                    : undefined
                            }
                        >
                            {' '}
                            {createOrderData.statusType}{' '}
                        </Tag>
                    </Descriptions.Item>
                    {createOrderData.statusDate && (
                        <Descriptions.Item key={'stDate'}>
                            {moment(createOrderData.statusDate).format('DD.MM.YYYY HH:mm:ss')}
                        </Descriptions.Item>
                    )}
                    {createOrderData.statusDescription && (
                        <Descriptions.Item key={'stDesc'}>{createOrderData.statusDescription}</Descriptions.Item>
                    )}
                </>
            );
        } else if (data[data.length - 1].responseCode !== 0) {
            return (
                <Descriptions.Item key={'statusError'} label={geti18nText('request.details.status')}>
                    <Tag color="red" icon={<WarningOutlined />}>
                        {' '}
                        {geti18nText('ws.request.edit.error')}{' '}
                    </Tag>
                </Descriptions.Item>
            );
        } else if (createOrderData.responseCode === 0 && !createOrderData.statusType) {
            return (
                <>
                    <Descriptions.Item key={'statusError'} label={geti18nText('request.details.status')}>
                        <Tag color="blue" icon={<ClockCircleOutlined />}>
                            {' '}
                            {geti18nText('ws.request.edit.status.wait.for.ht')}{' '}
                        </Tag>
                    </Descriptions.Item>
                </>
            );
        }
    }

    function renderParams(value: any) {
        if (value.requestMethod === WsMethods.REPORT_FAULT) {
            if (value.reportFaultParam) {
                return Object.keys(value.reportFaultParam).map((obj: any) => {
                    return (
                        <Descriptions.Item key={obj} label={geti18nText('params.' + value.requestType + '.' + obj)}>
                            <div>
                                <b>{value.reportFaultParam[obj]}</b>
                                <br />
                            </div>
                        </Descriptions.Item>
                    );
                });
            }
        } else if (value.requestMethod === WsMethods.SEND_FAULT_STATUS) {
            if (value.sendFaultStatusParam) {
                return Object.keys(value.sendFaultStatusParam)
                    .filter((obj: any) => {
                        return obj !== 'id';
                    })
                    .map((obj: any) => {
                        return (
                            <Descriptions.Item
                                key={obj}
                                label={geti18nText('params.' + value.requestMethod + '.' + obj)}
                            >
                                <div>
                                    <b>{value.sendFaultStatusParam[obj]}</b>
                                    <br />
                                </div>
                            </Descriptions.Item>
                        );
                    });
            }
        } else {
            return <div></div>;
        }
    }

    function errorContent(el: any) {
        return (
            <Descriptions bordered={printPdf === true}>
                <Descriptions.Item
                    style={{ background: '#cfcfcf' }}
                    span={3}
                    label={geti18nText('request.details.error.id')}
                >
                    {el.responseId ? el.responseId : geti18nText('request.details.errors.no.data')}
                </Descriptions.Item>
                <Descriptions.Item
                    style={{ background: '#cfcfcf' }}
                    span={3}
                    label={geti18nText('request.details.error.message')}
                >
                    {el.responseMessage ? el.responseMessage : geti18nText('request.details.errors.no.data')}
                </Descriptions.Item>
                <Descriptions.Item
                    style={{ background: '#cfcfcf' }}
                    span={3}
                    label={geti18nText('request.details.error.trace')}
                >
                    {el.responseTrace ? el.responseTrace : geti18nText('request.details.errors.no.data')}
                </Descriptions.Item>
            </Descriptions>
        );
    }

    function renderItems() {
        return data
            .filter((d: any) => {
                return d.id !== createOrderData.id;
            })
            .map((el: any) => {
                return (
                    <Timeline.Item
                        key={el.id}
                        color={el.requestFromHt ? 'gray' : 'blue'}
                        dot={
                            el.requestFromHt ? (
                                <ArrowLeftOutlined style={{ fontSize: '22px' }} />
                            ) : (
                                <ArrowRightOutlined style={{ fontSize: '22px' }} />
                            )
                        }
                        label={moment(el.timeGenerated).format('DD.MM.YYYY HH:mm:ss')}
                    >
                        <Row justify={'end'}>
                            {el.responseCode !== 0 && (
                                <Descriptions.Item span={2} label={geti18nText('request.details.status')}>
                                    {
                                        <Popover
                                            placement="bottom"
                                            content={errorContent(el)}
                                            title={
                                                el.responseCode +
                                                ' : ' +
                                                geti18nText(
                                                    NySession.getAppValue(SettingsKey.STATUS_CODE)[el.responseCode]
                                                )
                                            }
                                        >
                                            <Tag icon={<CloseCircleOutlined />} color="error">
                                                {geti18nText('ws.request.edit.error')}
                                            </Tag>
                                        </Popover>
                                    }
                                </Descriptions.Item>
                            )}
                            {
                                <div onClick={() => orderDetails(el.id)}>
                                    <Text
                                        strong
                                        style={{
                                            cursor: 'pointer',
                                            color:
                                                el.requestFromHt && el.responseCode === 0
                                                    ? 'grey'
                                                    : el.requestFromHt && el.responseCode !== 0
                                                    ? '#cd201f'
                                                    : !el.requestFromHt && el.responseCode !== 0
                                                    ? '#cd201f'
                                                    : '#1494e3',
                                        }}
                                        underline
                                    >
                                        { el.requestType === 'ESCL' ? geti18nText('request.method.ESCL') : geti18nText('request.method.' + el.requestMethod)}
                                    </Text>
                                </div>
                            }
                        </Row>
                        <Row>
                            <Descriptions
                                column={1}
                                style={{ textAlign: 'left' }}
                                bordered={printPdf === true}
                                size={'small'}
                            >
                                {el.statusTypeRow && (
                                    <Descriptions.Item key={'status'} label={geti18nText('request.details.status')}>
                                        <Tag
                                            color={
                                                HtStatus[el.statusTypeRow]
                                                    ? HtStatus[el.statusTypeRow].color
                                                    : undefined
                                            }
                                            icon={
                                                HtStatus[el.statusTypeRow] ? HtStatus[el.statusTypeRow].icon : undefined
                                            }
                                        >
                                            {' '}
                                            {el.statusTypeRow}{' '}
                                        </Tag>
                                        {el.statusDate && moment(el.statusDate).format('DD.MM.YYYY HH:mm:ss')}
                                    </Descriptions.Item>
                                )}
                                {el.statusDescription && (
                                    <Descriptions.Item
                                        key={'stDesc'}
                                        label={geti18nText('request.details.status.description')}
                                    >
                                        {el.statusDescription}
                                    </Descriptions.Item>
                                )}
                                {el.username && (
                                    <Descriptions.Item key={'username'} label={geti18nText('ws.request.edit.user')}>
                                        <b>{el.username}</b>
                                    </Descriptions.Item>
                                )}
                                {renderAdditionalData(el)}
                                {(el.sendFaultStatusParam || el.reportFaultParam) && renderParams(el)}
                                {el.wsRequestAttachment && (
                                    <Descriptions.Item label={geti18nText('ws.request.details.attachments')}>
                                        {el.wsRequestAttachment.map((obj: any) => {
                                            return (
                                                <>
                                                    <div>
                                                        {loadingFile && <NySpinner />}
                                                        <Link onClick={() => fileDownload(obj.id, obj.fileName)}>
                                                            {obj.fileName}
                                                        </Link>
                                                    </div>
                                                    {el.wsRequestAttachment.size > 1 ? <br /> : null}
                                                </>
                                            );
                                        })}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </Row>
                        <Divider></Divider>
                    </Timeline.Item>
                );
            });
    }

    function orderDetails(id: any) {
        setDetailsVisible(true);
        setWsRequestId(id);
    }

    
    function escalationConfirm() {
        form.validateFields().then((val:any)=>{ 
            if(val && val.telBr) {
                setEscalationModalVisible(false);
                let escalationData = {
                    guuid: guuid,
                    phoneNumber: form.getFieldValue('telBr'),
                    desc: form.getFieldValue('descFault'),
                };
                setLoading(true);
                NyRequestResolver.requestPost(
                    CONSTANTS_REQ.REPORT_FAULT.ESCALATION,
                    undefined,
                    escalationData
                ).then((response: any) => {
                    if (response.status === RESPONSE.OK) {
                        StatusNotification.notify(response.data.code, response.data.message);
                        fetchData();
                        form.resetFields();
                    } else {
                        StatusNotification.notify(-1, '');
                        fetchData();
                        form.resetFields();
                    }
                    setLoading(false);
                });
            }

        })

        
    }

  
    function cancelEscalation() {
        setEscalationModalVisible(false);
    }

    function renderAdditionalData(el: any) {
        if (el.requestMethod === WsMethods.CANCEL_ORDER) {
            return (
                <Descriptions.Item key={el.id} label={geti18nText('request.details.modal.cancel.order.note')}>
                    {el.note && (
                        <div>
                            <b>{el.note}</b>
                            <br />
                        </div>
                    )}
                </Descriptions.Item>
            );
        }
    }

    function renderButton() {
        return (
            <>
                {actionsButtons().map((button: any) => {
                    return (
                        <Button
                            key={button.key}
                            hidden={button.hidden}
                            style={button.style}
                            onClick={() => button.action()}
                            type={button.type}
                            icon={button.icon}
                        >
                            {button.title}
                        </Button>
                    );
                })}
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button
                                type={'primary'}
                                key={'print'}
                                style={{ marginLeft: '10px' }}
                                icon={<DownloadOutlined />}
                            >
                                {geti18nText('request.details.btn.save.pdf')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(false);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(true);
                    }}
                    content={() => componentRef.current}
                />
                <Button
                    type={'primary'}
                    key={'view'}
                    style={{ marginLeft: '125px' }}
                    onClick={() => {
                        setModalViewActivity(true);
                    }}
                    icon={<SearchOutlined />}
                >
                    {geti18nText('request.log.btn.title')}
                </Button>
            </>
        );
    }

    function goBack() {
        history.goBack();
    }

    const actionsButtons = () => {
        return [
            {
                type: 'primary',
                title: geti18nText('request.details.btn.back'),
                action: () => goBack(),
                key: 'back',
            },
            {
                type: 'danger',
                hidden:
                    data.length !== 1 &&
                    createOrderData.statusType &&
                    stornoNotAllowedStatus().includes(createOrderData.statusType),
                style: { marginLeft: '10px' },
                title: geti18nText('request.details.btn.cancel.order'),
                action: () => setCancelModalVisible(true),
                key: 'cancelOrder',
            },
            {
                type: 'primary',
                 hidden:
                    (data.length !== 1 &&
                    createOrderData.statusType &&
                    escalationNotAllowedStatus().includes(createOrderData.statusType)) || createOrderData.requestType !== 'SFA',
                style: { marginLeft: '10px' },
                title: geti18nText('request.details.btn.escalation'),
                action: () => setEscalationModalVisible(true),
                key: 'escalation',
            },
        ];
    };

    function cancelOrderConfirm() {
        setCancelModalVisible(false);
        let cancelOrderRequestType = {
            guid: guuid,
            operator: { id: data[0].operatorId, name: data[0].operatorName },
            type: requestType,
            note: form.getFieldValue('note'),
        };
        setLoading(true);
        NyRequestResolver.requestPost(
            CONSTANTS_REQ.RECIEVING_REQUEST.CANCEL_ORDER,
            undefined,
            cancelOrderRequestType
        ).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                StatusNotification.notify(response.data.code, response.data.message);
                fetchData();
                form.resetFields();
            } else {
                StatusNotification.notify(-1, '');
                fetchData();
                form.resetFields();
            }
            setLoading(false);
        });
    }

    function cancelOrderCancel() {
        setCancelModalVisible(false);
    }
    function closeDetailModal() {
        setDetailsVisible(false);
    }

    const fileDownload = (attachmentId: any, fileName: String) => {
        setLoadingFile(true);

        NyRequestResolver.requestGet(
            CONSTANTS_REQ.WS_REQUEST.DOWNLOAD_FILE,
            { attachmentId: attachmentId },
            false,
            true
        ).then((result: { [index: string]: any }) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                NyUtils.downloadFile(result);
            } else {
                message.error(geti18nText('app.default.error'));
            }
            setLoadingFile(false);
        });
    };

    const componentRef = useRef<any>();

    function onCancelDeviceModal() {
        setModalViewActivity(false);
    }

    const columns: any = [
        {
            title: geti18nText('log.request.table.column.user'),
            dataIndex: ['userCreated', 'username'],
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('log.request.table.column.created'),
            dataIndex: 'created',
            width: '5%',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.created) {
                    return <div>{new Date(record.created).toLocaleString(NyUtils.getSelectedLocale())}</div>;
                }
            },
            ...getColumnDateOption(true),
        },
    ];

    function setDefaultFilterValue() {
        return [{ field: 'ws_request.id', condition: 'equals', value: data[0].id }];
    }

    return (
        <>
            {' '}
            {loading ? (
                <NySpinner />
            ) : (
                data && (
                    <>
                        <div ref={componentRef}>
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <Title level={4}>
                                        {geti18nText('request.type.' + requestType) + ' - ' + requestType}
                                    </Title>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '35px' }}>
                                <Descriptions
                                    column={1}
                                    style={{ textAlign: 'left' }}
                                    bordered={printPdf === true}
                                    size={'small'}
                                >
                                    {createOrderData && renderCreateOrderParam()}
                                </Descriptions>
                            </Row>

                            <Row style={{ marginTop: '10px' }}>{printPdf === true && renderButton()}</Row>
                            <Divider></Divider>

                            <Timeline mode="right">
                                {createOrderData && (
                                    <Timeline.Item
                                        key={createOrderData.id}
                                        color={'blue'}
                                        dot={<ArrowRightOutlined style={{ fontSize: '22px' }} />}
                                        label={moment(createOrderData.timeGenerated).format('DD.MM.YYYY HH:mm:ss')}
                                    >
                                        <div onClick={() => orderDetails(createOrderData.id)}>
                                            <Text strong style={{ color: '#1494e3', cursor: 'pointer' }} underline>
                                                {geti18nText('request.method.' + createOrderData.requestMethod)}
                                            </Text>
                                        </div>
                                        {createOrderData.username && (
                                            <Row>
                                                <Descriptions
                                                    column={1}
                                                    style={{ textAlign: 'left' }}
                                                    bordered={printPdf === true}
                                                    size={'small'}
                                                >
                                                    <Descriptions.Item
                                                        key={'username'}
                                                        label={geti18nText('ws.request.edit.user')}
                                                    >
                                                        <b>{createOrderData.username}</b>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Row>
                                        )}
                                        <Divider></Divider>
                                    </Timeline.Item>
                                )}
                                {data && renderItems()}
                            </Timeline>

                            <Modal
                                closable={true}
                                title={geti18nText('request.details.btn.cancel.order') + ': ' + data[0].guuid}
                                footer={
                                    <>
                                        <Button type="primary" onClick={() => setCancelModalVisible(false)}>
                                            {geti18nText('app.default.button.cancel')}
                                        </Button>

                                        <Button type="primary" danger onClick={() => cancelOrderConfirm()}>
                                            {geti18nText('request.details.btn.cancel.order')}
                                        </Button>
                                    </>
                                }
                                visible={cancelModalVisible}
                                onCancel={cancelOrderCancel}
                            >
                                <Form form={form}>
                                    <Form.Item
                                        label={geti18nText('request.details.modal.cancel.order.note')}
                                        name="note"
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Modal
                                closable={true}
                                title={geti18nText('request.details.btn.escalation') + ': ' + data[0].guuid}
                                footer={
                                    <>
                                        <Button type="primary" onClick={() => setEscalationModalVisible(false)}>
                                            {geti18nText('app.default.button.cancel')}
                                        </Button>

                                        <Button type="primary" danger onClick={() => escalationConfirm()}>
                                            {geti18nText('request.details.btn.escalation')}
                                        </Button>
                                    </>
                                }
                                visible={escalationModalVisible}
                                onCancel={cancelEscalation}
                            >
                                <Form form={form}>
                                    <Form.Item
                                        label={geti18nText('request.details.modal.escalation.telbr')}
                                        name="telBr"
                                        rules={ [ {required: true, message: geti18nText('recieving.request.mandatory.field') }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label={geti18nText('request.details.modal.escalation.fault.desc')}
                                        name="descFault"
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <WsRequestDetails visible={detailsVisible} id={wsRequestId} closeModal={closeDetailModal} />
                            <Modal
                                visible={modalViewActivity}
                                title={geti18nText('request.log.title')}
                                width={900}
                                footer={null}
                                onCancel={onCancelDeviceModal}
                                destroyOnClose={true}
                            >
                                <NyDataTable
                                    url={CONSTANTS_REQ.LOG_REQUEST.LIST}
                                    showRecordModal={false}
                                    hideButtons={true}
                                    setDefaultFilterValue={setDefaultFilterValue}
                                    columns={columns}
                                    setDefaultPageSize={20}
                                />
                            </Modal>
                        </div>
                    </>
                )
            )}
        </>
    );
});

export default ReportFaultDetails;
