import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    geti18nText,
    NySession,
    getColumnSearch,
    getColumnDateOption,
    getColumnSearchOption,
    ENUMS,
} from '@nybble/nyreact';
import { table } from 'console';
import React from 'react';
import UserEdit from '.';
import { CONSTANTS_REQ } from '../../utils/Constants';

const UserIndex = (props: any) => {
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('users.table.header')}
            url={CONSTANTS_REQ.USER.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('users.table.add')}
            modalComponent={UserEdit}
            setDefaultPageSize={20}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[...[
                {
                    title: geti18nText('users.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('users.table.column.username'),
                    dataIndex: 'username',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.last_name'),
                    dataIndex: 'firstName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.first_name'),
                    dataIndex: 'lastName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                },
                
            ],...NySession.hasAnyRole(['ROLE_ADMIN'])?[
                {
                    title: geti18nText('users.table.column.tenant'),
                    dataIndex: ['tenant','name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
            ]:[]
            
            ,...[
                {
                    title: geti18nText('users.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]
            ]
    
    }
        />
    );
};

export default UserIndex;
