import { getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText, NyDataEdit, NyDataTable, NyRequestResolver, NySearchField, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Modal, Form, Row, Col, Input, Divider, notification, Typography } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const NbsaReport = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('user.table.header.new'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [userItem, setUserItem] = useState<any>(null);
    const [passwordValidateModal, setPasswordValidateModal] = useState(false);
    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('user.table.header.new'));
    };
    const [tableKey, setTableKey] = useState<any>(Math.random().toString(36));
    const { Title } = Typography;

    const realizedStatus = (status = undefined) => {
        let types:any = [
            {
                id : 0,
                text : geti18nText('ws.request.status.unrealized')
            },
            {
                id : 1,
                text : geti18nText('ws.request.status.realized')
            },
        ];
       
        return types;
    };

    const columns: any = [
        
        {
            title: geti18nText('review.request.response.table.column.guuid'),
            dataIndex: 'guuid',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch(
                'string',
                
            ),
            
        },
        {
            title: geti18nText('menu.reports.NBSA.adrs'),
            dataIndex: 'adrs',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch(
                'string',
                
            ),
            
        },
        {
            title: geti18nText('menu.reports.NBSA.construction.fiber.optic.installation'),
            dataIndex: 'svki',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch(
                'string',
                
            ),
            
        },
    ];

    const setDefaultFilterValue = () => {
        return [{ field: 'responseCode', condition: 'equals', value: '0' },
            {field:"latestStatus",condition:"equals",value:"REALIZIRAN"}
        ];
    };

    return (
        <>
        <Title level={4}>
                {geti18nText('menu.reports.NBSA')}
            </Title>
        <NyDataTable
                key={tableKey}
                url={CONSTANTS_REQ.WS_REQUEST.LIST_CREATE}
                showRecordModal={false}
                hideButtons={true}
                addedData={{type: 'NBSA'}}
                columns={columns}
                exportCSV
                CSVFileName='NBSA_report'
                
               // setDefaultPageSize={state && history.action !== 'PUSH' && state.max ? state.max : 20}
                //setDefaultSortOrder={sortDefault()}
                setDefaultFilterValue={setDefaultFilterValue}
                //onRowSelect={onRowClikcHandler}
                //onDataLoaded={onDataLoaded}
                
            />
        </>
    );
};

export default NbsaReport;
