import {
    geti18nText,
    NyDataTable,
    getColumnSearch,
    NyUtils,
    NySession,
    NyRequestResolver,
    RESPONSE,
    NySpinner,
} from '@nybble/nyreact';
import { Col, Tabs, Row, Typography, message, Form, Button, notification, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ, SettingsKey } from '../utils/Constants';
import NyFileUpload from '../components/file-upload';

const CertManagment = () => {
    const [refreshTable, setRefreshTable] = useState(0);
    const [visibleModalOperatorCert, setVisibleModalOperatorCert] = useState(false);
    const [selectedOperaterCert, setSelectedOperaterCert] = useState<any>('');
    const [selectedTenant, setSelectedTenant] = useState<any>(undefined);
    const [selectedTenantCode, setSelectedTenantCode] = useState<any>(undefined);
    const { TabPane } = Tabs;
    const { Title } = Typography;

    const tabChanged = (activeKey: any) => {
        if (activeKey == '1') {
            setRefreshTable(Date.now());
        }
    };

    return (
        <>
            <Title level={4}>{geti18nText('cert.managment.title')}</Title>
            <Row gutter={24} style={{ marginTop: '35px' }}>
                <Col span={24}>
                    <Tabs type="card" onChange={tabChanged}>
                        <TabPane tab={geti18nText('cert.managment.tab.history')} key="1">
                            <NyDataTable
                                headerTitle={geti18nText('cert.managment.table.header')}
                                url={CONSTANTS_REQ.CERT.AUDIT}
                                readonly={true}
                                hideButtons={true}
                                fetchWhenChange={refreshTable}
                                setDefaultPageSize={20}
                                columns={[
                                    {
                                        title: geti18nText('cert.managment.table.column.id'),
                                        dataIndex: 'id',
                                        width: '5%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('cert.managment.table.column.username'),
                                        dataIndex: 'username',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('cert.tab.operater.tenant'),
                                        dataIndex: ['tenant','code'],
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('cert.managment.table.column.action'),
                                        dataIndex: 'action',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                        render: (text: string, record: { [index: string]: any }) => {
                                            return (
                                                <div>
                                                    {geti18nText(
                                                        'cert.action.' +
                                                            NySession.getAppValue(SettingsKey.CERT_ACTIONS)[
                                                                record.action
                                                            ]
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: geti18nText('cert.managment.table.column.time'),
                                        dataIndex: 'timeGenerated',
                                        sorter: (a: any, b: any) => {},
                                        render: (text: string, record: { [index: string]: any }) => {
                                            if (record.timeGenerated) {
                                                return <div>{new Date(record.timeGenerated).toLocaleString(NyUtils.getSelectedLocale())}</div>;
                                            }
                                        },
                                    },
                                    {
                                        title: geti18nText('cert.table.column.validTo'),
                                        dataIndex: 'validTo',
                                        sorter: (a: any, b: any) => {},
                                        render: (text: string, record: { [index: string]: any }) => {
                                            if (record.validTo) {
                                                return <div>{new Date(record.validTo).toLocaleString(NyUtils.getSelectedLocale())}</div>;
                                            }
                                        },
                                    },
                                ]}
                            />
                        </TabPane>
                        {/* <TabPane tab={geti18nText('cert.managment.tab.operater')} key="2">
                            <CertDetails type={NySession.getAppValue('CERT_ACTIONS').ImportOperatorCert} />
                        </TabPane> */}
                        <TabPane tab={geti18nText('cert.managment.tab.ht')} key="3">
                            <CertDetails  type={NySession.getAppValue('CERT_ACTIONS').ImportHTCert} />
                        </TabPane>
                        {/* <TabPane tab={geti18nText('cert.managment.tab.operater.ssl')} key="4">
                            <CertDetails type={NySession.getAppValue('CERT_ACTIONS').ImportOperatorSSLCert} />
                        </TabPane> */}
                        <TabPane tab={geti18nText('cert.managment.tab.ht.ssl')} key="5">
                            <CertDetails  type={NySession.getAppValue('CERT_ACTIONS').ImportHTSSLCert} />
                        </TabPane>
                        <TabPane tab={geti18nText('cert.tab.operater')} key="6">
                        <NyDataTable
                            headerTitle={geti18nText('cert.tab.operater.header')}
                            url={CONSTANTS_REQ.TENANT.LIST}
                            showRecordModal={false}
                            hideButtons
                            readonly
                            setDefaultPageSize={20}
                            setDefaultFilterValue={()=>[{ field: 'active', condition: 'equals_bool', value: 1 }]}
                            columns={[
                                {
                                    title: geti18nText('client.edit.name'),
                                    dataIndex: 'name',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('client.edit.code'),
                                    dataIndex: 'code',
                                    sorter: (a: any, b: any) => {},
                                    ...getColumnSearch('string'),
                                },
                                {
                                    title: geti18nText('cert.tab.operater.column.actions'),
                                    render: (text: string, record: { [index: string]: any }) => {
                                        return (
                                            <div>
                                                <>
                                                    <Button type="primary" onClick={()=>{
                                                        setSelectedTenant(record.name)
                                                        setSelectedTenantCode(record.code)
                                                        setSelectedOperaterCert(NySession.getAppValue('CERT_ACTIONS').ImportOperatorCert)
                                                        setVisibleModalOperatorCert(true)
                                                    }}>
                                                        {geti18nText('cert.tab.operater.column.actions.application.cert')}
                                                    </Button>
                                                    <Button type="primary" onClick={()=>{
                                                        setSelectedTenant(record.name)
                                                        setSelectedTenantCode(record.code)
                                                        setSelectedOperaterCert(NySession.getAppValue('CERT_ACTIONS').ImportOperatorSSLCert)
                                                        setVisibleModalOperatorCert(true)
                                                    }} style={{marginLeft:'30px'}}>
                                                        {geti18nText('cert.tab.operater.column.actions.ssl.cert')}
                                                    </Button>
                                                </>
                                            </div>
                                        );
                                    },
                                }
                            ]}
                        />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Modal maskClosable={false} destroyOnClose okText='' footer={<></>} cancelText='' onCancel={()=>{setVisibleModalOperatorCert(false); setSelectedOperaterCert(''); setSelectedTenant(undefined)}}  visible={visibleModalOperatorCert}>
            <CertDetails name={selectedTenant} code={selectedTenantCode} type={selectedOperaterCert} />
            </Modal>
        </>
    );
};

interface ICertDetails {
    type: string;
    name?:string;
    code?:string
}

const CertDetails = ({ type,name,code }: ICertDetails) => {
    const [loading, setLoading] = useState(false);
    const [certDetails, setCertDetails] = useState('');
    const [files, setFiles] = useState<any>([]);
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [form] = Form.useForm();

    useEffect(() => {
        setLoading(true);
        setCertDetails('');
        console.log(name)
        NyRequestResolver.requestGet(CONSTANTS_REQ.CERT.DETAILS, { type: type, code: code ? code : undefined}).then(
            (result: { [index: string]: any }) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    setCertDetails(result.data.certDetails);
                } else {
                    message.error(geti18nText('app.default.error'));
                }
            }
        );
    }, [uploadKey]);

    const onFinish = (values: any) => {
        setLoading(true);
        if (files.length === 1) {
            const formData = new FormData();
            formData.append('type', type);
            formData.append('pass', values.pass);
            if(code) formData.append('tenantCode', code);
            files.forEach((file: any) => {
                formData.append('file', file, file.name);
            });
            NyRequestResolver.requestPostFile(CONSTANTS_REQ.CERT.UPLOAD, undefined, formData).then((response: any) => {
                setLoading(false);
                if (response.status == RESPONSE.OK) {
                    form.resetFields();
                    setUploadKey(Date.now());
                    setFiles([]);
                    notification['success']({
                        message: geti18nText('cert.managment.success'),
                        duration: 5,
                    });
                } else {
                    notification['error']({
                        message: geti18nText('cert.managment.error'),
                    });
                }
            });
        }
    };

    const stopServices=(code?:string)=>{
        let data:any={}
        if(code){
            data.tenantCode=code
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.CERT.STOP_SERVICE, undefined, data).then((response: any) => {
            setLoading(false);
            if (response.status == RESPONSE.OK) {
            
                notification['success']({
                    message: geti18nText('app.default.save.ok'),
                    duration: 5,
                });
            } else {
                notification['error']({
                    message: geti18nText('app.default.save.nok'),
                });
            }
        });
    }
    const startServices=(code?:string)=>{
        let data:any={}
        if(code){
            data.tenantCode=code
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.CERT.START_SERVICE, undefined, data).then((response: any) => {
            setLoading(false);
            if (response.status == RESPONSE.OK) {
            
                notification['success']({
                    message: geti18nText('app.default.save.ok'),
                    duration: 5,
                });
            } else {
                notification['error']({
                    message: geti18nText('app.default.save.nok'),
                });
            }
        });
    }

    return (
        <>
            <Form form={form} name="control-ref" onFinish={onFinish}>
                {name && <Row>{name}</Row>}
                <Row>
                    <Col style={{ marginTop: '15px' }}>
                        <Form.Item label={geti18nText('cert.managment.form.file.label')}>
                            <NyFileUpload key={uploadKey} files={files} setFiles={setFiles} multiple={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginTop: '15px' }}>
                        <Form.Item label={geti18nText('cert.managment.form.file.password')} name={'pass'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {Array.isArray(files) && files.length > 0 && (
                    <Row style={{marginBottom:20}}>
                        <Col>
                            <Button loading={loading} type="primary" htmlType="submit">
                                {geti18nText('cert.managment.form.button')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row gutter={[12,12]}>
                        <Col>
                            <Button onClick={()=>stopServices(code)}  type="primary" danger >
                                {geti18nText('cert.stop.services')}
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={()=>startServices(code)} style={{background: 'green', color: 'white'}}  >
                                {geti18nText('cert.start.services')}
                            </Button>
                        </Col>
                    </Row>
                    
            </Form>
            {loading && <NySpinner />}
            {!loading && (
                <Row gutter={24} style={{ marginTop: '35px' }}>
                    <Col
                        span={24}
                        style={{
                            whiteSpace: 'pre-wrap',
                            overflowY: 'scroll',
                            maxWidth: '75vw',
                            height: '60vh',
                            background: 'rgba(0,0,0,0.1)',
                        }}
                    >
                        {certDetails}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default CertManagment;
