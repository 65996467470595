import { geti18nText, NyRequestResolver, RESPONSE, NyUtils, NySpinner, getColumnSearch, NyDataTable, NyDataEdit } from '@nybble/nyreact';
import { Divider, Typography, Descriptions, Modal, message, Tooltip, Button, Form } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ, WsMethods } from '../../utils/Constants';
import { useHistory, useParams } from 'react-router-dom';

interface IWsRequestDetails {
    id: number;
    visible: boolean;
    closeModal: () => void;
}

const InformationFromHtDetails = (props:any) => {
    const [loading, setLoading] = useState(false);
    const [loadingParameter, setLoadingParameter] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [showParameter, setShowParameter] = useState(false);
    const [wsRequestDetail, setWsRequestDetail] = useState<{ [index: string]: any }>({});
    const { Title, Link } = Typography;
    const [editHeader, setEditHeader] = useState(geti18nText('menu.information.ht'));
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    


    
    const fileDownload = (attachmentId: any, fileName: String) => {
        setLoadingFile(true);

        NyRequestResolver.requestGet(
            CONSTANTS_REQ.WS_REQUEST.DOWNLOAD_FILE,
            { attachmentId: attachmentId },
            false,
            true
        ).then((result: { [index: string]: any }) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                NyUtils.downloadFile(result);
            } else {
                message.error(geti18nText('app.default.error'));
            }
            setLoadingFile(false);
        });
    };

    const parameterLabel = (label: String, code: String) => {
        return <Tooltip title={label}>{code === 'faaid' ? 'id' : code}</Tooltip>;
    };

    const onModalClose = () => {
        console.log(props);
        props.onSaveAndGetID(undefined);
        setEditHeader(geti18nText('menu.information.ht'));
    };
    function saveUserActivity(id: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.LOG_REQUEST.IS_READ, undefined, {
            wsRequest: { id: id },
            user: null,
        }).then((result) => {
            if (result.status === RESPONSE.CREATED) {
                
            }
        });
    }

    function setValues(dataForm: any) {
        if(dataForm.unread === true) {
            saveUserActivity(dataForm.id)
        }
        setEditHeader(geti18nText('menu.information.ht') + ' '+ dataForm.timeGenerated);
        setWsRequestDetail(dataForm);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.WS_REQUEST.DETAILS_TABLE}
            setValues={setValues}
            width={1300}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideButtons
            {...props}
        >
                <React.Fragment>
                        {wsRequestDetail && wsRequestDetail.statusDescription&&<Descriptions
                                title={''}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                
                            <Descriptions.Item
                                label={geti18nText('params.UpdateOrder.description')}>
                                {wsRequestDetail.statusDescription}
                            </Descriptions.Item>
                                       
                        </Descriptions>}
                    {wsRequestDetail.sendNotificationParam && (
                        <React.Fragment>
                            <Descriptions
                                title={''}
                                contentStyle={{ fontWeight: 'bold' }}
                            >
                                {Object.keys(wsRequestDetail.sendNotificationParam).map((obj: any) => {
                                    if (obj != 'id' && obj != 'wsRequest') {
                                        return (
                                            <Descriptions.Item
                                                label={
                                                    geti18nText('params.' + wsRequestDetail.requestMethod + '.' + obj)}
                                            >
                                                {wsRequestDetail.sendNotificationParam[obj]}
                                            </Descriptions.Item>
                                        );
                                    }
                                })}
                            </Descriptions>
                           
                        </React.Fragment>
                    )}
                </React.Fragment>
        
        </NyDataEdit>
    );
};

export default InformationFromHtDetails;
